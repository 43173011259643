//import { useAuth0 } from "@auth0/auth0-react";
import AuthHelper from "../../helpers/AuthHelper";
import { AxiosError } from "axios";
import React, { useEffect, useState, useRef } from "react";
import HealthcareProviderApi, {
  HealthcareProvider,
} from "../../api/HealthcareProviderApi";
import "./MyAcccount.css";
import Slider from "../../components/slider/Slider";
import {
  ConsultationType,
  toConsultationType,
} from "../../enums/ConsultationType";
import { Sex, toSex } from "../../enums/Sex";
import { SettingsSelect } from "../../components/settings-select/SettingsSelect";
import {
  ParkingAvailibilty,
  toParkingAvailability,
} from "../../enums/ParkingAvailability";
import capitalizeFirstLetter from "../../utils/CapitalizeFirstLetter";
import { SettingsCheckbox } from "../../components/settings-checkbox/SettingsCheckbox";
import { ImageInput } from "../../components/image-input/ImageInput";
import MediaApi from "../../api/MediaApi";
import CategoryApi from "../../api/CategoryApi";
import MethodApi, {
  MethodsPerCategory,
  MethodWeightById,
} from "../../api/MethodApi";
import { beliefs } from "../../constants/beliefs";
import { ageGroups } from "../../constants/ageGroups";
import { languages } from "../../constants/languages";
import { accessibilityByPublicTransit } from "../../constants/accessibilityByPublicTransit";
import { getDisplay } from "../../components/settings-display/SettingsDisplay";
import { Loader } from '@googlemaps/js-api-loader';
import { Config } from "../../Config";
import { Category } from "../../types/Types";
import Dialog from "../../components/dialog/Dialog";
import WorkingHours from "../../components/working-hours/WorkingHours";
import { Certification } from "../../components/certification-form/CertificationForm";
import CertificationForm  from "../../components/certification-form/CertificationForm";
import { FaTrash, FaUser } from "react-icons/fa"; // Import the trash can icon
import HeightSender from "../../components/height-sender/HeightSender";


declare const process: {
  env: {
    NODE_ENV: string;
  };
};
export function MyAccount() {
  const wrapperRef = useRef(null);
  const [hcProvider, setHcProvider] = useState<HealthcareProvider>({
    id: "",
    name: "",
    surName: "",
    sex: null,
    pronouns: "",
    age: null,
    languages: [],
    practiceRegionZipCode: null,
    beliefs: [],
    comfortableWithLGBTQIAP: null,
    practiceName: null,
    practiceDescription: null,
    personalityDescription: null,
    therapeuticMotto: null,
    lifeMotto: null,
    settingTypes: null,
    consultationType: ConsultationType.LIVE,
    ageGroups: [],
    profilePictureUrl: null,
    practiceRoomPictureUrl: null,
    certifications: "",
    visumNumber: null,
    registrationNumber: null,
    professionalAssocation: null,
    pricePerSession: 0,
    avgSessionMinutes: 0,
    refundOptions: [],
    conventioned: null,
    practiceHours: "",
    avgSessionCount: null,
    availableForNewPatients: true,
    avgWaitingDurationWeeks: null,
    waitingListInfo: null,
    address: null,
    landlinePhoneNumber: null,
    mobilePhoneNumber: null,
    email: null,
    website: null,
    accessibilityByPublicTransit: null,
    accessibilityByCar: null,
    parkingAvailibilty: null,
    wheelchairAccessible: null,
    validated: false,
    lat: null,
    lng: null,
    publishProfile: true,
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showDontForgetToSave, setShowDontForgetToSave] = useState<boolean>(false);

  const [methodsPerCategory, setMethodsPerCategory] =
    useState<MethodsPerCategory>({});
  const [methodWeightsPerId, setMethodWeightsPerId] =
    useState<MethodWeightById>({});
  const [categories, setCategories] = useState<Category[]>([]);
  const [certifications, setCertifications] = useState<Certification[]>([]);

  const [initialPracticeHours, setInitialPracticeHours] = useState(
          
    "Ma        09:00 - 17:00\n" +
    "Di        09:00 - 17:00\n" +
    "Wo        09:00 - 17:00\n" +
    "Do        09:00 - 17:00\n" +
    "Vr        09:00 - 17:00\n" +
    "Za        09:00 - 17:00"
);

  const [saveToast, setSaveToast] = useState({ message: "", succes: true });

  const [selectedTab, setSelectedTab] = useState(1);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [showAddCertification, setShowAddCertification] = useState(false);

  type MissingField = { name: string; key: string };
  const [missingFields, setMissingFields] = useState<MissingField[]>([]);
  const [isSaveRequiredOpen, setIsSaveRequiredOpen] = useState(false);

  
  const placeInput = useRef(null);

  let originalAddress: string | null = "";

  let auth0AccessToken = "";
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior
      e.stopPropagation(); // Stop event propagation
      // Your custom logic here (optional)
    }
  };

 
  const handlePreviewButtonClick = () => {
    setIsPreviewDialogOpen(true);
  };

  const handleClosePreviewDialog = () => {
    setIsPreviewDialogOpen(false);
  };

  const handlePublishButtonClick = async () => {
    hcProvider.publishProfile = true;
    const newHcProvider =
        await HealthcareProviderApi.updateAuthenticatedHealthcareProvider({
          ...hcProvider,
          auth0AccessToken: AuthHelper.getToken(),
        });
      setHcProvider(newHcProvider);
  };

  const handleOfflineButtonClick = async () => {
    hcProvider.publishProfile = false;
    const newHcProvider =
        await HealthcareProviderApi.updateAuthenticatedHealthcareProvider({
          ...hcProvider,
          auth0AccessToken: AuthHelper.getToken(),
        });
      setHcProvider(newHcProvider);
  };

  // Function to extract address without country name
  const getAddressWithoutCountry = (location: any) => {
    // Extract relevant address components
    const street = location.address_components.find((component:any) => component.types.includes('route'))?.long_name || '';
    const streetNumber = location.address_components.find((component:any) => component.types.includes('street_number'))?.long_name || '';
    const city = location.address_components.find((component:any) => component.types.includes('locality'))?.long_name || '';
    const postalCode = location.address_components.find((component:any) => component.types.includes('postal_code'))?.long_name || '';

    // Concatenate address components in the desired format
    const formattedAddress = `${street} ${streetNumber} ${postalCode} ${city}`;
    return formattedAddress.trim(); // Trim to remove leading/trailing spaces
  };

  useEffect(() => {

    if (placeInput.current === null) {
      // Handle the case where the ref is null, or simply return to avoid proceeding
      return;
    }
    const loader = new Loader({
      apiKey: Config.getGoogleMapsApiKey(),
      libraries: ["places"]
    });

    loader
      .load()
      .then(() => {
        if (!placeInput.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(placeInput.current, {
          types: ['geocode'],
          componentRestrictions: { country: "BE" },
        });

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            console.log("Latitude:", lat, "Longitude:", lng);
            console.log(place);
            setHcProvider(prevProvider => ({
              ...prevProvider,
              lat: lat || null,
              lng: lng || null
            }));
          } else {
            console.log("Place has no geometry");
          }
          setHcProvider(prevProvider => ({
            ...prevProvider,
            address: getAddressWithoutCountry(place) || null,
          }))
        });

      })
      .catch((e) => {
        // do something
    });

  }, [selectedTab]);

  useEffect(() => {
    (async () => {
      const methodsPerCategory = await MethodApi.getMethodsPerCategory();
      setMethodsPerCategory(methodsPerCategory);

      const categories = await CategoryApi.getCategories();
      setCategories(categories);

      auth0AccessToken = AuthHelper.getToken();
      if(!auth0AccessToken && process.env.NODE_ENV !== 'development') {
        window.location.replace('https://app.miapraktijk.be/login');
        return;
      }
      try {
        const hcProviderResult =
          await HealthcareProviderApi.getAuthenticatedHealthcareProvider(
            auth0AccessToken
          );
        originalAddress = hcProviderResult.address;
        setHcProvider(hcProviderResult);
        setInitialPracticeHours(hcProviderResult.practiceHours || initialPracticeHours);
        setCertifications(JSON.parse(hcProviderResult.certifications) || []);
        const methodAnswers = await MethodApi.getMyMethodWeights(
          auth0AccessToken
        );
        setMethodWeightsPerId(methodAnswers);
      } catch (e) {
        if (e instanceof AxiosError && e.response?.status === 404) {
          return;
        }
        throw e;
      } finally {
        setLoaded(true);
      }
    })();
  }, [auth0AccessToken]);


  // Function to check if all required fields are filled in and return an array of missing fields
  function getMissingFields(hcProvider: HealthcareProvider): MissingField[] {
    const requiredFields = [
      { name: 'Voornaam', key: 'name' },
      { name: 'Familienaam', key: 'surName' },
      { name: 'Talen', key: 'languages' },
      { name: 'Adres', key: 'address' },
      { name: 'E-mail', key: 'email' },
      { name: 'Consultatietype', key: 'consultationType' },
      { name: 'Opleidingen, diplomas, certificaties', key: 'certifications' },
      { name: 'Leeftijdsgroepen', key: 'ageGroups' },
      { name: 'Prijs per sessie', key: 'pricePerSession' },
    ];

    return requiredFields.filter(field => {
      const value = hcProvider[field.key];
      if (Array.isArray(value)) {
        return value.length === 0; // Check if array has no entries
      } else {
        return value === null || value === undefined || value === ''; // Check for null, undefined, and empty string
      }
    });
  }

  const onRequestClose = () => setIsSaveRequiredOpen(false);

  async function onSubmit() {
    const missing = getMissingFields(hcProvider);
    if (missing.length > 0) {
      setMissingFields(missing);
      setIsSaveRequiredOpen(true); // Show dialog if there are missing fields
      hcProvider.validated = false;
     
    }
    else {
      hcProvider.validated = true;
    }

    setShowDontForgetToSave(false);

    setSaveToast({ message: "...", succes: true });
    try {
      const newHcProvider =
        await HealthcareProviderApi.updateAuthenticatedHealthcareProvider({
          ...hcProvider,
          auth0AccessToken: AuthHelper.getToken(),
        });
      setHcProvider(newHcProvider);

      await MethodApi.postMethodWeights({
        weights: methodWeightsPerId,
        auth0AccessToken: AuthHelper.getToken(),
      });
      setSaveToast({ message: "Opgeslagen", succes: true });
    } catch {
      setSaveToast({ message: "Mislukt", succes: false });
    }
    setTimeout(() => setSaveToast({ message: "", succes: true }), 2000);
  }




  function getMethodQuestionsForCategory(category: string) {
    const methods = methodsPerCategory[category];
    if (methods) {
      return (
        <SettingsSelect
          options={methods}
          onSelect={(value) => {
            setMethodWeightsPerId((prev) => ({
              ...prev,
              [value.id]: 1,
            }));
          }}
          onDeselect={(value) => {
            setMethodWeightsPerId((prev) => ({
              ...prev,
              [value.id]: 0,
            }));
          }}
          answerActive={(answer) => methodWeightsPerId[answer.id] === 1}
          label={(value) => value.name}
        />
      );
    } else {
      return null;
    }
  }

  function getMethodSlidersForCategory(category: string, showLeftRightText = true) {
    const methods = methodsPerCategory[category];
    if (methods) {
      return methods.map((method) => (
        <div key={method.id}>
          <div className="my-account-label">{method.name}</div>
          <Slider showInfoText={false} showLeftRightText={showLeftRightText}
            value={methodWeightsPerId[method.id] || 5}
            onChange={(weight) =>
              setMethodWeightsPerId({
                ...methodWeightsPerId,
                [method.id]: weight,
              })
            }
          />
        </div>
      ));
    } else {
      return null;
    }
  }

  function translateParkingAvailability(s: string): string {
    switch (s.toUpperCase()) {
      case "FREE":
        return "Gratis";
      case "PAYING":
        return "Betaald";
      case "NONE":
        return "Geen";
      default:
        return "Onbekend"; // A fallback in case an unknown value is passed
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setHcProvider((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'email') {
      const isValid = validateEmail(value);
      setEmailError(isValid ? '' : 'Ongeldig e-mail formaat');
    }
    if (name === 'landlinePhoneNumber') {
      const isValid = validatePhoneNumber(value);
      setPhoneError(isValid ? '' : 'Ongeldig telefoon formaat');
    }
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression for phone number validation
    const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/; // International format with country code
    return regex.test(phoneNumber);
  };

  const handleRemoveCertification = (index) => {
    // Remove the certification at the specified index
    certifications.splice(index, 1);
    setHcProvider({
      ...hcProvider,
      certifications: JSON.stringify(certifications),
    });
    setShowDontForgetToSave(true);
  };
  
  function dataURLtoBlob(dataURL) {
    const parts = dataURL.split(",");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);
    
    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }
  
    return new Blob([uint8Array], { type: contentType });
  }

  return (
    <div ref={wrapperRef} className={"my-account-wrapper"}>
    <HeightSender wrapperRef={wrapperRef}/>
    <Dialog
      isOpen={isSaveRequiredOpen}
      onRequestClose={onRequestClose}
      size={"small"}
      title={`Vul onderstaande verplichte velden in`}
    >
      <div className="my-account-extra-padding">
        <b>Uw wijzigingen werden opgeslagen</b><br/><br/>Het profiel is nog niet volledig ingevuld. Om het profiel te activeren vul nog de volgende velden in:
        <ul>
            {missingFields.map(field => (
              <li key={field.key}>{field.name}</li>
            ))}
        </ul>
        <div className="save-dialog-footer">
          {/* Button aligned at the bottom */}
          <button className="small-button dark-button my-account-dialog-close-button" onClick={onRequestClose}>
            Sluiten
          </button>
        </div>
      </div>
    </Dialog>
    <Dialog
        isOpen={isPreviewDialogOpen}
        onRequestClose={handleClosePreviewDialog}
        title="Jouw profiel op miazoekhulp.be"
        size="large"
        url={"/provider-details?providerId=" + hcProvider.id} // Provide the URL for the iframe content
      />
    <div className={"menu-container"}>
      {/*
      <span className={"logo"}><a href="https://profiel.miapraktijk.be/">Mia</a></span>
      <div className={"menu"}>
        <div className={"menu-item"}><a href="https://profiel.miapraktijk.be/my-account">Mia profiel</a></div>
        <div className={"menu-item"}><a href="https://app.miapraktijk.be/home">Mia praktijk</a></div>
        <div><a className={"mia-button-blue"} href="https://app.miapraktijk.be/login">Logout</a></div>
      </div>
          */}
      <div className={"my-account"}>
        {loaded ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <h1 className="title-for-mia-praktijk"><FaUser /> &nbsp;Mijn profiel</h1>
            <p className="subtitle-for-mia-praktijk">Home&nbsp;&nbsp;/&nbsp;&nbsp;<b>Mijn profiel</b></p>

            {!hcProvider.validated ? (
              <div className={"warning"}>
                <div>
                Jouw profiel is nog niet volledig ingevuld. Klik op opslaan onderaan om te weten welke verplichte velden ontbreken. Tot die tijd zal je niet aangeraden worden
                bij bezoekers van de website <a href="https://www.miazoekthulp.be" target="_blank">miazoekthulp.be</a>. Dit is een maatregel om de
                kwaliteit van het platform te kunnen waarborgen.
                </div>
              </div>
            ) : (<>
              {!hcProvider.publishProfile ? (
              <div className={"warning"}>
                <div>Jouw profiel is offline. Klik op 'breng profiel online' om je profiel te zien op <a href="https://www.miazoekthulp.be" target="_blank">miazoekthulp.be</a>.</div>
                {hcProvider.validated ? (<div className="small-top-buttons-right"><button className="small-button lighter-button preview-button" onClick={handlePublishButtonClick}>Breng mijn profiel online</button></div>): null}
            
              </div>):(
                <div className={"warning"}>
                <div>Jouw profiel is actief. Je kan hieronder jouw gegevens aanpassen indien gewenst. Klik onderaan op opslaan.</div>
                {hcProvider.validated ? (<div className="small-top-buttons-right"><button className="small-button lighter-button preview-button" onClick={handlePreviewButtonClick}>Bekijk mijn profiel online</button><button className="small-button lighter-button offline-button" onClick={handleOfflineButtonClick}>Haal mijn profiel offline</button></div>): null}
          
              </div>
              )
              }</>
            )}
            <div className={"my-account-menu"}>
              <a
                className={`small-button ${
                  selectedTab === 1 ? "light-button" : "lighter-button"
                }`}
                onClick={() => {
                  setSelectedTab(1);
                }}
              >
                {"Persoon"}
              </a>
              <a
                className={`small-button ${
                  selectedTab === 2 ? "light-button" : "lighter-button"
                }`}
                onClick={() => {
                  setSelectedTab(2);
                }}
              >
                {"Praktijk"}
              </a>
              <a
                className={`small-button ${
                  selectedTab === 3 ? "light-button" : "lighter-button"
                }`}
                onClick={() => {
                  setSelectedTab(3);
                }}
              >
                {"Professioneel"}
              </a>
              <a
                className={`small-button ${
                  selectedTab === 4 ? "light-button" : "lighter-button"
                }`}
                onClick={() => {
                  setSelectedTab(4);
                }}
              >
                {"Aanpak"}
              </a>
            </div>
            {selectedTab === 1 ? (
              <div>
                <h1>Persoonsgegevens</h1>

                <div>
                  <div className="my-account-label">Upload een profielfoto</div>
                  <ImageInput
                    onChange={(file) => {
                     
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = (event) => {
                        const img = new Image();
                        if(event.target)
                        {
                          img.src = event.target?.result as string;

                          img.onload = () => {
                            
                            const maxDimension = 800; // Set a maximum width or height, e.g., 800px
                            let width = img.width;
                            let height = img.height;

                            // Calculate the new dimensions while maintaining the aspect ratio
                            if (width > height) {
                                if (width > maxDimension) {
                                    height *= maxDimension / width;
                                    width = maxDimension;
                                }
                            } else {
                                if (height > maxDimension) {
                                    width *= maxDimension / height;
                                    height = maxDimension;
                                }
                            }

                            const canvas = document.createElement("canvas");
                            const ctx = canvas.getContext("2d");
                            canvas.width = width;
                            canvas.height = height;
                            if(ctx)
                            {
                              ctx.drawImage(img, 0, 0, width, height);

                              // Adjust the quality parameter to control the compression level
                              const compressedDataURL = canvas.toDataURL("image/jpeg", 0.5);

                              // Convert the data URL to a Blob
                              const compressedFile = dataURLtoBlob(compressedDataURL);

                              // Create a FormData object and append the compressed file
                              const formData = new FormData();
                              formData.append("binTypeImage", compressedFile, file.name);

                              // Send the compressed image to the server
                              MediaApi.postMedia(formData).then((response) =>
                                setHcProvider((prev) => ({
                                  ...prev,
                                  profilePictureUrl: response.url,
                                }))
                              );
                            }

                          };
                        }
                      };
                    }}
                    imageUrl={hcProvider.profilePictureUrl}
                  />
                </div>
                <div className="next-to-eachother">
                  <div>
                    <div className="my-account-label">Voornaam</div>
                    <input
                      type="text"
                      value={hcProvider.name || ""}
                      onChange={(e) =>
                        setHcProvider({
                          ...hcProvider,
                          name: e.target.value || "",
                        })
                      }
                    />
                  </div>
                  <div>
                    <div className="my-account-label">Naam</div>
                    <input
                      type="text"
                      value={hcProvider.surName || ""}
                      onChange={(e) =>
                        setHcProvider({
                          ...hcProvider,
                          surName: e.target.value || "",
                        })
                      }
                    />
                  </div>
                  <div>
                    <div className="my-account-label">Voornaamwoorden</div>
                    <input
                      type="text"
                      value={hcProvider.pronouns || ""}
                      onChange={(e) =>
                        setHcProvider({
                          ...hcProvider,
                          pronouns: e.target.value || "",
                        })
                      }
                    />
                  </div>
                  <div>
                    <div className="my-account-label">Gender</div>
                    <SettingsSelect
                      compact
                      label={(value) => getDisplay(value.toUpperCase())}
                      options={Object.keys(Sex)}
                      onSelect={(value) => {
                        setHcProvider({
                          ...hcProvider,
                          sex: toSex(value) || Sex.X,
                        });
                      }}
                      activeAnswers={[hcProvider.sex || Sex.X]}
                    ></SettingsSelect>
                  </div>
                  <div>
                    <div className="my-account-label">Leeftijd</div>
                    <input
                      type="number"
                      value={hcProvider.age || ""}
                      onChange={(e) =>
                        setHcProvider({
                          ...hcProvider,
                          age: parseInt(e.target.value) || null,
                        })
                      }
                    />
                  </div>
                  <div>
                    <div className="my-account-label">Welke talen spreekt u</div>
                    <SettingsSelect
                      options={languages}
                      onSelect={(value) =>
                        setHcProvider({
                          ...hcProvider,
                          languages: hcProvider.languages.concat([value]),
                        })
                      }
                      onDeselect={(value) => {
                        setHcProvider({
                          ...hcProvider,
                          languages: hcProvider.languages.filter(
                            (el) => el !== value
                          ),
                        });
                      }}
                      activeAnswers={hcProvider.languages}
                    />
                  </div>
                  <div>
                    <div className="my-account-label">
                      Identificeert u zichzelf binnen een bepaalde religie of
                      geloof?
                    </div>
                    <SettingsSelect
                      options={beliefs}
                      onSelect={(value) => {
                        setHcProvider({
                          ...hcProvider,
                          beliefs: hcProvider.beliefs.concat([value]),
                        });
                      }}
                      onDeselect={(value) => {
                        if (hcProvider.beliefs.includes(value)) {
                          setHcProvider({
                            ...hcProvider,
                            beliefs: hcProvider.beliefs.filter(
                              (el) => el !== value
                            ),
                          });
                        }
                      }}
                      activeAnswers={hcProvider.beliefs}
                    />
                  </div>
                  <SettingsCheckbox
                    checked={hcProvider.comfortableWithLGBTQIAP || false}
                    onChange={(checked) =>
                      setHcProvider({
                        ...hcProvider,
                        comfortableWithLGBTQIAP: checked || null,
                      })
                    }
                    label={
                      "Bent u comfortabel bij het werken met patiënten die deel uitmaken van de LGBTQIA+ gemeenschap en mogelijke ervaringen die hiermee samengaan?"
                    }
                  />
                </div>

              </div>
            ) : null}
            {selectedTab === 2 ? (
              <div>
                  <h1>Praktijkgegevens</h1>
                    <h2>Luik 1: Contactgegevens</h2>
                    <div className="next-to-eachother">
                      <div>
                        <div className="my-account-label">Naam van de praktijk</div>
                          <input
                            type="text"
                            value={hcProvider.practiceName || ""}
                            onChange={(e) =>
                              setHcProvider({
                                ...hcProvider,
                                practiceName: e.target.value || null,
                              })
                            }
                          />
                      </div>
                      <div>
                        <div className="my-account-label">Adres van de praktijk</div>
                        <input ref={placeInput}
                          type="text" 
                          onKeyDown={handleKeyDown}
                          
                        />
                        <br/>
                        Uw adres wordt weergegeven als
                        <div className="chosen-address">{hcProvider.address}</div>
                      </div>
                    </div>
                    <div className="next-to-eachother">
                    <div>
                      <div className="my-account-label">Website van de praktijk</div>
                      <input
                        type="text"
                        value={hcProvider.website || ""}
                        onChange={(e) =>
                          setHcProvider({
                            ...hcProvider,
                            website: e.target.value || null,
                          })
                        }
                      />
                    </div>
                    <div>
                      <div className="my-account-label">Telefoonnummer</div>
                      <input
                        type="text"
                        name="landlinePhoneNumber"
                        value={hcProvider.landlinePhoneNumber || ""}
                        placeholder="Internationaal formaat (+xx xxx xxx xxx)"
                        onChange={handleChange}
                      />
                      <div className="input-explanation">
                        Voer alstublieft uw telefoonnummer in internationale opmaak in, beginnend met de landcode.
                        Voorbeeld: +32 3 1234567 (voor België).
                      </div>
                        {phoneError && <div className="error-message">{phoneError}</div>}
                    </div>
                    <div>
                      <div className="my-account-label">E-mail</div>
                      <input
                        type="email"
                        name="email"
                        value={hcProvider.email || ''}
                        onChange={handleChange}
                      />
                      {emailError && <div className="error-message">{emailError}</div>}
                    </div>
                    <div>
                      <div className="my-account-label">
                        Welke consultvormen biedt u aan?
                      </div>
                      <SettingsSelect
                        options={Object.keys(ConsultationType)}
                        label={(value) => capitalizeFirstLetter(value.toLowerCase())}
                        onSelect={(value) =>
                          setHcProvider({
                            ...hcProvider,
                            consultationType:
                              toConsultationType(value) || ConsultationType.LIVE,
                          })
                        }
                        activeAnswers={[hcProvider.consultationType]}
                      />
                    </div>
                    <div>
                      <div className="my-account-label">
                      In welk soort praktijk werkt u?
                      </div>
                      {getMethodQuestionsForCategory("d9c0972c-68d6-4809-ac5c-f88c9bf6b51d")}
                    </div>
                </div>
                <br/>
                <h2>Luik 2: Bereikbaarheid</h2>
                <div className="next-to-eachother">
                  <div>
                    <div className="my-account-label">
                      Is uw therapieruimte bereikbaar met het openbaar vervoer?
                    </div>
                    <SettingsSelect
                      options={accessibilityByPublicTransit}
                      onSelect={(value) => {
                        if (hcProvider.accessibilityByPublicTransit === null) {
                          setHcProvider({
                            ...hcProvider,
                            accessibilityByPublicTransit: [value],
                          });
                        } else {
                          setHcProvider({
                            ...hcProvider,
                            accessibilityByPublicTransit:
                              hcProvider.accessibilityByPublicTransit.concat([value]),
                          });
                        }
                      }}
                      onDeselect={(value) =>
                        setHcProvider({
                          ...hcProvider,
                          accessibilityByPublicTransit:
                            hcProvider.accessibilityByPublicTransit?.filter(
                              (el) => el !== value
                            ) || [],
                        })
                      }
                      activeAnswers={hcProvider.accessibilityByPublicTransit || []}
                    />
                  </div>
                  <div>
                    <div className="my-account-label">
                      Hoe is uw therapieruimte bereikbaar met de wagen?
                    </div>
                    <SettingsSelect
                      options={[
                        "Makkelijk bereikbaar",
                        "Binnen een LEZ",
                        "Buiten een LEZ",
                      ]}
                      onSelect={(value) => {
                        if (hcProvider.accessibilityByCar === null) {
                          setHcProvider({
                            ...hcProvider,
                            accessibilityByCar: [value],
                          });
                        } else {
                          setHcProvider({
                            ...hcProvider,
                            accessibilityByCar: hcProvider.accessibilityByCar.concat([
                              value,
                            ]),
                          });
                        }
                      }}
                      onDeselect={(value) =>
                        setHcProvider({
                          ...hcProvider,
                          accessibilityByCar:
                            hcProvider.accessibilityByCar?.filter(
                              (el) => el !== value
                            ) || [],
                        })
                      }
                      activeAnswers={hcProvider.accessibilityByCar || []}
                    />
                  </div>
                  <div>
                    <div className="my-account-label">
                      Zijn er parkeermogelijkheden bij uw therapieruimte
                    </div>
                    <SettingsSelect
                      options={Object.keys(ParkingAvailibilty)}
                      label={(answer) => translateParkingAvailability(answer.toLowerCase())}
                      onSelect={(value) => {
                        setHcProvider({
                          ...hcProvider,
                          parkingAvailibilty:
                            toParkingAvailability(value) || ParkingAvailibilty.NONE,
                        });
                      }}
                      activeAnswers={
                        hcProvider.parkingAvailibilty
                          ? [hcProvider.parkingAvailibilty]
                          : []
                      }
                    />
                  </div>
                  
                </div>
                <div>
                    <SettingsCheckbox
                      checked={hcProvider.wheelchairAccessible || false}
                      onChange={(checked) =>
                        setHcProvider({
                          ...hcProvider,
                          wheelchairAccessible: checked || null,
                        })
                      }
                      label={
                        "Is uw therapieruimte toegankelijk voor rolstoelgebruikers of mensen met beperkte mobiliteit?"
                      }
                    />
                  </div>
              </div>
            ) : null}
            {selectedTab === 3 ? (
              <div>
                <h1>Professionele gegevens</h1>
                <h2>Luik 1: Achtergrond</h2>
                
                <div>
                  <div className="my-account-label">
                    Welke opleidingen/diploma's en certificaten hebt u behaald?
                  </div>
                  <button className="small-button lighter-button" onClick={(e) => {
                    setShowAddCertification(true);
                    e.preventDefault();
                  }}>+ toevoegen</button>&nbsp;&nbsp; {showDontForgetToSave && (<span className="dont-forget-to-save">Vergeet niet onderaan op opslaan te klikken</span>)}
                 
                  {showAddCertification ? (
                  <CertificationForm
                    trainingCenters={methodsPerCategory["21aef58e-ee79-4a3e-bead-615ed2b1d4ee"]}
                    onAddCertification={(cert) => {
                      // Handle adding certification
                      setShowAddCertification(false);
                      setShowDontForgetToSave(true);
                      certifications.push(cert);
                      setHcProvider({
                        ...hcProvider,
                        certifications: JSON.stringify(certifications),
                      });
                    }}
                  />) : null}
                   {certifications.map((cert, index) => (
                    <div className="certification" key={index}>
                      <b>{cert.certName}</b>, bij {methodsPerCategory["21aef58e-ee79-4a3e-bead-615ed2b1d4ee"].find((method) => method.id === cert.trainingCenterId)?.name} - {cert.yearCompleted}
                      <button
                        className="remove-cert-button"
                        onClick={(e) => {e.preventDefault(); handleRemoveCertification(index);}}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  
                  ))}
                </div>
                <div className="next-to-eachother">
                <div>
                  <div className="my-account-label">
                    Hebt u een visumnummer?
                  </div>
                  <input
                    type="text"
                    value={hcProvider.visumNumber || ""}
                    onChange={(e) =>
                      setHcProvider({
                        ...hcProvider,
                        visumNumber: e.target.value || null,
                      })
                    }
                  />
                </div>
                <div>
                  <div className="my-account-label">Bij welke beroepsvereniging(en) bent u verbonden?</div>
                  {getMethodQuestionsForCategory("78213690-b01f-4c61-bb6b-cb72c606e4fe")}
                </div>
                </div>
                <br/>
                <h2>Luik 2: Visie en werking</h2>

                <div>
                  <div className="my-account-label">
                    Met welke leeftijdsgroepen werkt u?
                  </div>
                  <SettingsSelect
                    options={ageGroups}
                    onDeselect={(value) =>
                      setHcProvider({
                        ...hcProvider,
                        ageGroups: hcProvider.ageGroups.filter(
                          (el) => el !== value
                        ),
                      })
                    }
                    onSelect={(value) =>
                      setHcProvider({
                        ...hcProvider,
                        ageGroups: hcProvider.ageGroups.concat([value]),
                      })
                    }
                    activeAnswers={hcProvider.ageGroups}
                  />
                </div>
                <div className="next-to-eachother">
                  <div>
                    <div className="my-account-label">
                      Geef een korte voorstelling van uw therapeutische praktijk en
                      aanpak
                    </div>
                    <textarea
                      rows={4}
                      value={hcProvider.practiceDescription || ""}
                      onChange={(e) => {
                        setHcProvider({
                          ...hcProvider,
                          practiceDescription: e.target.value || null,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <div className="my-account-label">
                      Heb je een therapeutisch motto / citaat?
                    </div>
                    <textarea
                      rows={4}
                      value={hcProvider.therapeuticMotto || ""}
                      onChange={(e) => {
                        setHcProvider({
                          ...hcProvider,
                          therapeuticMotto: e.target.value || null,
                        });
                      }}
                    />
                  </div>
                </div>
                

                
                <div>
                  <div className="my-account-label">
                    Therapiestromingen: Via welke therapiestroming werkt u?
                  </div>
                  {getMethodQuestionsForCategory("therapiestroming")}
                </div>
                <div>
                  <div className="my-account-label">
                    Therapievormen: Geeft u en/of hebt u ervaring met een soort vorm van therapie?
                  </div>
                  {getMethodQuestionsForCategory("therapie")}
                </div>
                <div>
                  <div className="my-account-label">
                    Behandelingstechnieken: Met welke technieken of methodes hebt u ervaring?
                  </div>
                  {getMethodQuestionsForCategory("behandelingstechniek")}
                </div>
                <div>
                  <div className="my-account-label">
                    Diagnoses: Met welke problematieken of onderwerpen hebt u uitgebreide ervaring of een opleiding voor gevolgd?
                  </div>
                  {getMethodQuestionsForCategory("specialisatie")}
                </div>
                <div>
                  <div className="my-account-label">
                  Lichamelijke klachten: Met welke lichamelijke klachten hebt u ervaring of een opleiding voor gevolgd?
                  </div>
                  {getMethodQuestionsForCategory("cb283e01-5219-43a5-9c3a-5dd24b5f5db8")}
                </div>
                <br/>
                <h2>Luik 3: Praktisch</h2>
                <div className="next-to-eachother">
                <div>
                  <div className="my-account-label">
                    Wat zijn uw consultprijzen? Prijs per sessie:
                  </div>
                  <input
                    type="number"
                    value={hcProvider.pricePerSession || ""}
                    onChange={(e) =>
                      setHcProvider({
                        ...hcProvider,
                        pricePerSession: parseInt(e.target.value) || null,
                      })
                    }
                  />
                  
                </div>
                <div>
                  <div className="my-account-label">
                    Wat is uw consultduur? Gemiddeld aantal minuten per sessie:
                  </div>
                  <input
                    type="number"
                    value={hcProvider.avgSessionMinutes || ""}
                    onChange={(e) =>
                      setHcProvider({
                        ...hcProvider,
                        avgSessionMinutes: parseInt(e.target.value) || null,
                      })
                    }
                  />
                </div>
                </div>
                <div>
                  <div className="my-account-label">
                  Consultatie-opties: Doet u…
                  </div>
                  {getMethodQuestionsForCategory("eb72b702-97f5-428e-8c31-2b209ee727c8")}
                </div>
                <div>
                  <div className="my-account-label">
                    Wat zijn uw terugbetalingsmogelijkheden?
                  </div>
                  <SettingsSelect
                    options={[
                      "Eerstelijn in een netwerk voor geestelijke gezondheidszorg",
                      "gespecialiseerde psychologische zorg in een netwerk voor geestelijke gezondheidszorg",
                      "RIZIV",
                      "mutualiteit",
                    ]}
                    onDeselect={(value) =>
                      setHcProvider({
                        ...hcProvider,
                        refundOptions: hcProvider.refundOptions.filter(
                          (el) => el !== value
                        ),
                      })
                    }
                    onSelect={(value) =>
                      setHcProvider({
                        ...hcProvider,
                        refundOptions: hcProvider.refundOptions.concat([value]),
                      })
                    }
                    activeAnswers={hcProvider.refundOptions}
                  />
                </div>
                <div className="checkboxes-container">
                <SettingsCheckbox
                  checked={hcProvider.conventioned || false}
                  onChange={(checked) =>
                    setHcProvider({
                      ...hcProvider,
                      conventioned: checked || null,
                    })
                  }
                  label={"Bent u geconventioneerd?"}
                />
                </div>

                <div className="next-to-eachother">
                <div>
                  <div className="my-account-label">
                    Wat zijn uw gebruikelijke werktijden?
                  </div>
                  <WorkingHours
                    value={initialPracticeHours}
                    onChange={(hours) => {
                      setHcProvider({
                        ...hcProvider,
                        practiceHours: hours,
                      });
                    }}
                  />
                </div>

                <div>
                  <div className="my-account-label">
                    Hoe lang duurt een behandelingstraject bij u gemiddeld? (aantal
                    sessies)
                  </div>
                  <input
                    type="number"
                    value={hcProvider.avgSessionCount || ""}
                    onChange={(e) =>
                      setHcProvider({
                        ...hcProvider,
                        avgSessionCount: e.target.value,
                      })
                    }
                  />
                  <div>
                  <div className="my-account-label">
                    Wat is de gemiddelde wachttijd om een therapie te starten?
                    (weken)
                  </div>
                  <input
                    type="number"
                    value={hcProvider.avgWaitingDurationWeeks || ""}
                    onChange={(e) =>
                      setHcProvider({
                        ...hcProvider,
                        avgWaitingDurationWeeks: parseInt(e.target.value) || null,
                      })
                    }
                  />
                </div>
                <div className="checkboxes-container">
                <SettingsCheckbox
                  checked={hcProvider.availableForNewPatients || false}
                  onChange={(checked) =>
                    setHcProvider({
                      ...hcProvider,
                      availableForNewPatients: checked,
                    })
                  }
                  label={"Bent u beschikbaar voor nieuwe clienten?"}
                />
                </div>
                </div>
                
                </div>
                {/*
                <div>
                  <div className="my-account-label">
                    Heeft u een wachtlijst? Zo ja, op welke manier kunnen clienten
                    hier een plaats op krijgen?
                  </div>
                  <textarea
                    value={hcProvider.waitingListInfo || ""}
                    onChange={(e) =>
                      setHcProvider({
                        ...hcProvider,
                        waitingListInfo: e.target.value || null,
                      })
                    }
                  />
                </div>
               */}
              </div>
            ): null}
            {selectedTab === 4 ? (
                <div>
                  {categories
                    .filter((category) => category.code === 'AANPAKSTELLINGEN')
                    .map((category) => (
                      <div key={category.id}>
                        <h1>{category.name}</h1>
                        <p>{category.description}</p>

                        <div className="next-to-eachother">
                          {getMethodSlidersForCategory(category.id)}
                        </div>
                      </div>
                    ))}
                    {categories
                    .filter((category) => category.code === 'AANPAKCASUSSEN')
                    .map((category) => (
                      <div key={category.id}>
                        <h1>{category.name}</h1>
                        <p>{category.description}</p>

                        <div className="next-to-eachother">
                          {getMethodSlidersForCategory(category.id, false)}
                        </div>
                      </div>
                    ))}
                </div>
            ) : null}
          </form>
        ) : null}
      </div>
    </div>
    {loaded ? (<div className={"my-account-save"}>
              <button
                className={`small-button ${
                  saveToast.succes ? "light-button" : "dark-button"
                }`}
                type="submit"
                onClick={onSubmit}
              >
                {saveToast.message || "Opslaan"}
              </button>
              {selectedTab > 1 ? (
                <span>
                  <a
                    className={`small-button white-button`}
                    onClick={(e) => {
                      setSelectedTab(selectedTab - 1);
                      setInitialPracticeHours(hcProvider.practiceHours || initialPracticeHours);
                      document.body.scrollIntoView();
                    }}
                  >
                    {"< Vorige"}
                  </a>
                  &nbsp;&nbsp;&nbsp;
                </span>
              ) : null}
              {selectedTab < 4 ? (
                <span>
                  <a
                    className={`small-button white-button`}
                    onClick={(e) => {
                      setSelectedTab(selectedTab + 1);
                      document.body.scrollIntoView();
                    }}
                  >
                    {"Volgende >"}
                  </a>
                </span>
              ) : null}
            </div>):(<></>)}
    </div>
  );
}

