import React, { useEffect, useState, useRef } from "react";
import "./ActiveMethods.css";
import MethodApi, {
  MethodsPerCategory,
  MethodWeightById,
} from "../../api/MethodApi";


export function ActiveMethods({methodsPerCategory, category, methodWeightsPerId, color, amount} 
  : {methodsPerCategory: MethodsPerCategory; category: any; methodWeightsPerId: any; color: string | null; amount: number;}) {
  const [visibleItems, setVisibleItems] = useState(amount); // Number of initially visible items
  const [popupInfo, setPopupInfo] = useState<{ method: any } | null>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number } | null>(null);
  
  const colorStyle: any = {
  }
  if(color) {
    colorStyle.background = color;
  }
  const handleShowMoreClick = () => {
    // Set visibleItems to the total number of items in the array
    setVisibleItems(1000);
  };

  
  const showInfoOnMobile = (event: React.MouseEvent, method: any) => {
    if(method.information) {
      const rect = event.currentTarget.getBoundingClientRect();
      setPopupInfo({
        method
      });
      setMousePosition({
        x: event.clientX + 20 + window.scrollX,
        y: event.clientY + window.scrollY,
      });
    }
  }

  const handleMouseEnter = (event: React.MouseEvent, method: any) => {
    if(method.information) {

      setPopupInfo({ method });
      setMousePosition({
        x: event.clientX + 20 + window.scrollX,
        y: event.clientY + window.scrollY,
      });
    }
    
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (popupInfo) {
      setMousePosition({
        x: event.clientX + 20 + window.scrollX,
        y: event.clientY + window.scrollY,
      });
    }
  };

  const handleMouseLeave = () => {
    setPopupInfo(null);
    setMousePosition(null);
  };


  const closePopup = () => {
    setPopupInfo(null);
    setMousePosition(null);
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        closePopup();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const methods = methodsPerCategory[category];
  if (methods) {
    return (
      <div className="methods-container">
        {methods.filter((method) => methodWeightsPerId[method.id] === 1).slice(0, visibleItems).map((method: any) => (
          <>
          <div className="desktop">
            <div 
              onMouseEnter={(event) => handleMouseEnter(event, method)}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave} className="method-name" style={colorStyle} key={method.id}>{method.name}</div>
          </div>
          <div className="mobile">
            <div onClick={(event) => showInfoOnMobile(event, method)} className="method-name" style={colorStyle} key={method.id}>{method.name}</div>
          </div>
          </>
        ))}
        {methods.filter((method) => methodWeightsPerId[method.id] === 1).length > visibleItems && (
          <button className="method-more-button" onClick={handleShowMoreClick}>Toon meer...</button>
        )}
        {popupInfo && mousePosition && (
          <div ref={popupRef} className="method-popup-info" style={{ top: mousePosition.y, left: mousePosition.x }}>
            <button className="method-popup-close-button" onClick={closePopup}>X</button>
            <div className="method-popup-title">{popupInfo.method.name}</div>
            <div>{popupInfo.method.information}</div>
          </div>
        )}
      </div>
      
    );
  } else {
    return null;
  }
}
