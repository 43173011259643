import ApiInstance from "./ApiInstance";
import { Method } from "../types/Types";  

export type MethodsPerCategory = { [key: string]: Method[] };

export type MethodWeightById = {
  [methodId: string]: number;
};

export type WeightPerMethodWithAuth = {
  auth0AccessToken: string;
  weights: MethodWeightById;
};

class MethodApi {
  public async getMethodsPerCategory(): Promise<MethodsPerCategory> {
    const response = await ApiInstance.getApi().get("/methods/");
    return response.data;
  }

  public async postMethodWeights(weights: WeightPerMethodWithAuth) {
    await ApiInstance.getApi().post("/methods/", weights);
  }

  public async getMyMethodWeights(
    auth0AccessToken: string
  ): Promise<MethodWeightById> {
    const response = await ApiInstance.getApi().get<MethodWeightById>(
      "/methods/me",
      {
        headers: {
          "auth0-access-token": auth0AccessToken,
        },
      }
    );
    return response.data;
  }


  public async getMethodWeightsForProvider(
    providerId: string
  ): Promise<MethodWeightById> {
    const response = await ApiInstance.getApi().get<MethodWeightById>(
      `/methods/provider/${providerId}`,
      {
      }
    );
    return response.data;
  }

  
}

export default new MethodApi();
