import React, { useEffect, useState } from "react";
import "./SettingsDisplay.css";
import MethodApi, {
  MethodsPerCategory,
  MethodWeightById,
} from "../../api/MethodApi";


export const getDisplay = (option: any) => {
  switch (option) {
    case 'M': return 'Man'; // Assuming 'M' stands for Male
    case 'F': return 'Vrouw'; // Assuming 'F' stands for Female
    case 'X': return 'Non-binair'; 
    case 'LIVE': return 'Live'; 
    case 'ONLINE': return 'Online';
    case 'HYBRID': return 'Hybride';  
    case 'PAYING': return 'Betalend';  
    case 'FREE': return 'Gratis';  
    case 'NONE': return 'Geen';  
    default: return option;
  }
};




export function SettingsDisplay({color, options} 
  : {color: string; options: string[];}) {
  const [visibleItems, setVisibleItems] = useState(5); // Number of initially visible items
  const optionsStyle = {
    background: color
  }
  const handleShowMoreClick = () => {
    // Set visibleItems to the total number of items in the array
    setVisibleItems(1000);
  };
  if (options) {
    return (
      <div className="methods-container">
        {options.slice(0, visibleItems).map((option: any, index: number) => (
          <div className="setting-name" style={optionsStyle} key={index}>{getDisplay(option)}</div>
        ))}
        {options.length > visibleItems && (
          <button className="setting-more-button" onClick={handleShowMoreClick}>Toon meer...</button>
        )}
      </div>
      
    );
  } else {
    return null;
  }
}
