import React, { useState } from 'react';
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import './Informatie.css';
import informatieImage from '../../assets/informatie.svg'; // Adjust path accordingly

const Terugbetaling: React.FC = () => {


  const handleBackClick = () => {
    window.location.href = "/informatie"; // Adjust this path as necessary
  };

  return (
    <div className="container">
      <Header/>
      <main className="main-info">
        <div className="content-wrapper-article">
          <section className="intro-section-in-article">
            <h1>Welkom in het Mia informatie-luik</h1>
            <p>Welke soorten therapieën bestaan er? Heb ik recht op terugbetaling? Wat mag ik verwachten van therapie? Heb ik zelfs een psycholoog nodig? We voorzien een antwoord op enkele vragen!</p>
          </section>
          <a className="back-button" onClick={handleBackClick}>
            <span className="back-button-text">Terug naar overzicht</span>
          </a>
          <h1>Terugbetalings-mogelijkheden, tussenkomst van de mutualiteit en conventionering</h1>
          <section className="article-section">
            <h2>Terugbetalings-mogelijkheden</h2>
            <p>
              Voor een individuele sessie bij een geconventioneerd psycholoog betaal je zelf €11 of €4 bij een verhoogde tegemoetkoming. Voor een groepssessie is dat €2,5. De overheid past de rest bij via de derdebetalersregeling. Je hoeft dit dus niet eerst zelf voor te schieten.
            </p>
            <p>
              Het aantal sessies dat je vergoed krijgt per periode van 12 maanden, hangt af van uw leeftijd of het soort zorg dat je ontvangt of het soort sessies dat je volgt. <a href="https://www.inami.fgov.be/nl/thema-s/verzorging-kosten-en-terugbetaling/wat-het-ziekenfonds-terugbetaalt/geestelijke-gezondheidszorg/eerstelijns-en-gespecialiseerde-psychologische-zorg-in-een-netwerk-voor-geestelijke-gezondheid">Meer info vind je hier</a>.
            </p>
            <p>
              Het eerste verkennende gesprek is gratis. In dit gesprek bespreek je samen met de psycholoog je hulpvraag. Op basis hiervan kunnen jullie beslissen of de psycholoog in kwestie zelf met je aan de slag zal gaan. Dit kan individueel zijn of in groep. Het kan zijn dat de psycholoog je doorverwijst.
            </p>
            <p>
              Het kan ook zijn dat de psycholoog voorstelt om je huisarts of een andere zorgverlener bij je begeleiding te betrekken. Dat kan enkel met jouw goedkeuring. <a href="https://www.compsy.be/nl/conventie-verstrekking-van-psychologische-zorg">Geconventioneerde psychologen</a> zijn net als alle andere psychologen gebonden aan het beroepsgeheim.
            </p>
            <p>
              De regionale netwerken voor geestelijke gezondheidszorg staan in voor een goede samenwerking tussen de zorgverleners, de structuren en instellingen en voor een hoge kwaliteit en continuïteit van de zorg. Deze netwerken zijn zo georganiseerd dat de zorg zo dicht mogelijk aanwezig is in de spreekkamers van de zorgverleners, in de buurt (op school, in bedrijven en sportclubs…). Voor wie zich niet kan verplaatsen, is zorg aan huis of via video mogelijk.
            </p>
            <p>
              Het is mogelijk zorg te krijgen in een ander netwerk dan dat van de eigen regio, wanneer de juiste specialiteit niet voorhanden is in de eigen regio.
            </p>
            <p>
              Om beter te beantwoorden aan individuele noden zijn de netwerken verdeeld volgens de 2 soorten leeftijdscategorieën voor gespecialiseerde zorgen ook volgens 2 leeftijdscategorieën: <a href="https://www.jongerenwelzijn.be/">Kinderen en adolescenten</a> (tot en met 23 jaar) en <a href="https://www.inami.fgov.be/SiteCollectionDocuments/psy_netwerken_volwassenen_psy_reseaux_adultes_citizen.pdf">Volwassenen</a> (vanaf 15 jaar).
            </p>
          </section>
          <section className="article-section">
            <h2>RIZIV-Conventionering</h2>
            <p>
              Het <a href="https://www.riziv.fgov.be/nl/Paginas/default.aspx">Rijksinstituut voor ziekte- en invaliditeitsverzekering</a> (RIZIV) is een belangrijke instelling binnen de Belgische sociale zekerheid. Het instituut zorgt er onder andere voor dat iedere (door een ziekenfonds verzekerde) burger toegang heeft tot kwaliteitsvolle geneeskundige zorg die doeltreffend en volgens de tariefakkoorden verleend wordt. <a href="https://www.riziv.fgov.be/nl/Paginas/default.aspx">Meer info hier</a>.
            </p>
            <p>
              De <a href="https://www.riziv.fgov.be/nl/thema-s/verzorging-kosten-en-terugbetaling/financiele-toegankelijkheid/derdebetalersregeling">derdebetalersregeling</a> zorgt ervoor dat het ziekenfonds (of de mutualiteit) het bedrag van de tegemoetkoming rechtstreeks uitbetaalt aan de zorgverleners. Hierdoor moet je als patiënt enkel nog je eigen deel van de kosten betalen (dit wordt het remgeld genoemd). Er is de keuze van de zorgverlener zelf om deze regeling toe te passen, het is niet verplicht (behalve in bepaalde gevallen). <a href="https://www.riziv.fgov.be/nl/thema-s/verzorging-kosten-en-terugbetaling/financiele-toegankelijkheid/derdebetalersregeling">Hier info</a>.
            </p>
            <p>
              Remgeld (of persoonlijk aandeel) is het deel van het bedrag voor geneeskundige hulp en geneesmiddelen dat niet door het ziekenfonds wordt terugbetaald.
            </p>
            <p>
              De verzekeringspremie of tegemoetkoming is het te betalen deel dat niet ten laste is van de patiënt maar van het ziekenfonds. Het vormt samen met het remgeld het totale bedrag van de geleverde geneeskundige verstrekking.
            </p>
            <p>
              Dankzij de verhoogde tegemoetkoming (of voorkeurtarief) zijn raadplegingen, geneesmiddelen, hospitalisatie, enz. goedkoper voor de personen die hier recht op hebben. In bepaalde gevallen hebben ze hier automatisch recht op. In andere gevallen moeten ze daarvoor een aanvraag indienen bij hun ziekenfonds.
            </p>
            <p>
              De ziekteverzekering maakt deel uit van de Belgische sociale zekerheid. Elke burger moet de verzekering hebben en is verplicht zich aan te sluiten bij een erkend ziekenfonds naar keuze. Wie geen betalend lid wil worden van een ziekenfonds kan zich gratis aansluiten bij de <a href="https://www.hziv.fgov.be/">Hulpkas voor Ziekte- en Invaliditeitsverzekering</a> (HZIV).
            </p>
            <p>
              Een ziekenfonds of mutualiteit zorgt voor de verplichte verzekering tegen kosten in geval van ziekte.
            </p>
            <ul>
              <li><a href="https://www.cm.be/">Christelijke Mutualiteit Vlaanderen (CM)</a></li>
              <li><a href="https://www.helan.be/">Helan</a></li>
              <li><a href="https://www.hziv.fgov.be/">Hulpkas voor Ziekte- en Invaliditeitsverzekering (HZIV)</a></li>
              <li><a href="https://www.ocm-cdz.be/nl/particulieren/mijn-ziekenfonds-en-ik/mijn-aansluiting/hr-rail-kgv">Kas der geneeskundige verzorging van HR Rail (KGV)</a></li>
              <li><a href="https://www.lm.be/">Liberale mutualiteit</a></li>
              <li><a href="https://www.nzvl.be/">Neutraal ziekenfonds Vlaanderen</a></li>
              <li><a href="https://www.solidaris.be/">Solidaris</a></li>
              <li><a href="https://www.vnz.be/">Vlaams & Neutraal Ziekenfonds</a></li>
            </ul>
          </section>
          <section className="article-section">
            <h2>Elektronisch PatiëntenDossier (EPD)</h2>
            <p>
              In een elektronisch patiëntendossier (EPD) worden je medische gegevens digitaal bijgehouden door je zorgverlener. Dit zijn identificatiegegevens zoals je naam, adres en geboortedatum en informatie over je gezondheid en levensstijl. Ook eventuele aandoeningen, resultaten van onderzoeken en de zorg en behandeling die je kreeg zijn in je patiëntendossier opgenomen. Dat heeft verschillende voordelen:
            </p>
            <ul>
              <li>Je dossier is overzichtelijker, je gegevens worden centraal en duurzaam beheerd.</li>
              <li>De informatie kan vlotter gedeeld worden tussen de zorgverleners die je behandelen.</li>
              <li>Je vermijdt dubbele onderzoeken en administratieve overlast.</li>
            </ul>
            <p>
              Elektronische patiëntendossiers worden zorgvuldig bijgehouden en volgens de strengste normen van privacy en veiligheid bewaard.
            </p>
            <p>
              Artsen en andere zorgverleners mogen je gegevens niet delen of raadplegen zonder jouw toestemming. Die toestemming kun je zelf registreren <a href="https://www.mijngezondheid.belgie.be/#/consent-campaign">hier</a>.
            </p>
            <p>
              Op dit moment is er voor klinisch psychologen nog geen verplichting om een elektronisch patiëntendossier (EPD) bij te houden.
            </p>
            <p>
              Alle informatie die een psycholoog over zijn cliënt verwerkt, valt onder het beroepsgeheim. Hier wordt strikt vertrouwelijk mee omgegaan. Worden er toch gegevens met derden uitgewisseld (bv. met je huisarts, andere hulpverleners, gezinsleden), dan gebeurt dat pas nadat je hier nadrukkelijk in meegestemd hebt. In een zeer beperkt aantal gevallen kan het beroepsgeheim opgeheven worden. Dit gebeurt dan enkel wanneer je zelf een gevaar bent voor jezelf of je omgeving. In principe kan enkel een rechter het beroepsgeheim van een psycholoog doorbreken.
            </p>
          </section>
        </div>
        <div className="informatie-image-wrapper">
          <img className="info-image" src={informatieImage} alt="Informatie" />
        </div>
      </main>
      <Footer/>
    </div>
  );
};

export default Terugbetaling;
