export const routes = {
  home: "/",
  hulpverlener: "/hulpverlener",
  informatie: "/informatie",
  terugbetaling: "/terugbetaling",
  soortenzorg: "/soortenzorg",
  privacy: "/privacy",
  questionnaire: "/questionnaire",
  myAccount: "/my-account",
  results: "/results",
  providerDetails: "/provider-details",
  contactForm: "/contact/:providerId/:firstName",
};
