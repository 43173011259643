export enum ConsultationType {
  LIVE = "LIVE",
  ONLINE = "ONLINE",
  HYBRID = "HYBRID",
}

export function toConsultationType(s: string) {
  switch (s.toUpperCase()) {
    case "LIVE":
      return ConsultationType.LIVE;
    case "ONLINE":
      return ConsultationType.ONLINE;
    case "HYBRID":
      return ConsultationType.HYBRID;
  }
}
