import checkIcon from "../../assets/check.svg";
import { PropsWithChildren } from "react";
import "./SelectButton.css";

export function SelectButton({
  onClick,
  selected,
  children,
}: PropsWithChildren<{
  onClick: () => void;
  selected: boolean;
}>) {
  return (
    <button
      onClick={onClick}
      className={`select-button-answer large-button${
        selected ? " active" : ""
      }`}
    >
      <div className={"select-button-checkbox"}>
        {selected ? <img src={checkIcon} alt={"select-button-answer"} /> : null}
      </div>
      <div className={"select-button-children"}>{children}</div>
    </button>
  );
}
