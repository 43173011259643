import { ChosenAnswer, Category, Question, ScoresWithAnswerType, MethodScoresType, OrganisationScoresType, MediaScoresType } from "../types/Types";

type SubscriberCallback = () => void;

class ResultsStore {
  private static instance: ResultsStore;
  public finishedCategories: Set<string> = new Set();
  public answers: ChosenAnswer[] = [];
  public allFinished = false;
  public showExtraInfo = false;
  public extraInfo: string | undefined = undefined;
  public physicalDiagnose: string | undefined = undefined;
  public mentalDiagnose: string | undefined = undefined;

  // using Set to avoid duplicates
  private subscribers = new Set<SubscriberCallback>();

  private constructor() {
    // Private constructor for singleton pattern
  }

  public static getInstance(): ResultsStore {
    if (!ResultsStore.instance) {
      ResultsStore.instance = new ResultsStore();
    }
    return ResultsStore.instance;
  }

  public subscribe(callback: SubscriberCallback) {
    this.subscribers.add(callback);
  }

  public unsubscribe(callback: SubscriberCallback) {
    this.subscribers.delete(callback);
  }

  private notifySubscribers() {
    this.subscribers.forEach(callback => callback());
  }

  public setAnswers(answers: ChosenAnswer[]) {
    this.answers = answers;
    this.notifySubscribers();
  }

  public setAnswersForQuestion(addedAnswers: ChosenAnswer[]) {
    const { questionId } = addedAnswers[0];
  
    // Reset extra info states
    this.showExtraInfo = false;
    this.extraInfo = undefined;
  
    // Determine if extra info needs to be shown and set the states
    addedAnswers.forEach(answer => {
      if (answer.extraInfo) {
        this.showExtraInfo = true;
        this.extraInfo = answer.extraInfo; // Assuming last non-null wins
      }
    });
  
    // Update answers by filtering out old answers to the same question and adding new ones
    this.answers = this.answers.filter(answer => answer.questionId !== questionId).concat(addedAnswers);
  
    // Notify all subscribers about the changes
    this.notifySubscribers();
  }

  public finishCategory(category: Category) {
    this.finishedCategories.add(category.id);
    this.notifySubscribers();
  }

  public isCategoryFinished(category: Category): boolean {
    return this.finishedCategories.has(category.id);
  }

  public isAnswerActive(answer: ChosenAnswer): boolean {
    return this.answers.some(a => a.id === answer.id);
  }

  public addAnswer(answer: ChosenAnswer) {
    this.answers.push(answer);
    this.notifySubscribers();
  }

  public removeAnswer(answer: ChosenAnswer) {
    this.answers = this.answers.filter(a => a.id !== answer.id);
    this.notifySubscribers();
  }

  public setShowExtraInfo(showExtraInfo: boolean) {
    this.showExtraInfo = showExtraInfo;
    this.notifySubscribers();
  }

  public finishAll() {
    this.allFinished = true;
    this.notifySubscribers();
  }

  public setFreeTextAnswer(freeTextAnswer: string | undefined, question: Question) {
    if (question.code === "PHYSICAL_DIAGNOSE") {
      this.physicalDiagnose = freeTextAnswer;
    } else if (question.code === "MENTAL_DIAGNOSE") {
      this.mentalDiagnose = freeTextAnswer;
    }
    this.notifySubscribers();
  }

  public getScoresWithAnswerId() {
    const scores: ScoresWithAnswerType = {};
    
    this.answers.forEach((answer) => {
        answer.answerMethodWeights.forEach((weight) => {
            if (scores[weight.methodId] === undefined) {
                scores[weight.methodId] = { score: 0, answerId: answer.id };
            }
            scores[weight.methodId].score += weight.weight * ((answer.weight || 10) / 10);
        });
        
        const sum = Object.values(scores).reduce((tot, score) => tot + score.score, 0);
        Object.keys(scores).forEach((key) => (scores[key].score /= sum));
    });
    
    return scores;
  }


  public getScores() {
    const methodScores: MethodScoresType = {};
    const organisationScores: OrganisationScoresType = {};
    const mediaScores: MediaScoresType = {};
  
  
    if (this.answers.length > 0) {
      // Calculate method scores
      this.answers.forEach((answer) =>
        answer.answerMethodWeights.forEach((weight) => {
          if (methodScores[weight.methodId] === undefined) {
            methodScores[weight.methodId] = 0;
          }
          methodScores[weight.methodId] += weight.weight * ((answer.weight || 10) / 10);
        })
      );
  
      // Calculate organisation scores
      this.answers.forEach((answer) =>
        answer.answerOrganisationWeights.forEach((weight) => {
          if (organisationScores[weight.organisationId] === undefined) {
            organisationScores[weight.organisationId] = 0;
          }
          organisationScores[weight.organisationId] += weight.weight * ((answer.weight || 10) / 10);
        })
      );
  
      // Calculate media scores
      this.answers.forEach((answer) =>
        answer.answerMediaWeights.forEach((weight) => {
          if (mediaScores[weight.mediaId] === undefined) {
            mediaScores[weight.mediaId] = 0;
          }
          mediaScores[weight.mediaId] += weight.weight * ((answer.weight || 10) / 10);
        })
      );
  /*
      // Normalize scores
      const normalizeScores = (scores: { [id: string]: number }) => {
        const sum = Object.values(scores).reduce((tot, score) => tot + score, 0);
        if (sum > 0) {
          Object.keys(scores).forEach((key) => (scores[key] /= sum));
        }
      };
  
      normalizeScores(methodScores);
      normalizeScores(organisationScores);
      normalizeScores(mediaScores);
    
  */
    }
    return {
      methodScores,
      organisationScores,
      mediaScores,
    };
  }
}

export default ResultsStore.getInstance();
