import React, { useState, useEffect } from 'react';
import './WaitingGauge.css'; // Similar to MatchGauge.css

export function WaitingGauge({ avgWaitingDurationWeeks, imageRef }: { avgWaitingDurationWeeks: number; imageRef: React.RefObject<HTMLImageElement> }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupWidth, setPopupWidth] = useState<number | undefined>(undefined);
  const [gaugeWidth, setGaugeWidth] = useState<string | undefined>(undefined);
  const [gaugeWidthInPopup, setGaugeWidthInPopup] = useState<string | undefined>(undefined);
  if(avgWaitingDurationWeeks < 0) { 
    avgWaitingDurationWeeks = 0;
  }
  // Toggle popup on click
  const handleTogglePopup = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent event propagation
    setIsPopupVisible(!isPopupVisible);
  };

  // Get label based on waiting weeks
  const getLabel = (weeks: number) => {
    if (weeks < 3) return 'Kort';
    if (weeks < 5) return 'Gemiddeld';
    if (weeks < 9) return 'Lang';
    return 'Erg lang';
  };

  // Show popup on hover
  const handleMouseEnter = () => {
    setIsPopupVisible(true);
  };

  // Hide popup on mouse leave
  const handleMouseLeave = () => {
    setIsPopupVisible(false);
  };

  // Use the image's width to set the popup width when the popup is shown
  useEffect(() => {
    if(imageRef.current) {
      if (isPopupVisible) {
        setPopupWidth(imageRef.current.offsetWidth - 60);
        setGaugeWidthInPopup(`${Math.min(avgWaitingDurationWeeks, 8) / 8 * 100}%`);
      }
      else {
        setGaugeWidthInPopup('0');
      }
    } 
  }, [isPopupVisible, imageRef]);

  setTimeout(() => {
    setGaugeWidth(`${Math.min(avgWaitingDurationWeeks, 8) / 8 * 100}%`);
  }, 100); // Slight delay to allow transition

  return (
    <div className='waiting-gauge-div'
      onClick={handleTogglePopup}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='first-item'>
        Wachttijd: 
      </div>
      <div className='second-item'>
      <div className="waiting-gauge" style={{ width: '100px', marginRight: '20px' }}>
        <div
          className="waiting-gauge-fill"
          style={{
            width: gaugeWidth,
            backgroundColor:
              avgWaitingDurationWeeks < 4
                ? '#00acc1'
                : avgWaitingDurationWeeks < 7
                ? '#ed9912'
                : '#bf3e1e',
          }}
        />
      </div>
      </div>

      {/* Popup with bigger Gauge */}
      {isPopupVisible && (
        <div className='waiting-gauge-popup'
          style={{ width: popupWidth }} // Set the popup width to match the image width
        >
          <div className="waiting-gauge" style={{ width: '100px' }}>
            <div
            className="waiting-gauge-fill"
            style={{
              width: gaugeWidthInPopup,
              backgroundColor:
                avgWaitingDurationWeeks < 4
                  ? '#00acc1'
                  : avgWaitingDurationWeeks < 7
                  ? '#ed9912'
                  : '#bf3e1e',
              }}
            />
          </div>
          <div>
          {getLabel(avgWaitingDurationWeeks)} ({avgWaitingDurationWeeks} weken)
          </div>
        </div>
      )}
    </div>
  );
}
