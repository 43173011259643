import ApiInstance from "./ApiInstance";
import { Category } from "../types/Types";


class CategoryApi {
  public async getCategories(): Promise<Category[]> {
    const response = await ApiInstance.getApi().get("/categories/");
    return response.data;
  }

  
}

export default new CategoryApi();
