import React, { ComponentType } from "react";
//import { withAuthenticationRequired } from "@auth0/auth0-react";
import AuthHelper from "../helpers/AuthHelper";
import { useNavigate } from "react-router-dom";

export function AuthenticationGuard({
  component,
}: {
  component: ComponentType;
}) {
  const navigate = useNavigate();
  const token = AuthHelper.getToken();
  const Component = component;
  if (token === "") {
    navigate("/");
  }
  return <Component />;
}
