import React, { useState, useEffect, useRef } from 'react';
import './Hulpverlener.css';
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import Lottie from 'lottie-react';
import hulpverlenerAnimation from '../../animations/hulpverlener.json'; // Adjust path accordingly
import gratisImage from '../../animations/100gratis.json'; // Adjust path accordingly
import miaProfielImage from '../../assets/miaprofiel.svg'; // Adjust path accordingly
import miaPraktijkImage from '../../assets/miapraktijk.svg'; // Adjust path accordingly
import providerImage from '../../assets/hulpverlener.png';
import comonImage from '../../assets/comon.webp';
import imecImage from '../../assets/imec.webp';
const Hulpverlener: React.FC = () => {


  const [scrollY, setScrollY] = useState(0);
  const [parallaxHeight, setParallaxHeight] = useState('auto');


  const handleScroll = () => {
    setScrollY(window.scrollY);
  };


  const handleStarterButtonClick = () => {
    window.location.href = "https://app.miapraktijk.be/api/public/stripe/miaprofiel/checkoutsession";
  };

  const handleStandardButtonClick = () => {
    window.location.href = "https://app.miapraktijk.be/api/public/stripe/miaunlimited/checkoutsession";
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if ((videoRef.current as any).webkitRequestFullscreen) { /* Safari */
        (videoRef.current as any).webkitRequestFullscreen();
      } else if ((videoRef.current as any).msRequestFullscreen) { /* IE11 */
        (videoRef.current as any).msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    
  }, []);

  
  useEffect(() => {
    const parallaxElement = document.querySelector('.paralax');
    if (parallaxElement) {
      const rect = parallaxElement.getBoundingClientRect();
      let heightAdjustment = window.innerHeight - rect.top - window.scrollY;
      if(heightAdjustment < 600)
        heightAdjustment = 600;
      setParallaxHeight(`${heightAdjustment}px`);
    }
  }, [scrollY]);

  return (
    <div className="container">
      <Header/>
      
      <div className="top-image-wrapper">
        <Lottie className="hulpverlener-image" animationData={hulpverlenerAnimation} loop={true} />
        <Lottie className="gratis-image" animationData={gratisImage} loop={true} />
      </div>
      <main className="main-hulpverlener">
        <div className="content-wrapper-hulpverlener">
          <div className="text-section-hulpverlener">
            <h2>Wanneer we de geestelijke gezondheidszorg gemakkelijker willen maken, gaan we eerst en vooral moeten zorgdragen voor de hulpverleners.</h2>
            <p>Dat doet Mia dankzij een dubbele oplossing.</p>
            <br/>
            <div className="diagonal-container">
              <div className="left-side">
                <div className="left-side-content">
                  <h2>Mia Profiel</h2>
                  <p className="subtitle">Jouw profiel op miazoekthulp.be</p>
                  <p>Krijg enkel de cliënten die bij jou passen<br/>
                    Enkel cliënten wanneer jouw wachtlijst dit toelaat<br/>
                    Minder tijd verliezen aan doorverwijzingen<br/>
                    Bied ook cursussen en workshops aan
                    </p>
                </div>
              </div>
              <div className="right-side">
                <div className="right-side-content">
                  <h2>Mia Praktijk</h2>
                  <p className="subtitle">Alle tools voor jouw praktijk</p>
                  <p>Dankzij Mia Praktijk laten we de administratieve taken efficiënter en correcter verlopen zodat hulpverleners zich kunnen concentreren op wat er echt toe doet, namelijk de hulpvrager. Kortom, naast het profiel op miazoekthulp.be krijgt uw praktijk ook de nodige digitale ondersteuning die in deze tijd onontbeerlijk is.</p>
                  
                </div>
              </div>
            </div>
           </div>
          
        </div>
        
      </main>
      
      <div className='promointro'>
        Zo beschikken we naast <span className="important-text-hulpverlener">agendabeheer, online beveiligde sessies en een cliëntenportaal ook over praktijkruimte-verdeling, de mogelijkheid om digitale schema's aan te maken, facturatie en het digitaal overzetten van notities in een persoonlijk patiëntendossier</span>.
        
      </div>
      <div className="paralax" style={{ transform: `translateY(-${scrollY * 0.5}px)`, height: parallaxHeight }}>
      <div className='hulpverlener-buy-section'>
      <div className="promo-section">
        <div className='promocontainer'>
            <video width="100%" autoPlay muted loop
          ref={videoRef}>
              <source src="miapraktijkpromo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button 
          onClick={handleFullScreen}
          className="promobutton"
        >bekijk in full screen</button>
        </div>
        <div className="package-title">
          <h2>Aansluiten als hulpverlener is gratis</h2>
          <p>Mia zal actief worden gesteld voor hulpzoekers vanaf we beschikken over een aanbod van 100 hulpverleners. <br/>Daarom willen wij de eerste 100 hulpverleners bedanken met een permanent gratis profiel. </p>
        </div>
      </div>
      <div className="panel-container">
        <div className="panel mia-starter">
          <h2 className="panel-title">Mia Starter</h2>
          <p>Een hulpverlener profiel en optioneel gebruik van Mia praktijk</p>
          <h4 className="panel-subtitle">ACTIE EERSTE 100 PROFIELEN!</h4>
          <div className="panel-content">
            <p>Permanent gratis Mia Profiel en 3 maanden gratis dossiers in Mia Praktijk</p>
            <p><b>Mia Profiel</b> - voor altijd gratis</p>
            <p>Hulp aanbieden via de Mia website met jouw profiel tussen de resultaten.</p>
            <p><b>Mia Praktijk</b></p>
            <p>Gebruik indien gewenst Mia Praktijk en betaal na 3 maanden 5 euro éénmalig per nieuw dossier.</p>
            <br/>
          </div>
          <div className="panel-footer">
            <button className="panel-button" onClick={handleStarterButtonClick}>Permanent gratis</button>
          </div>
        </div>
        <div className="panel mia-standaard">
          <h2 className="panel-title">Mia Standaard</h2>
          <p>Een hulpverlener profiel en onbeperkt gebruik van Mia praktijk</p>
          <h4 className="panel-subtitle">3 maanden gratis en daarna € 39 per maand</h4>
          <div className="panel-content">
            <p><b>Mia Profiel</b> - voor altijd gratis</p>
            <p>Hulp aanbieden via de Mia website met jouw profiel tussen de resultaten.</p>
            <p><b>Mia Praktijk</b></p>
            <p>Gebruik Mia Praktijk met een onbeperkt aantal dossiers en betaal €0 per dossier.</p>
          </div>
          <div className="panel-footer">
            <button className="panel-button" onClick={handleStandardButtonClick}>Gratis proefversie starten</button>
          </div>
        </div>
      </div>
      </div>
      <div className="sponsors">
        <h2>Met ondersteuning van onze partners</h2>
        <p>
          Deze slimme oplossing is bedacht in de schoot van Comon, een initiatief van de stad Gent in samenwerking met Imec. Tijdens een van de Miniproeftuinen over verstaanbare zorg heeft een divers team dit idee verder ontwikkeld tot een werkend prototype. Met dank aan deze partners:
          <br/><br/>
          <div className="sponsors-images">
            <img src={imecImage} alt="Imec" />
            <img src={comonImage} alt="Comon" />
          </div>
        </p>
      </div>

      <Footer /> {/* Add the Footer component here */}
      </div>
    </div>
  );
};

export default Hulpverlener;
