import React, { useState } from 'react';
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import './Informatie.css';
import informatieImage from '../../assets/informatie.svg'; // Adjust path accordingly

const SoortenZorg: React.FC = () => {


  const handleBackClick = () => {
    window.location.href = "/informatie"; // Adjust this path as necessary
  };

  return (
    <div className="container">
      <Header/>
      <main className="main-info">
        <div className="content-wrapper-article">
          <section className="intro-section-in-article">
            <h1>Welkom in het Mia informatie-luik</h1>
            <p>Welke soorten therapieën bestaan er? Heb ik recht op terugbetaling? Wat mag ik verwachten van therapie? Heb ik zelfs een psycholoog nodig? We voorzien een antwoord op enkele vragen!</p>
          </section>
          <a className="back-button" onClick={handleBackClick}>
            <span className="back-button-text">Terug naar overzicht</span>
          </a>
          <h1>Het Hulpverlenings-landschap in de Geestelijke Gezondheidszorg in België</h1>
          <section className="article-section">
            <h2>Inleiding</h2>
            <p>
              Het zoeken naar gepaste hulp binnen de geestelijke gezondheidszorg kan overweldigend zijn. In België is er een breed scala aan diensten en instellingen die ondersteuning bieden bij psychische problemen. Deze gids helpt je navigeren door de verschillende mogelijkheden.
            </p>
          </section>
          <section className="article-section">
            <h2>Eerste Lijnshulp</h2>
            <p>
              Huisarts: Je huisarts kan je situatie beoordelen, een eerste behandeling starten of je doorverwijzen naar gespecialiseerde zorg. Onafhankelijke psychologen en therapeuten. Je kan ook bij Mia de <a href="https://profiel.miapraktijk.be/questionnaire">test</a> doen om een overzicht te vinden van waar je terecht kan.
            </p>
          </section>
          <section className="article-section">
            <h2>Centra Geestelijke Gezondheidszorg (CGG)</h2>
            <p>Naast de grote groep onafhankelijke psychologen en therapeuten zijn er enkele centra in elke provincie waar je hulp kan vinden. Hier zijn meestal wel lange wac  htlijsten.</p>
            <h3>Vlaanderen</h3>
            <ul>
              <li>Antwerpen: CGG Vagga biedt diverse vormen van geestelijke gezondheidszorg. Meer informatie kun je vinden op <a href="https://www.vagga.be/nl">CGG Vagga</a>.</li>
              <li>Antwerpen: CGG Andante biedt diverse vormen van geestelijke gezondheidszorg. Meer informatie kun je vinden op <a href="https://www.centrageestelijkegezondheidszorg.be/cgg/andante">CGG Andante</a>.</li>
              <li>Limburg: Integra Limburg biedt hulp in de provincie Limburg. Bezoek hun website op <a href="https://www.integra-limburg.be/">Integra Limburg</a>.</li>
              <li>Oost-Vlaanderen: CGG Adentro in regio Gent-Deinze-Eeklo biedt therapie en ondersteuning. Meer info <a href="https://www.centrageestelijkegezondheidszorg.be/cgg/adentro">hier</a>.</li>
              <li>West-Vlaanderen: CGG Largo heeft vestigingen in verschillende steden waaronder Brugge en Kortrijk. Details vind je op <a href="https://www.cgglargo.be/">CGG Largo</a>.</li>
              <li>Vlaams-Brabant: CGG PassAnt biedt diensten aan in deze regio. Hun website is <a href="https://www.passant.be/">CGG PassAnt</a>.</li>
            </ul>
            <h3>Brussel</h3>
            <ul>
              <li>Brussel Hoofdstedelijk Gewest: CGG Brussel biedt hulp aan mensen met psychische problemen in het tweetalige Brussel. Bezoek <a href="https://www.centrageestelijkegezondheidszorg.be/cgg/brussel">CGG Brussel</a> voor meer informatie.</li>
            </ul>
          </section>
          <section className="article-section">
            <h2>Psychiatrische Ziekenhuizen en Afdelingen</h2>
            <p>
              PAAZ-afdelingen bieden psychiatrische zorg binnen een algemeen ziekenhuis. Deze afdelingen zijn gericht op de opname van patiënten met acute psychiatrische problemen waarvoor tijdelijke, intensieve behandeling nodig is. De behandeling op een PAAZ kan variëren van crisisinterventie en stabilisatie tot uitgebreide diagnostische evaluatie en kortdurende behandelingen. De toegang tot een PAAZ is vaak via een verwijzing van een huisarts of via de spoeddienst van het ziekenhuis.
            </p>
            <h3>Vlaanderen</h3>
            <ul>
              <li>Antwerpen: ZNA Stuivenberg in Antwerpen heeft een bekende PAAZ afdeling. Meer informatie is te vinden op <a href="https://www.zna.be/nl/Stuivenberg">ZNA Stuivenberg</a>.</li>
              <li>Limburg: Jessa Ziekenhuis in Hasselt beschikt over een PAAZ met uitgebreide zorgmogelijkheden. Bekijk hun aanbod op <a href="https://www.jessazh.be/">Jessa Ziekenhuis</a>.</li>
              <li>Oost-Vlaanderen: AZ Sint-Lucas in Gent biedt psychiatrische zorg op hun PAAZ afdeling. Meer details op <a href="https://www.azstlucas.be/">AZ Sint-Lucas</a>.</li>
              <li>West-Vlaanderen: AZ Groeninge in Kortrijk heeft faciliteiten voor psychiatrische zorg. Informatie is beschikbaar op <a href="https://www.azgroeninge.be/">AZ Groeninge</a>.</li>
              <li>Vlaams-Brabant: UZ Leuven in Leuven heeft een uitgebreide psychiatrische afdeling die diverse behandelingen aanbiedt. Hun website is <a href="https://www.uzleuven.be/">UZ Leuven</a>.</li>
            </ul>
            <h3>Brussel</h3>
            <ul>
              <li>Brussel Hoofdstedelijk Gewest: UMC Sint-Pieter biedt psychiatrische zorg en behandelingen in het hart van Brussel. Meer informatie op <a href="https://www.stpierre-bru.be/">UMC Sint-Pieter</a>.</li>
            </ul>
          </section>
          <section className="article-section">
            <h2>Zelfhulp en Peer Support</h2>
            <p>
              Voorbeelden van organisaties: Similes is een organisatie die ondersteuning biedt aan familieleden van mensen met psychische problemen. Hun website is <a href="https://www.similes.be/">Similes</a>.
            </p>
          </section>
          <section className="article-section">
            <h2>Online Hulpverlening</h2>
            <p>
              Platformen: Op <a href="https://www.tele-onthaal.be/">Tele-Onthaal</a> kun je anoniem chatten of bellen over persoonlijke, emotionele problemen.
            </p>
          </section>
          <section className="article-section">
            <h2>Specifieke Doelgroepen</h2>
            <p>
              Jongeren: <a href="https://www.awel.be/">AWEL</a> biedt luisterende oren voor jongeren. Bezoek hun site op <a href="https://www.awel.be/">AWEL</a>.
            </p>
            <p>
              Ouderen: Verschillende instellingen bieden een Ouderenpsychiatrie Centrum aan met specifieke hulp voor ouderen met psychische problemen.
            </p>
          </section>
          <section className="article-section">
            <h2>Dringende Hulp</h2>
            <p>
              Voor mensen met zelfmoordgedachten of die onmiddellijke psychische hulp nodig hebben:
              <ul>
                <li>Zelfmoordlijn: Beschikbaar via <a href="https://www.zelfmoord1813.be/">Zelfmoord1813</a> waar je kan chatten of bellen met getrainde vrijwilligers.</li>
                <li>Spoeddiensten: In acute crisissituaties kun je terecht bij de spoeddienst van een ziekenhuis.</li>
              </ul>
            </p>
          </section>
          <section className="article-section">
            <h2>Conclusie</h2>
            <p>
              Het is belangrijk om de beschikbare hulpbronnen te kennen en te gebruiken wanneer je geestelijke gezondheidszorg nodig hebt. Van eerste hulp bij je huisarts tot gespecialiseerde zorg in psychiatrische centra, er zijn verschillende opties beschikbaar om steun en behandeling te vinden in België.
            </p>
          </section>
        </div>
        <div className="informatie-image-wrapper">
          <img className="info-image" src={informatieImage} alt="Informatie" />
        </div>
      </main>
      <Footer/>
    </div>
  );
};

export default SoortenZorg;
