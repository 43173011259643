import "./Questionnaire.css";
import { useEffect, useState } from "react";
import { ProgressBar } from "../../components/progress-bar/ProgressBar";
import { BaseQuestion } from "../../components/questions/base-question/BaseQuestion";
import { Category, Question, QuestionType } from "../../types/Types";
import QuestionnaireApi from "../../api/QuestionnaireApi";
import ProviderApi from "../../api/ProviderApi";
import { useResultsContext } from "../../hooks/useResultsContext";
import { SelectButton } from "../../components/select-button/SelectButton";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import ProgressDisplay from "../../components/progress-display/ProgressDisplay";
import ProgressGauge from "../../components/progress-gauge/ProgressGauge";

export function Questionnaire() {
  const [qState, setQState] = useState<{
    categoryQuestions: Question[];
    subQuestions: Question[];
    subQuestion: Question | undefined;
    categories: Category[];
    questionIndex: number;
    categoryIndex: number;
    overview: boolean;
  }>({
    categoryQuestions: [],
    subQuestions: [],
    subQuestion: undefined,
    categories: [],
    questionIndex: 0,
    categoryIndex: 0,
    overview: false,
  });
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [showedCategoryIntro, setShowedCategoryIntro] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showStopQuestionnaireInfo, setShowStopQuestionnaireInfo] = useState(false);
  const [stopQuestionnaireReason, setStopQuestionnaireReason] = useState<string | null>(null);
  const [showCategorySelection, setShowCategorySelection] = useState(false);

  const showTooltip = (e) => {
    const { pageX, pageY } = e; // Get cursor position
    setTooltipPosition({ x: pageX, y: pageY });
    setTooltipVisible(true);
  };

  const hideTooltip = () => {
    setTooltipVisible(false);
  };

  const {
    finishCategory,
    isCategoryFinished,
    anyAnswerChosen,
    allFinished,
    finishAll,
    isAnswerActive,
    showExtraInfo,
    setShowExtraInfo,
    extraInfo,
    physicalDiagnose,
    mentalDiagnose, 
    getScores
  } = useResultsContext();

  const navigate = useNavigate();

  const currentQuestion = qState.categoryQuestions[qState.questionIndex];
  const alreadyFinishedCategories = allCategories.filter(isCategoryFinished);

  const sortByOrder = <T extends Question | Category | {order: number}>(orderables: T[]): T[] =>
    orderables.sort((a, b) => a.order - b.order);

  const [matchCounts, setMatchCounts] = useState({
    strong: 0,
    veryGood: 0,
    good: 0,
    reasonable: 0
  });

  useEffect(() => {
    if (qState.categories.length && !showedCategoryIntro) {
      const fetchMatchCounts = async () => {
        const counts = await ProviderApi.countProviderMatches(getScores().methodScores);
        setMatchCounts(counts);
      };
      fetchMatchCounts();
    }
  }, [qState.categoryIndex, showedCategoryIntro]);

  useEffect(() => {
    QuestionnaireApi.getQuestionnaire().then((questionnaire) => {
      setAllCategories(sortByOrder(questionnaire.categories));
      const subQuestions: Question[] = [];
      questionnaire.categories.map(category => {
        category.questions.map(question => {
          question.answers = sortByOrder(question.answers);
          if(question.answerId !== null) {
            subQuestions.push(question);
          }
        });
      });
      if (allFinished) {
        setQState((prev) => ({ ...prev, overview: true, subQuestions: subQuestions }));
      } else {
        setQState((prev) => ({
          ...prev,
          subQuestions: subQuestions,
          categories: sortByOrder(questionnaire.categories),
          categoryQuestions: sortByOrder(sortByOrder(questionnaire.categories)[0].questions.filter(question => question.answerId === null)),
        }));
        setShowedCategoryIntro(false);
      }
      setShowContent(true);
    });
  }, [allFinished]);

  
  const nextPage = () => {
    
    window.scrollTo(0, 0);
    if(qState.subQuestion === undefined) {
      const answer = qState.categoryQuestions[qState.questionIndex].answers.find(answer => isAnswerActive(answer));
       
      // Check if the answer has stopQuestionnaire set to true
      if (answer && answer.stopQuestionnaire) {
        // Set the stop reason and show the stopQuestionnaire info
        setStopQuestionnaireReason(answer.stopQuestionnaireReason || "Er is geen reden opgegeven.");
        setShowStopQuestionnaireInfo(true); // Show the stop reason
        setShowContent(false); // Hide the content
        return; // Stop the flow
      }

      if(answer !== undefined) {
        const subQuestion = qState.subQuestions.find(question => question.answerId === answer.id);
        if(subQuestion !== undefined)
        {
          setQState((prev) => ({ ...prev, 
            subQuestion: subQuestion }));
          return;
        }
      }
      
    }
    setQState((prev) => ({ ...prev, 
      subQuestion: undefined }));
    
    if (qState.questionIndex + 1 < qState.categoryQuestions.length) {
      setQState((prev) => ({ ...prev, questionIndex: prev.questionIndex + 1 }));
    } else {
      if (qState.categoryIndex + 1 < qState.categories.length) {
        finishCategory(qState.categories[qState.categoryIndex]);
        setShowedCategoryIntro(false);
        setQState((prev) => {
          const categoryIndex = prev.categoryIndex + 1;
          return {
            ...prev,
            category: prev.categories[categoryIndex],
            categoryQuestions: sortByOrder(
              prev.categories[categoryIndex].questions.filter(question => question.answerId === null)
            ),
            questionIndex: 0,
            categoryIndex,
          };
        });
      } else {
        finishCategory(qState.categories[qState.categoryIndex]);
        finishAll();
        setQState((prev) => ({
          ...prev,
          overview: true,
        }));

        setShowCategorySelection(true);
      }
    }
  };

  const prevPage = () => {

    window.scrollTo(0, 0);
    if(qState.subQuestion !== undefined) { 
      setQState((prev) => ({ ...prev, 
        subQuestion: undefined }));
        return;
    }
    if (qState.questionIndex - 1 >= 0) {
      setQState((prev) => ({ ...prev, questionIndex: prev.questionIndex - 1 }));
    } else {
      if (qState.categoryIndex - 1 >= 0) {
        setShowedCategoryIntro(false);
        setQState((prev) => {
          const categoryIndex = prev.categoryIndex - 1;
          return {
            ...prev,
            category: prev.categories[categoryIndex],
            categoryQuestions: sortByOrder(
              prev.categories[categoryIndex].questions.filter(question => question.answerId === null)
            ),
            questionIndex: 0,
            categoryIndex,
          };
        });
      } else {
        setQState((prev) => ({
          ...prev,
          overview: true,
        }));
      }
    }
  };

  return (
    <>
  

    {showExtraInfo? (
      <div className="extra-info">
        <div className="extra-info-width">
          <img src="/info.jpg" className={"info-icon"} alt="information"></img>
          <div dangerouslySetInnerHTML={{ __html: extraInfo !== undefined? extraInfo : "" }} />
          <a className="small-button dark-button" onClick={() => {setShowExtraInfo(false);}}>Verder gaan</a>
        </div>
      </div>) : (<></>)}
    
    <div className="container">
    <Header/>
    <div className={"questionnaire-container"}>

    {showCategorySelection && (
      <div className="category-selection">
        <h3>Welke soort hulpverlening wil je graag eerst bekijken?</h3>
        <div className="button-group">
          <div
            className="selection-button red-button"
            onClick={() => navigate(`${routes.results}?tab=PSYCHOLOGIST`)}
          >
            Psychologen en therapeuten

            <p className="subtext">
              Vind professionele hulp aansluitend aan jouw uitdagingen.
            </p>
          </div>
          <div
            className="selection-button dark-yellow-button"
            onClick={() => navigate(`${routes.results}?tab=ORGANISATION`)}
          >
            Organisaties
            <p className="subtext">
              Vind ondersteunende organisaties die je kunnen helpen in jouw situatie.
            </p>
          </div>
          <div
            className="selection-button yellow-button"
            onClick={() => navigate(`${routes.results}?tab=MEDIA`)}
          >
            Lees-, kijk- en luistertips

            <p className="subtext">
              Ontdek boeken, podcasts en video's om meer te leren over jouw situatie.
            </p>
          </div>
        </div>
      </div>
    )}

    {showStopQuestionnaireInfo && (
      <div>
        <div>
          <img src="/info.jpg" className="info-icon" alt="information" />
          <div>
            <div dangerouslySetInnerHTML={{ __html: stopQuestionnaireReason !== null? stopQuestionnaireReason : "" }} />
            <br/>
            <a
              className="small-button white-button"
              onClick={() => {
                setShowStopQuestionnaireInfo(false); // Hide the stop questionnaire info
                setShowContent(true); // Show the content again
              }}
            >
              Vorige
            </a>
          </div>
         
        </div>
      </div>
    )}
    {showContent && !showExtraInfo? ( 
      <div>
      {currentQuestion !== undefined && !qState.overview ? (
        
        <>
        {qState.categories.length && !showedCategoryIntro? (
        <div className={"category-intro"}>
         
        <div className={`category-content`}>
          <div className={"category-title"}>
            Stap {qState.categoryIndex + 1} van {qState.categories.length} : {qState.categories[qState.categoryIndex].name}
          </div>
          <p>{qState.categories[qState.categoryIndex].description}</p>

          <div>
            <a className="small-button dark-button" onClick={() => {hideTooltip(); setShowedCategoryIntro(true);}}>Daar gaan we</a>
          </div>
          <br/>
        </div>
        {qState.categoryIndex > 0 ? (
        <div className="matches-info" onClick={showTooltip}>
          <b>hulpverleners voor jouw situatie</b>
          <ProgressDisplay 
          percentComplete={((qState.categoryIndex) / qState.categories.length * 100).toFixed(2)}
          strongMatches={matchCounts.strong}
          veryGoodMatches={matchCounts.veryGood}
          goodMatches={matchCounts.good}
          reasonableMatches={matchCounts.reasonable}
        />
          <br/>
          <b>percentage voltooid</b>
          <br/>
          
          <ProgressGauge percentComplete={((qState.categoryIndex) / qState.categories.length * 100).toFixed(0)} />

          {tooltipVisible && (
            <div className="tooltip"
              style={{
              left: tooltipPosition.x,
              top: tooltipPosition.y
            }}>
              Voltooi de vragenlijst door op 'Daar gaan we' te klikken.
            </div>
          )}
          
        </div>):(<></>)}
       
        
      </div>
      
        ) : (
          <>
          <ProgressBar
            sortedQuestions={qState.categoryQuestions}
            questionIndex={qState.questionIndex}
            onClick={(questionIndex) =>
              setQState((prev) => ({
                ...prev,
                questionIndex,
              }))
            }
            showCategories={allFinished}
            categories={qState.categories}
            categoryIndex={qState.categoryIndex}
          />
          {qState.subQuestion === undefined ? (
            <BaseQuestion question={currentQuestion} onNext={nextPage} />
          ) : (
            <BaseQuestion question={qState.subQuestion} onNext={nextPage} />
          )}

          <div className={"questionnaire-bottom"}>
            {(!(!allFinished && qState.questionIndex === 0)) || qState.subQuestion !== undefined ? (
              <a
                className={"small-button white-button"}
                onClick={prevPage}
              >
                Vorige
              </a>
            ) : (
              <div />
            )}
            {qState.subQuestion !== undefined?(
              <>
              {
                anyAnswerChosen(qState.subQuestion) ||
                qState.subQuestion.type == QuestionType.Slider ||
                qState.subQuestion.type == QuestionType.AutoComplete ? (
                  <a
                    className={"small-button dark-button"}
                    onClick={nextPage}
                  >
                    Volgende
                  </a>
                ) : null}
                </>
            ):(
              <>
              {allFinished ||
                anyAnswerChosen(currentQuestion) ||
                currentQuestion.type == QuestionType.Slider ||
                currentQuestion.type == QuestionType.AutoComplete  ? (
                  <a
                    className={"small-button dark-button"}
                    onClick={nextPage}
                  >
                    Volgende
                  </a>
                ) : null}
              </>
            )}
            
          </div>
          </>
          )}
          
        </> 
        
      ) : (
        <>
          {alreadyFinishedCategories.length > 0 && !showCategorySelection? (
            <>
              <div className={"questionnaire-categories-finished"}>
                Wens je een categorie opnieuw in te vullen?
              </div>
              <div className={"questionnaire-categories-entries"}>
                {alreadyFinishedCategories.map((category) => {
                  const isChosen = selectedCategories.indexOf(category.id);
                  return (
                    <SelectButton
                      selected={isChosen >= 0}
                      key={category.id}
                      onClick={() => {
                        if (isChosen < 0) {
                          setSelectedCategories((prev) => [
                            ...prev,
                            category.id,
                          ]);
                        } else {
                          setSelectedCategories((prev) => {
                            const categories = [...prev];
                            categories.splice(isChosen, 1);
                            return categories;
                          });
                        }
                      }}
                    >
                      {category.name}
                    </SelectButton>
                  );
                })}
              </div>
              <div
                className={"questionnaire-bottom questionnaire-categories-bottom"}
                  >

    <           Link to={routes.results} className={"small-button white-button"}>
                    Bekijk je resultaten
                  </Link>
                {selectedCategories.length > 0 ? (
                  <a
                    className={"small-button dark-button"}
                    onClick={() => {
                      const sortedCategories = sortByOrder(
                        allCategories.filter((category) =>
                          selectedCategories.includes(category.id)
                        )
                      );
                      setSelectedCategories([]);
                      setQState((prev) => ({
                        ...prev,
                        category: sortedCategories[0],
                        categories: sortedCategories,
                        categoryQuestions: sortByOrder(
                          sortedCategories[0].questions.filter(question => question.answerId === null)
                        ),
                        questionIndex: 0,
                        categoryIndex: 0,
                        overview: false,
                      }));
                    }}
                  >
                    Vul vragen in
                  </a>
                ) : null}
              </div>
            </>
          ) : null}
      
        </>
      )}
      <br/>
    </div>):(<></>)}
    </div>
    <br/><br/>
    <br/><br/>
    <br/><br/>
    <div className={"grey-area"}>
        <div className={"centered-text intro-text"}>
            <p>
              Deze vragenlijst kwam tot stand met ondersteuning en validatie van hulpverleners en hulpvragers. Mia werd gerealiseerd dankzij onze partners.
            </p>
          
            <div className={"partner-logos"}>
              <img src="/imec.png" className={"partner-logo"} alt="Imec"/>
              <img src="/comon.png" className={"partner-logo"} alt="Comon"/>
              <img src="/ugent.png" className={"partner-logo"} alt="UGent"/>

            </div>
          </div>
      </div>
      <Footer/>
    </div>
    </>
  );
}
