import React, { useEffect } from 'react';

const HeightSender = ({ wrapperRef }) => {
  const sendHeightToParent = (height) => {
    if (window.parent && window.parent !== window) {
      window.parent.postMessage({ height }, 'https://app.miapraktijk.be'); // Ensure this is the correct parent domain
      console.log('Message sent:', { height });
    }
  };

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) return;

    const observer = new ResizeObserver(() => {
      const contentHeight = wrapperElement.getBoundingClientRect().height;
      sendHeightToParent(contentHeight);
    });

    observer.observe(wrapperElement);

    // Send initial height
    const initialHeight = wrapperElement.getBoundingClientRect().height;
    sendHeightToParent(initialHeight);

    return () => {
      observer.disconnect();
    };
  }, [wrapperRef]);

  return null;
};

export default HeightSender;
