import { ConsultationType } from "../enums/ConsultationType";
import { ParkingAvailibilty } from "../enums/ParkingAvailability";
import { Sex } from "../enums/Sex";
import ApiInstance from "./ApiInstance";

export type HealthcareProviderBase = {
  name: string;
  surName: string;
  sex: Sex | null;
  pronouns: string | null;
  age: number | null;
  languages: string[];
  practiceRegionZipCode: string | null;
  beliefs: string[];
  comfortableWithLGBTQIAP: boolean | null;
  practiceName: string | null;
  practiceDescription: string | null;
  personalityDescription: string | null;
  therapeuticMotto: string | null;
  lifeMotto: string | null;
  settingTypes: string[] | null;
  consultationType: ConsultationType;
  ageGroups: string[];
  profilePictureUrl: string | null;
  practiceRoomPictureUrl: string | null;
  certifications: string;
  visumNumber: string | null;
  registrationNumber: string | null;
  professionalAssocation: string | null;
  pricePerSession: number | null;
  avgSessionMinutes: number | null;
  refundOptions: string[];
  conventioned: boolean | null;
  practiceHours: string;
  avgSessionCount: string | null;
  availableForNewPatients: boolean;
  avgWaitingDurationWeeks: number | null;
  waitingListInfo: string | null;
  address: string | null;
  landlinePhoneNumber: string | null;
  mobilePhoneNumber: string | null;
  email: string | null;
  website: string | null;
  accessibilityByPublicTransit: string[] | null;
  accessibilityByCar: string[] | null;
  parkingAvailibilty: ParkingAvailibilty | null;
  wheelchairAccessible: boolean | null;
  lat: number | null;
  lng: number | null;
  publishProfile: boolean;
};

export type HealthcareProviderData = {
  auth0AccessToken: string;
} & HealthcareProviderBase;

export type HealthcareProvider = {
  id: string;
  validated: boolean;
} & HealthcareProviderBase;

class HealthcareProviderApi {
  async getAuthenticatedHealthcareProvider(
    auth0AccessToken: string
  ): Promise<HealthcareProvider> {
    const response = await ApiInstance.getApi().get<HealthcareProvider>(
      "/healthcare-providers/me",
      {
        headers: {
          "auth0-access-token": auth0AccessToken,
        },
      }
    );
    return response.data;
  }

  public async getHealthcareProvider(
    id: string
  ): Promise<HealthcareProvider> {
    const response = await ApiInstance.getApi().get(
      `/healthcare-providers/${id}`
    );
    return response.data;
  }

  async updateAuthenticatedHealthcareProvider(
    hcProviderData: HealthcareProviderData
  ): Promise<HealthcareProvider> {
    const response = await ApiInstance.getApi().put(
      "/healthcare-providers",
      hcProviderData
    );
    return response.data;
  }
}

export default new HealthcareProviderApi();
