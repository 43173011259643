import React, { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { Config } from "../../Config";  // Ensure correct path
import { routes } from "../../constants/routes"
import {GoogleMapsAddressStyle} from "../google-maps-address/GoogleMapsAddress";

const ProviderMap = ({ providers }) => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: Config.getGoogleMapsApiKey(),
      libraries: ['places']
    });

    loader.load().then(() => {
      if (mapContainerRef.current) {
        const map = new google.maps.Map(mapContainerRef.current, {
          center: { lat: 51.03445, lng: 4.34878 }, // Default to Brussels center
          zoom: 8,
          styles: GoogleMapsAddressStyle,
          disableDefaultUI: true // This disables all default UI controls
        });

        const infoWindow = new google.maps.InfoWindow();

        providers.forEach(provider => {
          const lat = Number(provider.providerData.lat);
          const lng = Number(provider.providerData.lng);
          if (!isNaN(lat) && !isNaN(lng)) {
            const marker = new google.maps.Marker({
              position: { lat, lng },
              map,
              title: provider.providerData.name,
            });

            // Listener for click event on the marker
            marker.addListener('click', () => {
              const contentString = `
                  <p style="margin-top: 0"><b>${provider.providerData.name} ${provider.providerData.surName}</b></p>
                  <p>${provider.providerData.practiceName}</p>
                  <p>${provider.providerData.practiceDescription}</p>
                  <a href="${routes.providerDetails}?providerId=${provider.id}">Meer informatie</a>
              `;
              infoWindow.setContent(contentString);
              infoWindow.open(map, marker);
            });
          }
        });
      }
    });
  }, [providers]);

  return <div ref={mapContainerRef} style={{ width: '100%', height: '200px', marginTop: '20px', borderRadius: '10px' }} />;
};

export default ProviderMap;
