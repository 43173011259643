
export enum QuestionType {
  SingleChoice = "SINGLE_CHOICE",
  MultipleChoice = "MULTIPLE_CHOICE",
  Slider = "SLIDER",
  AutoComplete = "AUTO_COMPLETE",
}

export type Method = {
  id: string;
  name: string;
  category: string;
};
export type AnswerMethodWeight = {
  id: string;
  answerId: string;
  methodId: string;
  weight: number;
};

export type AnswerOrganisationWeight = {
  id: string;
  answerId: string;
  organisationId: string;
  weight: number;
};

export type AnswerMediaWeight = {
  id: string;
  answerId: string;
  mediaId: string;
  weight: number;
};

export type Answer = {
  id: string;
  description: string;
  questionId: string;
  extraInfo: string;
  answerMethodWeights: AnswerMethodWeight[];
  answerOrganisationWeights: AnswerOrganisationWeight[];
  answerMediaWeights: AnswerMediaWeight[];
  order: number;
  stopQuestionnaire: boolean;
  stopQuestionnaireReason: string;
};


export type ChosenAnswer = {
  id: string;
  description: string;
  questionId: string;
  extraInfo: string | null;
  answerMethodWeights: AnswerMethodWeight[];
  answerOrganisationWeights: AnswerOrganisationWeight[];
  answerMediaWeights: AnswerMediaWeight[];
  weight?: number;
};

export type Question = {
  id: string;
  code: string;
  description: string;
  information: string;
  type: QuestionType;
  hidden: boolean;
  order: number;
  categoryId: string;
  answerId: string;
  answers: Answer[];
};

export type Category = {
  id: string;
  code: string;
  name: string;
  description: string;
  order: number;
  questions: Question[];
};

export type Questionnaire = {
  methods: Method[];
  mandatoryQuestions: Question[];
  categories: Category[];
};
// Named subset types
export type MethodScoresType = {
  [methodId: string]: number;
};

export type OrganisationScoresType = {
  [organisationId: string]: number;
};

export type MediaScoresType = {
  [mediaId: string]: number;
};
// Superset type
export type ScoresType = {
  methodScores: MethodScoresType;
  organisationScores: OrganisationScoresType;
  mediaScores: MediaScoresType;
};

export type ScoresWithAnswerType = {
  [methodId: string]: { score: number; answerId: string };
};

export type DetailedScoresType = {
  methodScores: ScoresWithAnswerType;
  organisationScores: ScoresWithAnswerType;
  mediaScores: ScoresWithAnswerType;
};

export type ResultsContextType = {
  setAnswersInSessionStorage: () => void;
  getAnswersFromSessionStorage: () => void;
  finishedCategories: Set<string>;
  getAnswers: () => ChosenAnswer[];
  setDevAnswers: (answers: ChosenAnswer[]) => void;
  setAnswersForQuestion: (answers: ChosenAnswer[]) => void;
  finishCategory: (category: Category) => void;
  isCategoryFinished: (category: Category) => boolean;
  isAnswerActive: (answer: ChosenAnswer) => boolean;
  addAnswer: (answer: ChosenAnswer) => void;
  removeAnswer: (answer: ChosenAnswer) => void;
  anyAnswerChosen: (question: Question) => boolean;
  getFirstAnswerScore: (question: Question) => number | null;
  allFinished: boolean;
  showExtraInfo: boolean;
  setShowExtraInfo: (show: boolean) => void;
  extraInfo: string | undefined;
  finishAll: () => void;
  getScores: () => ScoresType;
  getScoresWithAnswerId: () => ScoresWithAnswerType;
  physicalDiagnose: string | undefined;
  mentalDiagnose: string | undefined;
  setFreeTextAnswer: (freeTextAnswer: string | undefined, question: Question) => void;
};
