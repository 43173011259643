import "./AutoComplete.css";
import { Answer, Question } from "../../../types/Types";  
import { useResultsContext } from "../../../hooks/useResultsContext";
import { useState, useEffect } from "react";

export function AutoComplete({ question }: { question: Question }) {
  const { isAnswerActive, addAnswer, removeAnswer, setFreeTextAnswer } = useResultsContext();

  const [filteredAnswers, setFilteredAnwsers] = useState<Answer[]>([]);

  useEffect(() => {  setFilteredAnwsers(question.answers);
  }, []);


  return (
    <div className={"auto-complete"}>
      <input type="text" onChange={(e) => {
        setFreeTextAnswer(e.target.value, question);
        let newAnswers:Answer[] = [];
        if(e.target.value.length > 0) {
          newAnswers = question.answers.filter(answer => answer.description.toLowerCase().startsWith(e.target.value.toLowerCase()));
        }
        else {
          newAnswers = question.answers;
        }
        question.answers.map(answer => {
          if(isAnswerActive(answer)) {
            if(!newAnswers.some(thisAnswer => thisAnswer.id === answer.id)) {
              newAnswers.push(answer);
            }
          }
        });
        setFilteredAnwsers(newAnswers);
        }}></input>
      <div className={"auto-complete-answers"}>
        {filteredAnswers.map((answer) => {
          const selected = isAnswerActive(answer);

          return (
            <a
              key={answer.id}
              className={`auto-complete-answer${
                selected ? " active" : ""
              }`}
              onClick={() => {
                if (selected) {
                  removeAnswer(answer);
                } else {
                  addAnswer(answer);
                }
              }}
            >
              {answer.description}
            </a>
          );
        })}
      </div>
    </div>
  );
}
