export const beliefs = [
  "Katholiek",
  "Orthodox",
  "Angelicaans",
  "Joods",
  "Islamitisch",
  "Protestants",
  "Hindoeïstisch",
  "Boeddhistisch",
  "Atheïstisch",
  "Andere",
];
