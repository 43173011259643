import ApiInstance from "./ApiInstance";
import { HealthCareProviderType } from "./ProviderApi";
import { FormData } from "../components/contact-form/ContactForm";

export type MediaUrl = {
  url: string;
};

class EmailApi {
  public async postMedia(
    email: string,
    hcProviders: HealthCareProviderType[]
  ): Promise<MediaUrl> {
    const response = await ApiInstance.getApi().post("/email/", {
      email,
      hcProviders: hcProviders.map((hcProvider) => hcProvider.id),
    });
    return response.data;
  }

  public async postResultsEmail(formData: FormData): Promise<void> {
    await ApiInstance.getApi().post("/email/contact", formData);
  }
}

export default new EmailApi();
