import React, { useState } from 'react';
import './Privacy.css';
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
const Informatie: React.FC = () => {



  return (
    <div className="container">
      <Header/>
      <main className="main-info">
        <div className="content-wrapper-article">
          <section className="intro-section-in-article">
            <h1>Privacybeleid - 2 juli 2024</h1>
            <p>Welkom bij Mia BV. Wij hechten groot belang aan de bescherming van uw privacy en de veiligheid van uw persoonlijke gegevens. Dit privacybeleid legt uit hoe wij uw gegevens verzamelen, gebruiken, delen en beschermen wanneer u onze website bezoekt en gebruikmaakt van onze diensten.</p>
          </section>
          <section className="article-section">
            <h2>1. Verzamelde Gegevens</h2>
            <p>Wanneer u onze website bezoekt en gebruikmaakt van onze diensten, verzamelen wij de volgende soorten gegevens:</p>
            <ul>
              <li><strong>Anonieme Vragenlijstgegevens:</strong> Om u te helpen bij het vinden van passende hulpverlening, vragen wij u om een aantal eenvoudige, anonieme vragen te beantwoorden. Deze vragen hebben betrekking op uw problematiek, hulpvraag en voorkeuren voor hulpverlening. Deze gegevens zijn volledig anoniem en kunnen niet worden herleid tot een specifieke persoon.</li>
              <li><strong>Selectie- en Filtergegevens:</strong> Wij verzamelen gegevens over de soort hulpverlening die u selecteert en de filters die u toepast op de resultatenpagina, zoals locatie, taal, budget en beschikbaarheid. Ook deze gegevens zijn anoniem en dienen enkel om u beter van dienst te kunnen zijn.</li>
              <li><strong>Persoonsgegevens via Contactformulier:</strong> Wanneer u besluit contact op te nemen met een hulpverlener via ons contactformulier, verzamelen wij persoonlijke gegevens zoals uw naam, e-mailadres en eventueel andere contactinformatie die u verstrekt. Dit is de enige plaats waar wij persoonlijke gegevens verwerken.</li>
            </ul>
          </section>
          <section className="article-section">
            <h2>2. Doel van de Gegevensverwerking</h2>
            <p>Wij gebruiken de verzamelde gegevens voor de volgende doeleinden:</p>
            <ul>
              <li><strong>Anonieme Gegevens:</strong> De anonieme gegevens van de vragenlijsten en selectie- en filteropties worden gebruikt om een beter beeld te krijgen van uw hulpvraag en om passende hulpverleningsopties aan te bieden.</li>
              <li><strong>Persoonsgegevens:</strong> De persoonsgegevens die via het contactformulier worden verzameld, worden uitsluitend gebruikt om uw contactverzoek door te sturen naar de betreffende hulpverlener, zodat deze contact met u kan opnemen.</li>
            </ul>
          </section>
          <section className="article-section">
            <h2>3. Rechtsgrondslag voor de Gegevensverwerking</h2>
            <p>De verwerking van uw persoonsgegevens is gebaseerd op uw toestemming, die u geeft door het invullen en verzenden van het contactformulier.</p>
          </section>
          <section className="article-section">
            <h2>4. Bewaartermijn van de Gegevens</h2>
            <ul>
              <li><strong>Anonieme Gegevens:</strong> Deze gegevens worden bewaard zolang als nodig is voor analytische doeleinden en om onze diensten te verbeteren.</li>
              <li><strong>Persoonsgegevens:</strong> Persoonsgegevens verzameld via het contactformulier worden bewaard zolang als nodig is om uw contactverzoek af te handelen en worden daarna verwijderd, tenzij een langere bewaartermijn wettelijk vereist is.</li>
            </ul>
          </section>
          <section className="article-section">
            <h2>5. Delen van Gegevens</h2>
            <ul>
              <li><strong>Anonieme Gegevens:</strong> Deze gegevens kunnen gedeeld worden met partners en dienstverleners voor analytische doeleinden en om onze diensten te verbeteren.</li>
              <li><strong>Persoonsgegevens:</strong> Uw persoonsgegevens worden alleen gedeeld met de hulpverlener waarmee u contact wilt opnemen. Wij delen uw persoonsgegevens niet met derden voor marketingdoeleinden.</li>
            </ul>
          </section>
          <section className="article-section">
            <h2>6. Beveiliging van Gegevens</h2>
            <p>Wij nemen passende technische en organisatorische maatregelen om uw persoonsgegevens te beschermen tegen ongeoorloofde toegang, verlies of diefstal.</p>
          </section>
          <section className="article-section">
            <h2>7. Uw Rechten</h2>
            <p>U heeft het recht om toegang te vragen tot uw persoonsgegevens, deze te corrigeren, te verwijderen of de verwerking ervan te beperken. U kunt uw toestemming voor de verwerking van uw persoonsgegevens te allen tijde intrekken door contact met ons op te nemen.</p>
          </section>
          <section className="article-section">
            <h2>8. Contact</h2>
            <p>Als u vragen heeft over dit privacybeleid of over de manier waarop wij uw gegevens verwerken, kunt u contact met ons opnemen via:</p>
            <address>
              <p><a href="mailto:privacy@miazoekthulp.be">privacy@miazoekthulp.be</a></p>
            </address>
          </section>
          <section className="article-section">
            <h2>9. Wijzigingen in dit Privacybeleid</h2>
            <p>Wij behouden ons het recht voor om dit privacybeleid te allen tijde te wijzigen. Eventuele wijzigingen zullen op deze pagina worden gepubliceerd met vermelding van de herzieningsdatum bovenaan het beleid.</p>
            <p>Dit privacybeleid is voor het laatst bijgewerkt op 2 juli 2024.</p>
          </section>
        </div>
      </main>

      <Footer/>
    </div>
  );
};

export default Informatie;
