import ApiInstance from "./ApiInstance";
import { Questionnaire } from "../types/Types";


class QuestionnaireApi {
  public async getQuestionnaire(): Promise<Questionnaire> {
    const response = await ApiInstance.getApi().get("/questionnaire");
    return response.data;
  }
}

export default new QuestionnaireApi();
