import "./Results.css";
import { useResultsContext } from "../../hooks/useResultsContext";
import { devAnswers } from "../../constants/devAnswers";
import ProviderApi, { HealthCareProviderType } from "../../api/ProviderApi";
import OrganisationApi, { OrganisationType } from "../../api/OrganisationApi";
import MediumApi, { MediumType } from "../../api/MediumApi";
import React, { useEffect, useState, useRef } from "react";
import { ProviderCard } from "../../components/provider-card/ProviderCard";
import { OrganisationCard } from "../../components/organisation-card/OrganisationCard";
import { MediumCard } from "../../components/medium-card/MediumCard";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { SettingsSelect } from "../../components/settings-select/SettingsSelect";
import { Sex } from "../../enums/Sex";
import { beliefs } from "../../constants/beliefs";
import { languages } from "../../constants/languages";
import { accessibilityByPublicTransit } from "../../constants/accessibilityByPublicTransit";
import { SettingsCheckbox } from "../../components/settings-checkbox/SettingsCheckbox";
import Dialog from "../../components/dialog/Dialog";
import EmailApi from "../../api/EmailApi";
import Pagination from '../../components/pagination/pagination';
import { Loader } from '@googlemaps/js-api-loader';
import { Config } from "../../Config";
import ProviderMap from '../../components/provider-map/ProviderMap'; 
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer'; // Import the Footer component
import { useSearchParams } from "react-router-dom";

const PAGE_SIZE = 6;

declare global {
  interface Window {
    google: typeof google;
  }
}
interface ResultsData {
  providers: HealthCareProviderType[];
  page: number;
}

interface ProviderWithFlag extends HealthCareProviderType {
  isOrganisation?: boolean;
}

interface OrganisationWithFlag extends OrganisationType {
  isOrganisation: boolean;
}

type Range = {
  min: number;
  max: number;
};

const defaultFilters: {
  lat: null | number;
  lng: null | number;
  ageRanges: Range[];
  sex: null | Sex;
  beliefs: string[];
  languages: string[];
  accessibilityByPublicTransit: string[];
  waitingRanges: Range[];
  comfortableWithLGBTQIAP: boolean;
  conventioned: boolean;
  zip: string;
} = {
  lat: null,
  lng: null,
  ageRanges: [],
  sex: null,
  beliefs: [],
  languages: [],
  accessibilityByPublicTransit: [],
  waitingRanges: [],
  comfortableWithLGBTQIAP: false,
  conventioned: false,
  zip: "",
};


export function Results() {

  console.log("results page instantiated");
  const { getAnswersFromSessionStorage, getScores, getAnswers, setDevAnswers } = useResultsContext();
  const providerPlaceInput = useRef(null);
  const [originalData, setOriginalData] = useState<HealthCareProviderType[]>(
    []
  );
  const [sendingMail, setSendingMail] = useState(false);
  const [email, setEmail] = useState("");
  const [data, setData] = useState<{
    providers: HealthCareProviderType[];
    page: number;
  }>({ providers: [], page: 0 });

  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);
  const [media, setMedia] = useState<MediumType[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [editingFilters, setEditingFilters] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentOrganisationsPage, setCurrentOrganisationsPage] = useState(0);
  const [currentMediaPage, setCurrentMediaPage] = useState(0);

  const hasRun = useRef(false);

  const reAnswerQuestions = () => {
    window.scrollTo(0, 0);
    navigate(routes.questionnaire);
  };

  const handlePageChange = (pageNumber : number) => {
    setCurrentPage(pageNumber - 1); // Adjust for zero-based indexing
    window.scrollTo(0, 0); 
    // Save to sessionStorage
    sessionStorage.setItem('providersCurrentPage', JSON.stringify(pageNumber - 1));
  };


  const handleOrganisationPageChange = (pageNumber : number) => {
    setCurrentOrganisationsPage(pageNumber - 1); // Adjust for zero-based indexing
    window.scrollTo(0, 0);
    // Save to sessionStorage
    sessionStorage.setItem('organisationsCurrentPage', JSON.stringify(pageNumber - 1));

  };


  const handleMediumPageChange = (pageNumber : number) => {
    setCurrentMediaPage(pageNumber - 1); // Adjust for zero-based indexing
    window.scrollTo(0, 0);
    // Save to sessionStorage
    sessionStorage.setItem('mediaCurrentPage', JSON.stringify(pageNumber - 1));
  };

  const filterOnCity = (lat, lng) => {
    applyFilters(lat, lng);
  };

  function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180; // Difference in latitude
    const dLon = (lon2 - lon1) * Math.PI / 180; // Difference in longitude
    const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
        Math.sin(dLon / 2) * Math.sin(dLon / 2); 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
    const distance = R * c; // Distance in kilometers
    return distance;
}

const loadGooglePlacesAutoComplete = function(inputReference) {
  requestAnimationFrame(() => {
  const loader = new Loader({
    apiKey: Config.getGoogleMapsApiKey(),
    libraries: ["places"]
  });

  loader
    .load()
    .then(() => {
      if (!inputReference.current) return;
      const autocomplete = new window.google.maps.places.Autocomplete(inputReference.current, {
        types: ['(cities)'],
        componentRestrictions: { country: "BE" },
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          // If a place is selected, update filters and trigger filtering
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setFilters((prev) => ({
            ...prev,
            lat: lat,
            lng: lng
          }));
          filterOnCity(lat, lng);  // Call filter function immediately after setting new coordinates
        } else {
          // If no place is selected (input is cleared), reset the relevant filters and filter again
          setFilters((prev) => ({
            ...prev,
            lat: null,
            lng: null
          }));
          filterOnCity(null, null);  // Call filter function to handle clearing of selection
        }
      });

    })
    .catch((e) => {
      // do something
  });
  });
};

function normalizeScores(providers: HealthCareProviderType[]): HealthCareProviderType[] {
  // Step 1: Find the current min and max normalized scores
  const minScore = Math.min(...providers.map(provider => provider.normalizedScore));
  const maxScore = Math.max(...providers.map(provider => provider.normalizedScore));

  // Step 2: Scale scores so that they fit within the range [10, 90]
  return providers.map(provider => {
    const normalizedScore = ((provider.normalizedScore - minScore) / (maxScore - minScore)) * 80 + 10;
    return {
      ...provider,
      normalizedScore
    };
  });
}



  useEffect(() => {

    window.scrollTo(0, 0);
    if (providerPlaceInput.current === null) {
      // Handle the case where the ref is null, or simply return to avoid proceeding
      return;
    }
    const loader = new Loader({
      apiKey: Config.getGoogleMapsApiKey(),
      libraries: ["places"]
    });

    loader
      .load()
      .then(() => {
        if (!providerPlaceInput.current) return;
          loadGooglePlacesAutoComplete(providerPlaceInput);

      })
      .catch((e) => {
        // do something
    });

  }, []);

  const [searchParams] = useSearchParams();

  useEffect(() => {  
    getAnswersFromSessionStorage();
    if (hasRun.current) return;
    hasRun.current = true;
    if(getAnswers().length === 0 && process.env.NODE_ENV === 'development') {
      setDevAnswers(devAnswers);
    }
    if (getAnswers().length === 0 && process.env.NODE_ENV !== 'development') {
      navigate(routes.questionnaire);
      return;
    }

    // Handle search params from URL if the user comes from the questionnaire
    const initialTab = searchParams.get("tab");
    if (initialTab) {
      setSelectedTab(initialTab);
    }


    const fetchData = async () => {
      const providers = await ProviderApi.getSuggestedProviders(getScores().methodScores);
      const normalizedProviders = normalizeScores(providers);
      setData({ providers: normalizedProviders, page: 0 });
      setOriginalData(normalizedProviders);
  
      const organisations = await OrganisationApi.getSuggestedOrganisations(getScores().organisationScores);
      setOrganisations(organisations);
  
      const media = await MediumApi.getSuggestedMedia(getScores().mediaScores);
      setMedia(media);
    };
  
    fetchData();

    const savedProvidersPage = sessionStorage.getItem('providersCurrentPage');
    const savedOrganisationsPage = sessionStorage.getItem('organisationsCurrentPage');
    const savedMediaPage = sessionStorage.getItem('mediaCurrentPage');

    setCurrentPage(savedProvidersPage ? parseInt(savedProvidersPage) : 0);
    setCurrentOrganisationsPage(savedOrganisationsPage ? parseInt(savedOrganisationsPage) : 0);
    setCurrentMediaPage(savedMediaPage ? parseInt(savedMediaPage) : 0);
  }, []);



  function applyFilters(lat = filters.lat, lng = filters.lng) {
    //we can leave this reloading out if too much traffic. But nice for testing results.
    ProviderApi.getSuggestedProviders(getScores().methodScores).then((providers) => {
      const normalizedProviders = normalizeScores(providers);
      setOriginalData(normalizedProviders);

      setCurrentPage(0);
      setData({
        providers: normalizedProviders.filter((p) => {
          if (
            p.providerData?.lat && p.providerData?.lng &&
            lat && lng &&
            calculateDistance(lat, lng, p.providerData.lat, p.providerData.lng) > 10
          ) {
            return false;
          }
          if (filters.ageRanges.length > 0) {
            if (
              !filters.ageRanges.some(
                (range) =>
                  p.providerData.age &&
                  range.min <= p.providerData.age &&
                  range.max >= p.providerData.age
              )
            ) {
              return false;
            }
          }

          if (
            p.providerData?.sex &&
            filters.sex &&
            p.providerData.sex !== filters.sex
          ) {
            return false;
          }

          if (
            filters.beliefs.length > 0 &&
            p.providerData.beliefs.filter((ag) => filters.beliefs.includes(ag))
              .length === 0
          ) {
            return false;
          }

          if (
            filters.languages.length > 0 &&
            p.providerData.languages.filter((ag) =>
              filters.languages.includes(ag)
            ).length === 0
          ) {
            return false;
          }

          if (
            filters.accessibilityByPublicTransit.length > 0 &&
            p.providerData.accessibilityByPublicTransit?.filter((ag) =>
              filters.accessibilityByPublicTransit.includes(ag)
            ).length === 0
          ) {
            return false;
          }

          if (filters.waitingRanges.length > 0) {
            if (
              !filters.waitingRanges.some(
                (range) =>
                  p.providerData.avgWaitingDurationWeeks != null &&
                  range.min <= p.providerData.avgWaitingDurationWeeks &&
                  range.max >= p.providerData.avgWaitingDurationWeeks
              )
            ) {
              return false;
            }
          }

          if (filters.comfortableWithLGBTQIAP) {
            if (!p.providerData.comfortableWithLGBTQIAP) {
              return false;
            }
          }

          if (filters.conventioned) {
            if (!p.providerData.conventioned) {
              return false;
            }
          }

          if (filters.zip.length) {
            if (p.providerData.practiceRegionZipCode !== filters.zip) {
              return false;
            }
          }

          return true;
        }),
        page: 0,
      });
    });
  }


  const getProviderCardsWithOrganisations = (): (ProviderWithFlag | OrganisationWithFlag)[] => {
    let providersWithOrganisations: (ProviderWithFlag | OrganisationWithFlag)[] = [...data.providers];
    const remainingOrganisations: OrganisationWithFlag[] = organisations.map(org => ({ ...org, isOrganisation: true }));
    const pages = Math.ceil(providersWithOrganisations.length / PAGE_SIZE);
  
    for (let page = 0; page < pages; page++) {
      const startIndex = page * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      const pageProviders = providersWithOrganisations.slice(startIndex, endIndex);
  
      // Only insert organisation if there are remaining organisations and it's not the first two positions
      if (remainingOrganisations.length > 0) {
        const insertIndex = Math.floor(Math.random() * (pageProviders.length - 2)) + 2; // Ensure it's not in the first two positions
        const organisation = remainingOrganisations.shift();
  
        if (organisation) {
          pageProviders.splice(insertIndex, 0, organisation);
        }
  
        providersWithOrganisations = [
          ...providersWithOrganisations.slice(0, startIndex),
          ...pageProviders,
          ...providersWithOrganisations.slice(endIndex)
        ];
      }
    }
  
    return providersWithOrganisations;
  };
  

  const isEven = (num: number): boolean => {
    return num % 2 === 0;
  };


  const [selectedTab, setSelectedTab] = useState('PSYCHOLOGIST');
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);


  const handleTabChange = (tab) => {
    window.location.href = routes.results + `?tab=${tab}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className={"wrapper"}>
    <div className={"menu-container"}>
      <Header/>
      <div className="results-title-mobile">Resultaten</div>
      <h1 className={"desktop"}>Resultaten</h1>
     
      {/* Tab Buttons */}
      <div className="select-button-group-results">
        <button
          className={`tab-selection-button red-button ${selectedTab === 'PSYCHOLOGIST' ? 'active' : ''}`}
          onClick={() => handleTabChange('PSYCHOLOGIST')}
        >
          {selectedTab === 'PSYCHOLOGIST' && <span className="checkmark">✔&nbsp;&nbsp;</span>}
          Psychologen en therapeuten
        </button>
        <button
          className={`tab-selection-button dark-yellow-button ${selectedTab === 'ORGANISATION' ? 'active' : ''}`}
          onClick={() => handleTabChange('ORGANISATION')}
        >
        {selectedTab === 'ORGANISATION' && <span className="checkmark">✔&nbsp;&nbsp;</span>}    
          Organisaties
        </button>
        <button
          className={`tab-selection-button yellow-button ${selectedTab === 'MEDIA' ? 'active' : ''}`}
          onClick={() => handleTabChange('MEDIA')}
        >
          {selectedTab === 'MEDIA' && <span className="checkmark">✔&nbsp;&nbsp;</span>}
          Lees-, kijk- en luistertips
        </button>
      </div>
      {selectedTab === "PSYCHOLOGIST" && (
      <div className="providers-section">
      <div className={"mobile"}>
        Aan de hand van jouw antwoorden hebben we deze hulpverleners voor je gevonden. Je kan verder filteren op gemeente of andere filters configureren.
      </div>
      <div className={"desktop results-intro-text"}>
        Aan de hand van jouw antwoorden hebben we deze hulpverleners voor je gevonden. Je kan verder filteren op gemeente of andere filters configureren.
      </div>
     
      <div>
        <p><b>Filter op gemeente</b></p>
        <div className={"filtering-top"}>
          <div className={"filtering-left"}><input type="text" className="location-input" ref={providerPlaceInput}></input></div>
          <div className={"filtering-right"}>
          <div className={"desktop"}>
            <a  onClick={() => filterOnCity(filters.lat, filters.lng)} className={"small-button dark-button filter-on-city"}>
              Filter op gemeente
            </a>
            <a 
                onClick={() => setEditingFilters(true)} className={"small-button white-button other-filters"}>
              Andere filters
            </a>  
          </div>      
          <div className={"mobile"}>
            <a  onClick={() => filterOnCity(filters.lat, filters.lng)} className={"small-button dark-button filter-on-city"}>
              Filter
            </a>
            <a 
                onClick={() => setEditingFilters(true)} className={"small-button white-button other-filters"}>
              Andere filters
            </a>  
          </div>       
          </div>
        </div>
        <ProviderMap providers={data.providers} />
        <div className={"extra-categories mt-12"}>
          <a className={"small-link"}
            onClick={() => reAnswerQuestions()}>
            Vul vragen opnieuw in
          </a>
          <a
            className={"small-link"}
            onClick={() => setSendingMail(true)}
          >
            E-mail resultaten</a>
        </div>
      </div>
      <Dialog
          isOpen={editingFilters}
          onRequestClose={() => setEditingFilters(false)}
          size={"large"}
          title={"Extra filters instellen"}
        >
          
          <div className={"filters"}>
            <div className="filter-label">Geslacht</div>
            <SettingsSelect
              options={Object.keys(Sex) as Sex[]}
              onSelect={(answer) =>
                setFilters((prev) => ({
                  ...prev,
                  sex: answer,
                }))
              }
              onDeselect={() =>
                setFilters((prev) => ({
                  ...prev,
                  sex: null,
                }))
              }
              activeAnswers={filters.sex ? [filters.sex] : []}
              compact
            />
            <div className="filter-label">Leeftijd (min-max)</div>
            <SettingsSelect
              options={[
                { min: 20, max: 30 },
                { min: 30, max: 40 },
                { min: 40, max: 50 },
                { min: 50, max: 60 },
                { min: 60, max: Infinity },
              ]}
              onSelect={(answer) =>
                setFilters((prev) => ({
                  ...prev,
                  ageRanges: [...prev.ageRanges, answer],
                }))
              }
              onDeselect={(answer) => {
                setFilters((prev) => ({
                  ...prev,
                  ageRanges: prev.ageRanges.filter(
                    (range) => range.min != answer.min || range.max != answer.max
                  ),
                }));
              }}
              label={(range) =>
                range.max === Infinity
                  ? `${range.min} +`
                  : `${range.min} - ${range.max}`
              }
              answerActive={(answer) =>
                filters.ageRanges.filter(
                  (range) => range.min == answer.min && range.max == answer.max
                ).length > 0
              }
              activeAnswers={filters.ageRanges}
            />
            <div className="filter-label">Geloofsovertuigingen</div>
            <SettingsSelect
              options={beliefs}
              onSelect={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  beliefs: [...prev.beliefs.filter((ag) => ag !== value), value],
                }))
              }
              onDeselect={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  beliefs: prev.beliefs.filter((ag) => ag !== value),
                }))
              }
              activeAnswers={filters.beliefs}
            />
            <div className="filter-label">Taal</div>
            <SettingsSelect
              options={languages}
              onSelect={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  languages: [
                    ...prev.languages.filter((ag) => ag !== value),
                    value,
                  ],
                }))
              }
              onDeselect={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  languages: prev.languages.filter((ag) => ag !== value),
                }))
              }
              activeAnswers={filters.languages}
            />
            <div className="filter-label">Bereikbaarheid</div>
            <SettingsSelect
              options={accessibilityByPublicTransit}
              onSelect={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  accessibilityByPublicTransit: [
                    ...prev.accessibilityByPublicTransit.filter(
                      (ag) => ag !== value
                    ),
                    value,
                  ],
                }))
              }
              onDeselect={(value) =>
                setFilters((prev) => ({
                  ...prev,
                  accessibilityByPublicTransit:
                    prev.accessibilityByPublicTransit.filter(
                      (ag) => ag !== value
                    ),
                }))
              }
              activeAnswers={filters.accessibilityByPublicTransit}
            />
            <div className="filter-label">Gemiddelde weken wachttijd</div>
            <SettingsSelect
              options={[
                { min: 0, max: 2 },
                { min: 2, max: 4 },
                { min: 4, max: 6 },
                { min: 6, max: Infinity },
              ]}
              onSelect={(answer) =>
                setFilters((prev) => ({
                  ...prev,
                  waitingRanges: [...prev.waitingRanges, answer],
                }))
              }
              onDeselect={(answer) => {
                setFilters((prev) => ({
                  ...prev,
                  waitingRanges: prev.waitingRanges.filter(
                    (range) => range.min != answer.min || range.max != answer.max
                  ),
                }));
              }}
              label={(range) =>
                range.max === Infinity
                  ? `> ${range.min}`
                  : `${range.min} - ${range.max}`
              }
              answerActive={(answer) =>
                filters.waitingRanges.filter(
                  (range) => range.min == answer.min && range.max == answer.max
                ).length > 0
              }
              activeAnswers={filters.waitingRanges}
            />
            <SettingsCheckbox
              checked={filters.comfortableWithLGBTQIAP}
              onChange={(val) =>
                setFilters((prev) => ({ ...prev, comfortableWithLGBTQIAP: val }))
              }
              label={"LGBTQIA+ vriendelijk"}
              className={"mt-4"}
            />
            <SettingsCheckbox
              checked={filters.conventioned}
              onChange={(val) =>
                setFilters((prev) => ({ ...prev, conventioned: val }))
              }
              label={"Geconventioneerd"}
              className={"mt-4"}
            />
            <div className="filter-buttons mt-4">
              <button
                className="small-button white-button"
                onClick={() => setEditingFilters(false)}
              >
                Sluit
              </button>
              <button
                className="small-button white-button desktop"
                onClick={() => {
                  setData((prev) => ({ ...prev, providers: originalData }));
                  setFilters(defaultFilters);
                }}
              >
                Filters wissen
              </button>
              <button
                className="small-button dark-button"
                onClick={() => {applyFilters(); setEditingFilters(false);}}
              >
                Toepassen
              </button>
              
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </div>

        </Dialog>
        <div>
          {getProviderCardsWithOrganisations().length ? (
            <>
             
              <div className={"provider-cards"}>
                {getProviderCardsWithOrganisations()
                  .slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE)
                  .map((item, index) => {
                    const backgroundClass = isEven(index) ? "background-darker" : "";

                    return item.isOrganisation ? (
                      <div key={index}>
                        <div className="suggestion wider">
                          Mia raad ook deze organisatie aan voor jouw situatie:
                        </div>
                        <OrganisationCard
                          organisation={item as OrganisationWithFlag}
                          position={index}
                          backgroundClass={backgroundClass}
                        />
                        <div className="suggestion-bottom wider"></div>
                      </div>
                    ) : (
                      <ProviderCard
                        provider={item as ProviderWithFlag}
                        key={index}
                        position={index}
                        backgroundClass={backgroundClass}
                      />
                    );
                  })}
              </div>
              <div className={"results-bottom"}>
                {currentPage > 0 ? (
                  <button
                    className={"small-button dark-button desktop"}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    Vorige
                  </button>
                ) : (
                  <div />
                )}
                <Pagination
                  totalPages={Math.ceil(getProviderCardsWithOrganisations().length / PAGE_SIZE)}
                  currentPage={currentPage + 1} // Adjust for one-based indexing
                  onPageChange={handlePageChange}
                />
                {(currentPage + 1) * PAGE_SIZE < getProviderCardsWithOrganisations().length ? (
                  <button
                    className={"small-button dark-button desktop next-button"}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Volgende
                  </button>
                ) : (<div />)}
              </div>
            </>
          ) : (
            <div className="mb-4 mt-4">
              Er zijn geen hulpverleners die voldoen aan de geselecteerde filters.
            </div>
          )}
        </div>

      </div>
      )}
      {selectedTab === "ORGANISATION" && (
      <div className="organisations-section">
      <div>
        {organisations.length > 0 ? (
          <>
          <div className={"mobile"}>
            Aan de hand van jouw antwoorden hebben we deze organisaties voor je gevonden. 
          </div>
          <div className={"desktop results-intro-text"}>
            Aan de hand van jouw antwoorden hebben we deze organisaties voor je gevonden. 
          </div>
          </>
        ):(
          <div className="mb-4 mt-4">
              Er zijn geen organisaties gevonden die aansluiten bij jouw selecties.
          </div>
        )}
        <div className={"extra-categories mt-12"}>
          <Link to={routes.questionnaire} className={"small-link"}>
            Vul vragen opnieuw in
          </Link>
          <a
            className={"small-link"}
            onClick={() => setSendingMail(true)}
          >
            E-mail resultaten</a>
        </div>
          {organisations.length > 0 ? (
            <>
              <div className={"organisation-cards"}>
                {organisations
                  .slice(currentOrganisationsPage * PAGE_SIZE, (currentOrganisationsPage + 1) * PAGE_SIZE)
                  .map((organisation, index) => {
                    const overallIndex = currentOrganisationsPage * PAGE_SIZE + index;
                    const backgroundClass = isEven(overallIndex) ? "background-darker" : "";
          
                    return (
                      <OrganisationCard
                        organisation={organisation}
                        key={index}
                        position={overallIndex}
                        backgroundClass={backgroundClass}
                      />
                    );
                  })}
              </div>
              <div className={"results-bottom"}>
              {currentOrganisationsPage > 0 ? (
                  <button
                    className={"small-button dark-button desktop"}
                    onClick={() => setCurrentOrganisationsPage(currentOrganisationsPage - 1)}
                  >
                    Vorige
                  </button>
                ) : (
                  <div />
                )}
                 <Pagination
                  totalPages={Math.ceil(organisations.length / PAGE_SIZE)}
                  currentPage={currentOrganisationsPage + 1} // Adjust for one-based indexing
                  onPageChange={handleOrganisationPageChange}
                />
                 {(currentOrganisationsPage + 1) * PAGE_SIZE < organisations.length ? (
                  <button
                    className={"small-button dark-button desktop next-button"}
                    onClick={() => setCurrentOrganisationsPage(currentOrganisationsPage + 1)}
                  >
                    Volgende
                  </button>
                ) : (<div/>)}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>)}

      {selectedTab === "MEDIA" && (
      <div className="media-section">
      <div>
        {media.length > 0 ? (
          <>
          <div className={"mobile"}>
            Aan de hand van jouw antwoorden hebben we deze media voor je gevonden. 
          </div>
          <div className={"desktop results-intro-text"}>
            Aan de hand van jouw antwoorden hebben we deze media voor je gevonden. 
          </div>
          </>
        ):(
          <div className="mb-4 mt-4">
              Er zijn geen media (boeken, podcasts, ...) gevonden die aansluiten bij jouw selecties.
          </div>
        )}
        <div className={"extra-categories mt-12"}>
          <Link to={routes.questionnaire} className={"small-link"}>
            Vul vragen opnieuw in
          </Link>
          <a
            className={"small-link"}
            onClick={() => setSendingMail(true)}
          >
            E-mail resultaten</a>
        </div>
          {media.length > 0 ? (
            <>
              <div className={"organisation-cards"}>
                {media
                  .slice(currentMediaPage * PAGE_SIZE, (currentMediaPage + 1) * PAGE_SIZE)
                  .map((medium, index) => {
                    const overallIndex = currentMediaPage * PAGE_SIZE + index;
                    const backgroundClass = isEven(overallIndex) ? "background-darker" : "";
          
                    return (
                      <MediumCard
                        medium={medium}
                        key={index}
                        position={overallIndex}
                        backgroundClass={backgroundClass}
                      />
                    );
                  })}
              </div>
              <div className={"results-bottom"}>
              {currentMediaPage > 0 ? (
                  <button
                    className={"small-button dark-button desktop"}
                    onClick={() => setCurrentMediaPage(currentMediaPage - 1)}
                  >
                    Vorige
                  </button>
                ) : (
                  <div />
                )}
                 <Pagination
                  totalPages={Math.ceil(media.length / PAGE_SIZE)}
                  currentPage={currentMediaPage + 1} // Adjust for one-based indexing
                  onPageChange={handleMediumPageChange}
                />
                 {(currentMediaPage + 1) * PAGE_SIZE < media.length ? (
                  <button
                    className={"small-button dark-button desktop next-button"}
                    onClick={() => setCurrentMediaPage(currentMediaPage + 1)}
                  >
                    Volgende
                  </button>
                ) : (<div/>)}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>)}
    <Dialog
          isOpen={sendingMail}
          onRequestClose={() => setSendingMail(false)}
          size={"xsmall"}
          title={"E-mail mijn resultaten"}
        >
          <div className="email-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              EmailApi.postMedia(email, data.providers.slice(0, 5)).finally(
                () => {
                  setSendingMail(false);
                }
              );
            }}
          >
            <input
              type={"email"}
              name={"email"}
              autoComplete={"on"}
              placeholder={"voorbeeld@email.com"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={"dialog-input"}
            />
            <button className={"small-button dark-button mt-4"}>Stuur</button>
          </form>
          </div>
        </Dialog>
    </div>

        <div className={"grey-area wider"}>
          <div className={"centered-text intro-text"}>
                <p>
                  Deze vragenlijst kwam tot stand met ondersteuning en validatie van hulpverleners en hulpvragers. Mia werd gerealiseerd dankzij onze partners.
                </p>
              
                <div className={"partner-logos"}>
                  <img src="/imec.png" className={"partner-logo"} alt="Imec"/>
                  <img src="/comon.png" className={"partner-logo"} alt="Comon"/>
                  <img src="/ugent.png" className={"partner-logo"} alt="UGent"/>

                </div>
              </div>
        </div>
        <div className="wider">
          <Footer/>
        </div>
    </div>
  );
}
