import React, { useState, useEffect }  from 'react';
import Gauge from 'react-gauge-component';
import './MatchGauge.css';

export function MatchGauge({ score, imageRef }: { score: number; imageRef: React.RefObject<HTMLImageElement> }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupWidth, setPopupWidth] = useState<number | undefined>(undefined);

  // Toggle popup on click
  const handleTogglePopup = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the event from propagating further
    setIsPopupVisible(!isPopupVisible);
  };


  // Show popup on hover
  const handleMouseEnter = () => {
    setIsPopupVisible(true);
  };

  // Hide popup on mouse leave
  const handleMouseLeave = () => {
    setIsPopupVisible(false);
  };

  // Use the image's width to set the popup width when the popup is shown
  useEffect(() => {
    if (isPopupVisible && imageRef.current) {
      setPopupWidth(imageRef.current.offsetWidth - 60);
    }
  }, [isPopupVisible, imageRef]);



    return (
      <div className='match-gauge-div'
      
        onClick={handleTogglePopup}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
      <div className='first-item'>
        match met jouw antwoorden: 
      </div>
      <div className='second-item'>
      <Gauge className='gauge'
      style={{ width: '100px', height: '50px', margin: '-30px 0 0 auto' }} 
      arc={{
        width: 0.6,
        subArcs: [
          {
            limit: 20,
            color: '#ff9800',
            showTick: false
          },
          {
            limit: 40,
            color: '#ffc107',
            showTick: false
          },
          {
            limit: 60,
            color: '#00daf4',
            showTick: false
          },
          {
            limit: 100,
            color: '#00acc1',
            showTick: false
          },
        ],
      }}
      value={score}

      labels={{
        valueLabel: {
          formatTextValue: (value: number) => {
            if(value < 20) {
              return "redelijk";
            }
            if(value < 40) {
              return "  goed  ";
            }
            if(value < 60) {
              return "  sterk  ";
            }
            return "  best ";
          },
          matchColorWithArc: true,
          style: {fontSize: '50px', textShadow: 'none'}
        },
        tickLabels: {
          type: "outer",
          ticks: [
          ],
          defaultTickValueConfig: {
            formatTextValue: (value: number) => '',
          }
        }
      }}
    />
    </div>


      {/* Popup with bigger Gauge */}
      {isPopupVisible && (
        <div className='gauge-popup'
        style={{ width: popupWidth }} // Set the popup width to match the image width
          >
          <div className='popup-content'>
            <Gauge className='gauge'
              style={{ width: '300px', height: '150px', margin: 'auto' }} 
              arc={{
                width: 0.6,
                subArcs: [
                  { limit: 20, color: '#ff9800', showTick: false },
                  { limit: 40, color: '#ffc107', showTick: false },
                  { limit: 60, color: '#00daf4', showTick: false },
                  { limit: 100, color: '#00acc1', showTick: false },
                ],
              }}
              value={score}
              labels={{
                valueLabel: {
                  formatTextValue: (value: number) => {
                    if (value < 20) return "redelijk";
                    if (value < 40) return "  goed  ";
                    if (value < 60) return "  sterk  ";
                    return "  best ";
                  },
                  matchColorWithArc: true,
                  style: { fontSize: '26px', textShadow: 'none' },
                },
                tickLabels: {
                  type: "outer",
                  ticks: [],
                  defaultTickValueConfig: {
                    formatTextValue: () => '',
                  },
                },
              }}
            />
          </div>
        </div>
      )}
    </div>
    );
}
