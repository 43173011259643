import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from "@mui/system";
import "./SettingsSelect.css";

export function SettingsSelect<T>({
  options,
  onSelect,
  onDeselect,
  activeAnswers = [],
  compact,
  label,
  answerActive,
  multiple = true, // Default to multiple selection
}: {
  options: T[];
  onSelect: (answer: T) => void;
  onDeselect?: (answer: T) => void;
  activeAnswers?: T[];
  compact?: boolean;
  label?: (element: T) => string;
  answerActive?: (answer: T) => boolean;
  multiple?: boolean; // Prop to specify if multiple selection is allowed
}) {
  const [selectedAnswers, setSelectedAnswers] = useState<T[]>([]);
  const [valueToShow, setValueToShow] = useState<string>("");

  useEffect(() => {
    // Update selectedAnswers when activeAnswers or answerActive change
    if (activeAnswers.length > 0 || answerActive) {
      const initiallySelected = options.filter(
        (option) =>
          activeAnswers.includes(option) || (answerActive && answerActive(option))
      );
      setSelectedAnswers(initiallySelected);
    }
  }, [activeAnswers, answerActive, options]);

  const handleChange = (event: SelectChangeEvent<T[]>) => {
    const selectedOptions = event.target.value as T | T[]; // Adjust type to handle single or multiple selections

  // Handle options that were newly selected
  if (Array.isArray(selectedOptions)) {
    selectedOptions.forEach((option) => {
      if (!selectedAnswers.includes(option)) {
        onSelect(option);
      }
    });
  } else {
    if (!selectedAnswers.includes(selectedOptions)) {
      onSelect(selectedOptions);
    }
  }

  // Handle options that were deselected
  selectedAnswers.forEach((option) => {
    if (Array.isArray(selectedOptions)) {
      if (!selectedOptions.includes(option)) {
        onDeselect && onDeselect(option);
      }
    } else {
      if (option !== selectedOptions) {
        onDeselect && onDeselect(option);
      }
    }
  });

  setSelectedAnswers(Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions]);
  setValueToShow(Array.isArray(selectedOptions) ? selectedOptions.map((answer) => label ? label(answer) : String(answer)).join(",") : label ? label(selectedOptions) : String(selectedOptions));

  };

  return (
    <div className={"settings-select"}>
      <FormControl 
        fullWidth
        variant="outlined"
        className={`settings-select-dropdown ${
          compact ? " settings-select-no-box" : ""
        }`}
      >
        <InputLabel sx={{ paddingLeft: "5px", paddingRight: "5px", backgroundColor: "white"}}>Maak een keuze</InputLabel>
        <Select
          fullWidth
          multiple={multiple} // Set multiple selection based on prop
          value={selectedAnswers}
          onChange={handleChange}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Maak een keuze</em>;
            }

            return selected.map(answer => label ? label(answer) : String(answer)).join(', ');
          }}
        >
          <MenuItem disabled value="">
            <em>Maak een keuze</em>
          </MenuItem>
          {options.map((answer, idx) => (
            <MenuItem key={idx} value={answer as any}>
            {label ? label(answer) : String(answer)}
          </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
