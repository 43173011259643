import ApiInstance from "./ApiInstance";
import { MediaScoresType } from "../types/Types";

export type Medium = {
  id: string;
  title: string;
  description: string;
  author: string;
  link: string;
  image: string;
  pictureUrl: string;
  providerId: string;
  problemArea: string;
  targetAudience: string;
  distributor: string;
  subject: string;
};

export type MediumType = {
  id: string;
  MediumApi: string;
  mediaData: Medium;
  scoreSum: number;
};

class MediumApi {
  public async getSuggestedMedia(
    scores: MediaScoresType
  ): Promise<MediumType[]> {
    const response = await ApiInstance.getApi().post(
      "/medium/suggest",
      scores
    );
    return response.data.data;
  }


}

export default new MediumApi();
