import "./pagination.css";
import React from 'react';
interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}
function Pagination({ totalPages, currentPage, onPageChange }: PaginationProps) {
  const getPageNumbers = () => {
    const pageNumbers:any[] = [];
    const maxButtons = 4; // Maximum number of page buttons to display

    if (totalPages <= maxButtons) {
      // If total pages are less than or equal to the maximum buttons, display all pages
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // If total pages are greater than maximum buttons, display a subset with ellipsis
      const leftOffset = Math.floor(maxButtons / 2);
      const rightOffset = Math.ceil(maxButtons / 2) - 1;

      let startPage = currentPage - leftOffset;
      let endPage = currentPage + rightOffset;

      if (startPage < 1) {
        startPage = 1;
        endPage = maxButtons;
      } else if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxButtons + 1;
      }

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
          pageNumbers.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push('...');
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageClick = (pageNumber: any) => {
    if (pageNumber === '...') return; // Ignore ellipsis clicks
    onPageChange(pageNumber);
  };

  return (
    <div className="page-numbers">
      {getPageNumbers().map((pageNumber, index) => (
        <button className={"number-button dark-button"} key={index} onClick={() => handlePageClick(pageNumber)}
        style={{
          background: pageNumber === currentPage ? 'white' : '', // Change color for current page
          color: pageNumber === currentPage ? 'grey' : '', // Change color for current page
          border: pageNumber === currentPage ? '1px solid grey' : '',
          pointerEvents: pageNumber === currentPage ? 'none' : 'auto', // Disable clicking for current page
        }}
        >
          {pageNumber}
        </button>
      ))}
    </div>
  );
}

export default Pagination;
