import "./ProviderCard.css";
import { useResultsContext } from "../../hooks/useResultsContext";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes"
import { ActiveMethods } from "../../components/methods/ActiveMethods";
import React, { useEffect, useState, useRef } from "react";
import WhyDialog from "../why-provider-dialog/WhyProviderDialog";
import ProviderApi, { HealthCareProviderType } from "../../api/ProviderApi";
import MethodApi, {
  MethodsPerCategory,
  MethodWeightById,
} from "../../api/MethodApi";
import Slider from "../../components/slider/Slider";
import {MatchGauge} from "../match-gauge/MatchGauge";
import {WaitingGauge} from "../waiting-gauge/WaitingGauge";

function boolToStr(bool: boolean | null) {
  return bool === null ? "Onbekend" : bool ? "Ja" : "Nee";
}

const getDisplaySex = (providerData: any) => {
  // Example of more complex logic; can be expanded as needed
  if (!providerData) return "Onbekend"; // Localized string for "Unknown"
  switch (providerData.sex) {
    case 'M': return 'Man'; // Assuming 'M' stands for Male
    case 'F': return 'Vrouw'; // Assuming 'F' stands for Female
    case 'X': return 'Intersex'; // Assuming 'F' stands for Female
    default: return "Onbekend";
  }
};

function isEven(num: number): boolean {
  return num % 2 === 0;
}


export function ProviderCard({
  provider, position, backgroundClass
}: {
  provider: HealthCareProviderType;
  position: number;
  backgroundClass: string;
}) {
  const navigate = useNavigate();
  const { getScores, getScoresWithAnswerId } = useResultsContext();
  const [whyIsOpen, setWhyIsOpen] =
    useState<boolean>(false);
  const [whyThisTherapist, setWhyThisTherapist] =
      useState<string>("");
  const [mostSimilarMethods, setMostSimilarMethods] =
      useState<any[]>([]);
  const [methodsPerCategory, setMethodsPerCategory] =
    useState<MethodsPerCategory>({});
  const [methodWeightsPerId, setMethodWeightsPerId] =
    useState<MethodWeightById>({});
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);

  const goToDetail = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
    navigate(`${routes.providerDetails}?providerId=${provider.id}`); 
  };


  const onContactClick = () => {
    window.scrollTo(0, 0);
    navigate(`/contact/${provider.id}/${provider.providerData.name}`);
  };

  function getMethodSlidersForCategory(category: string, threshold: number) {
    const methods = methodsPerCategory[category];
    if (methods) {
      return methods.filter(method => methodWeightsPerId[method.id] && methodWeightsPerId[method.id] > threshold)
      .map((method) => (
        <div key={method.id}>
          <div className="my-account-label">{method.name}</div>
          <Slider showInfoText={false}
            value={methodWeightsPerId[method.id] || 0}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={(weight) => {}}
          />
        </div>
      ));
    } else {
      return null;
    }
  }


  const onWhyClick = async (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
    setWhyIsOpen(true); // Open the dialog first
    setIsLoading(true); // Set loading to true initially
    if (provider && Object.keys(getScores()).length > 0) {
      const response:any = await ProviderApi.getWhyProvider(getScoresWithAnswerId(), provider.id);
      setWhyThisTherapist(response.answer);
      setMostSimilarMethods(response.mostSimilarMethods);
      const methodsPerCategory = await MethodApi.getMethodsPerCategory();
      setMethodsPerCategory(methodsPerCategory);
      const methodAnswers = await MethodApi.getMethodWeightsForProvider(
        provider.id
      );
      setMethodWeightsPerId(methodAnswers);
      setIsLoading(false);
    }
  };
  const imageRef = useRef<HTMLImageElement>(null); // Create a reference for the image
  const imageRefDesktop = useRef<HTMLImageElement>(null); // Create a reference for the image

  return (
    <>
    <div className={`provider-card provider-hover-behaviour ${backgroundClass}`} onClick={goToDetail}>
        <div className={"provider-card-text"}>

        <div className="mobile"
         ref={imageRef} // Attach the ref to the image
        >
          <div className={"provider-card-image-container"}>

            <img
             
              src={provider.providerData.profilePictureUrl || "/person.png"}
              alt={"Profile picture of healthcare provider"}
              className={"mobile provider-card-image mb-4"}
            />
          </div>
          <MatchGauge score={provider.normalizedScore} imageRef={imageRef}/>
          {provider.providerData?.avgWaitingDurationWeeks?(<WaitingGauge avgWaitingDurationWeeks={provider.providerData?.avgWaitingDurationWeeks} imageRef={imageRef}/>):(<></>)}

        </div>
        
        <div className={"provider-card-name"}>
          {provider.providerData.name} {provider.providerData.surName}
        </div>
        {provider.providerData.practiceName? (
          <div>
          <h2>{provider.providerData.practiceName}</h2>
          {provider.providerData.address?(<div>{provider.providerData.address}</div>):(<></>)}
          </div>
        ):(<>{provider.providerData.address?(<div className="provider-card-subtitle">{provider.providerData.address}</div>):(<></>)}</>)}
        <div className="provider-card-buttons">
        <a className={"small-button dark-button contact-button"} onClick={goToDetail}>
          Meer informatie 
        </a>
        <a className={"small-button medium-dark-button why-button"} onClick={onWhyClick}>
          Waarom deze therapeut? 
        </a>
        </div>
        <div className={"mt-4"}>
          {provider.providerData?.practiceDescription}
        </div>

        <div className={"provider-card-info"}>
          <div className={"provider-card-subtitle"}>Algemeen</div>
          <span>
            {getDisplaySex(provider.providerData)}
          </span>
          <span>
            <b>Leeftijd</b>: {provider.providerData?.age || "Onbekend"}
          </span>
          <span>
            <b>Prijs per sessie</b>:{" "}
            {provider.providerData.pricePerSession ? `€${provider.providerData.pricePerSession}` : "Onbekend"}
          </span>
          <span>
            <b>Werkzaam in regio (postcode)</b>:{" "}
            {provider.providerData.practiceRegionZipCode || "Onbekend"}
          </span>
          <span>
            <b>Geconventioneerd</b>:{" "}
            {boolToStr(provider.providerData.conventioned) || "Onbekend"}
          </span>
          <span>
            <b>Gemiddelde wachttijd</b>:{" "}
            {provider.providerData?.avgWaitingDurationWeeks || "Onbekend"} weken
          </span>
          <span>
            <b>Terugbetalingsopties</b>:{" "}
            {(provider.providerData.refundOptions || []).join(", ")}
          </span>
          <span>
            <b>Leeftijdsgroepen patiënten</b>:{" "}
            {(provider.providerData.ageGroups || []).join(", ")}
          </span>
        </div>
        <div className={"mobile"}>
        <div className={"provider-card-info"}>
        <div className={"provider-card-subtitle"}>Contactgegevens</div>
        
        <span>
          <b>Adres</b>:{" "}{provider.providerData.address || "Ongekend"}
        </span>
        <a className="show-link" onClick={(e) => {
             e.stopPropagation();
             onContactClick();}}>Contacteer {provider.providerData.name}</a>
        
      </div>
        </div>
      </div>
      <div className={"provider-card-right desktop"}>
        
        <div ref={imageRefDesktop} className={isEven(position) ? 'provider-card-image-container' : 'provider-card-image-container background-darker'}>
          {(provider.providerData.profilePictureUrl)? (<img
            alt={"Profile picture of healthcare provider"}
            src={provider.providerData.profilePictureUrl || "/person.png"}
            className={!isEven(position) ? 'provider-card-image' : 'provider-card-image background-darker'}
          />) :(<div className={!isEven(position) ? 'provider-card-image' : 'provider-card-image background-darker'}></div>)}
        </div>     
        <MatchGauge score={provider.normalizedScore} imageRef={imageRefDesktop}/>    
        {provider.providerData?.avgWaitingDurationWeeks?(<WaitingGauge avgWaitingDurationWeeks={provider.providerData?.avgWaitingDurationWeeks} imageRef={imageRef}/>):(<></>)}

        <div className={"provider-card-info"}>
          <div className={"provider-card-subtitle"}>Contactgegevens</div>
        
          <span>
            <b>Adres</b>:{" "}{provider.providerData.address || "Ongekend"}
          </span>
          <a className="show-link" onClick={(e) => {
               e.stopPropagation();
               onContactClick();}}>Contacteer {provider.providerData.name}</a>
          {provider.providerData.landlinePhoneNumber || provider.providerData.mobilePhoneNumber?(<>
            <a className="show-link" onClick={(e) => {
               e.stopPropagation();
              setShowPhoneNumber(true);}}>Toon telefoonnummer</a>
            {showPhoneNumber?(<div>
                {provider.providerData.landlinePhoneNumber || provider.providerData.mobilePhoneNumber}
              </div>):(<></>)}
              </>):(<></>)}
          {provider.providerData.email?(<>
            <a className="show-link" onClick={(e) => {
               e.stopPropagation();
              setShowEmail(true);}}>Toon email</a>
            {showEmail?(<div>
            {provider.providerData.email}</div>):(<></>)}
          </>):(<></>)}
        </div>
      </div>
      
    </div>

      <WhyDialog
      isOpen={whyIsOpen}
      onRequestClose={() => setWhyIsOpen(false)}
      providerName={provider?.providerData.name} // Pass the provider as a prop
      whyThisTherapist={whyThisTherapist}
      mostSimilarMethods={mostSimilarMethods}
      getMethodSlidersForCategory={getMethodSlidersForCategory}
      isLoading={isLoading}
      />
    </>
  );
}
