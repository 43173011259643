export enum Sex {
  M = "M",
  F = "F",
  X = "X",
}

export function toSex(s: string) {
  switch (s) {
    case "M":
      return Sex.M;
    case "F":
      return Sex.F;
    case "X":
      return Sex.X;
  }
}
