import ApiInstance from "./ApiInstance";
import { OrganisationScoresType } from "../types/Types";



export type Organisation = {
  id: string;
  name: string;
  description: string;
  link: string;
  contact: string;
  location: string;
  pictureUrl: string;
  problemArea: string;
  targetAudience: string;
};

export type OrganisationType = {
  id: string;
  OrganisationApi: string;
  organisationData: Organisation;
  scoreSum: number;
};

class OrganisationApi {
  public async getSuggestedOrganisations(
    scores: OrganisationScoresType
  ): Promise<OrganisationType[]> {
    const response = await ApiInstance.getApi().post(
      "/organisation/suggest",
      scores
    );
    return response.data.data;
  }


}

export default new OrganisationApi();
