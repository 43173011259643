import { SingleChoice } from "../single-choice/SingleChoice";
import "./BaseQuestion.css";
import { MultipleChoice } from "../multiple-choice/MultipleChoice";
import { AutoComplete } from "../auto-complete/AutoComplete";
import { Question, QuestionType } from "../../../types/Types";
import SliderQuestion from "../slider-question/SliderQuestion";

export function BaseQuestion({ question, onNext }: { question: Question, onNext: () => void }) {
  const questionSwitch = () => {
    switch (question.type) {
      case QuestionType.SingleChoice:
        return <SingleChoice question={question} onNext={onNext} key={question.id} />;
      case QuestionType.MultipleChoice:
        return <MultipleChoice question={question} key={question.id} />;
      case QuestionType.Slider:
        return <SliderQuestion question={question} key={question.id} />;
      case QuestionType.AutoComplete:
        return <AutoComplete question={question} key={question.id} />;
    }
  };

  return (
    <div className={"base-question"}>
      <div className={"base-question-title"}>{question.description}</div>
      <div className={"base-question-switch"}>{questionSwitch()}</div>
      {question.information ? (
        <div className={"base-question-explanation"}>{question.information}</div>
      ) : (
        <></>
      )}
    </div>
  );
}
