import "./SliderQuestion.css";
import { Question } from "../../../types/Types";
import { useResultsContext } from "../../../hooks/useResultsContext";
import Slider from "../../slider/Slider";

export default function SliderQuestion({ question }: { question: Question }) {
  const { setAnswersForQuestion, getFirstAnswerScore } = useResultsContext();

  const answer = question.answers[0];

  const value = getFirstAnswerScore(question) ?? 5;

  return (
    <div className={"slider-question"}>
      <div className={"slider-question-slider"}>
      <Slider
        value={value ?? 5} // This only sets to 5 if value is null or undefined
        onChange={(value) => {
          setAnswersForQuestion([
            {
              ...answer,
              weight: value,
            },
          ]);
        }}
      />

      </div>
    </div>
  );
}
