import { createContext } from "react";
import { ChosenAnswer, Category, Question, ResultsContextType } from "../types/Types";

export const ResultsContext = createContext<ResultsContextType>({
  setAnswersInSessionStorage: () => {
    return;
  },
  getAnswersFromSessionStorage: () => {
    return;
  },
  finishedCategories: new Set(),
  getAnswers: () => {
    return [];
  },
  showExtraInfo: false,
  setShowExtraInfo() {
    return;
  },
  extraInfo: undefined,
  setDevAnswers: () => {
    return;
  },
  setAnswersForQuestion: () => {
    return;
  },
  finishCategory: () => {
    return;
  },
  isCategoryFinished: () => {
    return false;
  },
  isAnswerActive: () => {
    return false;
  },
  addAnswer: () => {
    return;
  },
  removeAnswer: () => {
    return;
  },
  anyAnswerChosen: () => {
    return false;
  },
  getFirstAnswerScore: () => {
    return null;
  },
  allFinished: false,
  finishAll: () => {
    return;
  },getScores: () => {
    return {
      methodScores: {},
      organisationScores: {},
      mediaScores: {},
    };
  },
  getScoresWithAnswerId: () => {
    return {
    };
  },
  setFreeTextAnswer: () => {
    return;
  },
  physicalDiagnose: undefined,
  mentalDiagnose: undefined,
});
