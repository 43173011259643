// ContactFormPage.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContactForm } from '../../components/contact-form/ContactForm'; // Adjust the import path if necessary
import './ContactFormPage.css'; // Create a CSS file for styling
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import Lottie from 'lottie-react';
import bottomAnimation from '../../animations/sea.json'; // Adjust path accordingly

export function ContactFormPage() {
  const { providerId, firstName } = useParams();

  const goBack = () => {
    window.history.back();
  };
  return (
    <div>
    <div className={"wrapper"}>
      <div className={"menu-container"}>
      <Header/>
      <div className="back-link">
        <a  onClick={goBack}>← Terug</a>
      </div>
      <div className="contact-form-page">
        <h1>Contacteer {firstName}</h1>
        <ContactForm providerId={providerId} />
        <br/><br/>
      </div>
    </div>
    </div>
    <div className={"grey-area"}>
          <div className={"centered-text intro-text"}>
                <p>
                  Deze vragenlijst kwam tot stand met ondersteuning en validatie van hulpverleners en hulpvragers. Mia werd gerealiseerd dankzij onze partners.
                </p>
              
                <div className={"partner-logos"}>
                  <img src="/imec.png" className={"partner-logo"} alt="Imec"/>
                  <img src="/comon.png" className={"partner-logo"} alt="Comon"/>
                  <img src="/ugent.png" className={"partner-logo"} alt="UGent"/>

                </div>
        </div>
    </div>
    <Footer/>
    </div>
  );
}
