import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // If you're using react-router for navigation
import './Header.css'; // Ensure you have the necessary styles

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleProviderButtonClick = () => {
    navigate("/hulpverlener");
  };

  return (
    <header className="header">
      <div className="logo"><a href="/">Mia</a></div>
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <a href="/">Home</a>
        <a href="/questionnaire">Start de hulpzoeker</a>
        <a href="/informatie">Ik wil informatie</a>
        <a href="https://app.miapraktijk.be">Login</a>
        <button onClick={handleProviderButtonClick} className="provider-button">Ik ben hulpverlener</button>
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

export default Header;
