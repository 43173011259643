import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./WorkingHours.css";

const daysOfWeek = ["Ma", "Di", "Wo", "Do", "Vr", "Za"];

const WorkingHours = ({ value, onChange = undefined }: { value: string; onChange?: any }) => {
  const initialWorkingHours = {
    Ma: [6, 23],
    Di: [6, 23],
    Wo: [6, 23],
    Do: [6, 23],
    Vr: [6, 23],
    Za: [6, 23],
  };

  const [workingHours, setWorkingHours] = useState(initialWorkingHours);
  const [workDays, setWorkDays] = useState({Ma: true, Di: true, Wo: true, Do: true, Vr: true, Za: true});


  useEffect(() => {
    if (value) {
      const lines = value.split("\n");
      
      const newHours = { ...initialWorkingHours };
      const newWorkDays = {Ma: true, Di: true, Wo: true, Do: true, Vr: true, Za: true};
      
      lines.forEach((line) => {
        const [day, hoursStart, hyphen, hoursEnd] = line.trim().split(/\s+/); // Use trim() to remove leading and trailing whitespace
        const start = parseInt(hoursStart.split(":")[0]);
        const end = parseInt(hoursEnd.split(":")[0]);
        newHours[day] = [start, end];
        if (start === end) {
          newWorkDays[day] = false;
        }
        else {
          newWorkDays[day] = true;
        }
      });
      setWorkingHours(newHours);
      setWorkDays(newWorkDays);
    }
  }, [value]);


  const handleSliderChange = (day, newValue) => {
    setWorkingHours((prevHours) => ({
      ...prevHours,
      [day]: newValue,
    }));
    if (newValue[0] === newValue[1]) {
      setWorkDays((prevDays) => ({
        ...prevDays,
        [day]: false,
      }));
    } else {
      setWorkDays((prevDays) => ({
        ...prevDays,
        [day]: true,
      }));
    }
  };


  const handleCheckboxChange = (day) => {
    setWorkDays((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));
  };


  useEffect(() => {
    daysOfWeek.forEach((day) => {
      if (!workDays[day]) {
        setWorkingHours((prevHours) => ({
          ...prevHours,
          [day]: [0, 0],
        }));
      }
      else {
        if (workingHours[day][0] === 0 && workingHours[day][1] === 0) {
          setWorkingHours((prevHours) => ({
            ...prevHours,
            [day]: [9, 17],
          }));
        }
      }
    });
  }, [workDays]);

  useEffect(() => {
    if (onChange) {
      onChange(formatWorkingHours());
    }
  }, [workingHours]);


  const formatHoursRange = (hours) => {
    return `${hours[0]}:00 - ${hours[1]}:00 u`;
  };

  const formatWorkingHours = () => {
    let result = "";
    daysOfWeek.forEach((day) => {
      const hours = workingHours[day];
      result += `${day.padEnd(2)} ${formatHoursRange(hours)}\n`;
    });
    return result.trim();
  };

  const renderDaySliders = () => {
    return daysOfWeek.map((day) => (
      <div key={day}>
        <div className="nowork-checkbox-container">
          <input
            disabled={!onChange}
            type="checkbox"
            checked={workDays[day]}
            onChange={() => handleCheckboxChange(day)}
            className="nowork-checkbox"
          />
          <label>{day}</label>
        </div>
        {!workDays[day] && <div className="nowork-label">Niet werken</div>}
        {workDays[day] && 
        <div>        
          <Slider
          disabled={!onChange}
          min={6}
          max={23}
          step={1}
          range
          defaultValue={[6, 23]}
          value={workingHours[day]}
          onChange={(newValue) => handleSliderChange(day, newValue)}
        />
        <div>{formatHoursRange(workingHours[day])}</div>
        </div>
        }
      </div>
    ));
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {Array.from({ length: 17 }, (_, i) => (
          <div key={i + 6} style={{ width: `${100 / 17}%` }}>{i + 6}</div>
        ))}
      </div>
      <div>
        {renderDaySliders()}
      </div>
    </div>
  );
};

export default WorkingHours;
