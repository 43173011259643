import React, { useState } from 'react';
import './Informatie.css';
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import informatieImage from '../../assets/informatie.svg'; // Adjust path accordingly
import comonImage from '../../assets/comon.webp';
import imecImage from '../../assets/imec.webp';
const Informatie: React.FC = () => {

  const handleTerugbetalingButtonClick = () => {
    window.location.href = "/terugbetaling";
  };
  const handleSoortenZorgButtonClick = () => {
    window.location.href = "/soortenzorg";
  };


  return (
    <div className="container">
      <Header/>
      
      <main className="main-info">
        <section className="intro-section">
          <h1>Welkom in het Mia informatie-luik</h1>
          <p>Welke soorten therapieën bestaan er? Heb ik recht op terugbetaling? Wat mag ik verwachten van therapie? Heb ik zelfs een psycholoog nodig? We voorzien een antwoord op enkele vragen!</p>
        </section>

        <section className="info-section">
          <div className="left-column">
            Wat is ’conventionering’? Heb ik recht op terugbetaling?
          </div>
          <div className="right-column">
            <p>Een geconventioneerd psycholoog is een psycholoog die deel uitmaakt van een van de 32 regionale netwerken voor geestelijke gezondheidszorg dat de nieuwe conventie (2021) met het RIZIV heeft ondertekend. Dit betekent dat deze psycholoog het tarievenakkoord van de conventie aanvaardt en de officieel vastgelegde tarieven aanrekent.</p>
            <p>Informatie over de geconventioneerde psychologen en hun contactgegevens, zijn te vinden bij de netwerken voor geestelijke gezondheidszorg. De nieuwe regeling voor de terugbetaling van geconventioneerde psychologen is sinds 1 januari 2022 van kracht. Sindsdien kan je rechtstreeks contact opnemen met een geconventioneerd psycholoog voor eerstelijns psychologische zorg of gespecialiseerde psychologische zorg om hulp te krijgen via de derdebetalersregeling. Je hoeft niet langer eerst langs de huisarts voor een voorschrift.</p>
            <p>De eerstelijns psychologische zorg is direct toegankelijk. De gespecialiseerde psychologische zorg is toegankelijk nadat een functioneel bilan is opgesteld na een eerste sessie. Dit wordt opgesteld door de geconventioneerde psycholoog of orthopedagoog van het netwerk. Hiervoor kan die uw huisarts contacteren, met uw toestemming.</p>
            <button className="read-more-button" onClick={handleTerugbetalingButtonClick}>Lees meer</button>
          </div>
        </section>

        <section className="info-section">
          <div className="left-column">
            Welke soorten geestelijke gezondheidszorg bestaan er in België?
          </div>
          <div className="right-column">
            <p>Het Belgische landschap van de geestelijke gezondheidszorg biedt een breed scala aan diensten voor mensen die hulp zoeken bij psychische problemen. Aan de basis staat de huisarts, die een cruciale rol speelt in de initiële beoordeling en doorverwijzing naar gespecialiseerde zorg. Voor directe ondersteuning zonder doorverwijzing zijn er onafhankelijke psychologen en therapeuten beschikbaar. Daarnaast bieden Centra Geestelijke Gezondheidszorg (CGG) toegankelijke, ambulante zorg voor mensen met psychische aandoeningen en crisis situaties. In urgente gevallen kunnen patiënten terecht bij de spoedeisende hulp of opname in een Psychiatrische Afdeling van een Algemeen Ziekenhuis (PAAZ).</p>
            <p>Voor intensievere zorg beschikken psychiatrische ziekenhuizen over mogelijkheden voor zowel korte als langdurige opnames, afhankelijk van de ernst en aard van de problematiek. Naast traditionele face-to-face therapieën groeit het aanbod van online hulpverlening, wat een laagdrempelige toegang biedt tot professionele ondersteuning. Voor mensen in acute crisissituaties, zoals bij zelfmoordgedachten, zijn er specifieke interventiediensten zoals de Zelfmoordlijn, waar men anoniem en 24/7 terecht kan voor hulp. Elk van deze diensten speelt een essentiële rol in het brede en toegankelijke netwerk van geestelijke gezondheidszorg in België.</p>
            <button className="read-more-button" onClick={handleSoortenZorgButtonClick}>Lees meer</button>
          </div>
        </section>
        <div className="informatie-image-wrapper">
          <img className="info-image" src={informatieImage} alt="Informatie" />
        </div>
        {/* Add more sections as needed */}
      </main>
      <Footer /> {/* Add the Footer component here */}
    </div>
  );
};

export default Informatie;
