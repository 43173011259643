import "./OrganisationCard.css";
import { useResultsContext } from "../../hooks/useResultsContext";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes"
import React, { useEffect, useState } from "react";
import WhyDialog from "../why-organisation-dialog/WhyOrganisationDialog";
import OrganisationApi, { OrganisationType } from "../../api/OrganisationApi";


function boolToStr(bool: boolean | null) {
  return bool === null ? "Onbekend" : bool ? "Ja" : "Nee";
}


function isEven(num: number): boolean {
  return num % 2 === 0;
}

export function OrganisationCard({
  organisation, position, backgroundClass
}: {
  organisation: OrganisationType;
  position: number;
  backgroundClass: string;
}) {

  const goToDetail = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
    window.open(organisation.organisationData.link, '_blank');
  };

  return (
    <>
    <div className={`organisation-card organisation-hover-behaviour ${backgroundClass}`} onClick={goToDetail}>

      
      <div className={"organisation-card-text"}>
        <div className="mobile">
        {organisation.organisationData.pictureUrl ? (<div className={"organisation-card-image-container"}><img
              src={organisation.organisationData.pictureUrl}
              alt={"Profile picture of healthcare organisation"}
              className={"mobile organisation-card-image"}
            /></div>):(<></>)}
        </div>
        
        <div className={"organisation-card-name"}>
          {organisation.organisationData.name}
        </div>
        {organisation.organisationData.location?(<div className="organisation-card-subtitle">{organisation.organisationData.location}</div>):(<></>)}
        
         
        <div className={"mt-4"}>
          {organisation.organisationData?.description}
        </div>
        <br/>
        {organisation.organisationData.problemArea ? (
        <span>
          <b>Probleemgebied</b>:{" "}
          {organisation.organisationData.problemArea || "Ongekend"}

        <br/>
        </span>):(<></>)}
        {organisation.organisationData.targetAudience ? (
        <span>
          <b>Doelpubliek</b>:{" "}
          {organisation.organisationData.targetAudience || "Ongekend"}

        <br/>
        </span>):(<></>)}
        {organisation.organisationData.contact ? (
          <span>
            <b>Contact nemen</b>:{" "}
            {organisation.organisationData.contact.includes("http") ? (
              <a
                href={organisation.organisationData.contact}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()} // Prevents event bubbling
              >
                {organisation.organisationData.contact}
              </a>
            ) : organisation.organisationData.contact.includes("@") ? (
              <a
                href={`mailto:${organisation.organisationData.contact}`}
                onClick={(e) => e.stopPropagation()} // Prevents event bubbling
              >
                {organisation.organisationData.contact}
              </a>
            ) : (
              organisation.organisationData.contact || "Ongekend"
            )}
            <br />
          </span>
        ) : null}

        <span>
          <b>Website</b>:{" "}
          {organisation.organisationData.link ? (
            <a href={organisation.organisationData.link} target="_blank" rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}>
              {organisation.organisationData.link}
            </a>
          ) : (
            "Ongekend"
          )}
        </span>
      </div>
      <div className={"organisation-card-right desktop"}>
      {(organisation.organisationData.pictureUrl)? (
        <div className={isEven(position) ? 'organisation-card-image-container' : 'organisation-card-image-container background-darker'}>
          <img
            alt={"Picture of organisation"}
            src={organisation.organisationData.pictureUrl}
            className={!isEven(position) ? 'organisation-card-image' : 'organisation-card-image background-darker'}
          />
        </div>):(
          <div className="image-placeholder">
            
          </div>
        )}     
      </div>
    </div>
      
      
    </>
  );
}
