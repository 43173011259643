import React, { ChangeEvent, FormEvent, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SettingsCheckbox } from '../settings-checkbox/SettingsCheckbox';
import './ContactForm.css'; // Adjust the path if necessary
import EmailApi from '../../api/EmailApi';
import { Config } from '../../Config';

export type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  phone: string;
  subject: string;
  message: string;
  sendCopy: boolean;
  consent: boolean;
  recaptchaValue: string | null;
  providerId: string | undefined;
};

interface ContactFormProps {
  providerId: string | undefined;
}

export function ContactForm({ providerId }: ContactFormProps) {
  const [sendingMail, setSendingMail] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phone: '',
    subject: '',
    message: '',
    sendCopy: false,
    consent: false,
    recaptchaValue: null,
    providerId
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const isCheckbox = e.target.type === 'checkbox';
    
    setFormData({
      ...formData,
      [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value,
    });
  };

  const handleChangeCheckbox = (name: string, checked: boolean) => {
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  
  const handleRecaptcha = (value: string | null) => {
    setFormData({ ...formData, recaptchaValue: value });
  };

  const [mailSent, setMailSent] = useState(false);

  const saveFormData = async (formData: FormData) => {
    setSendingMail(true);
    await EmailApi.postResultsEmail(formData);
    setSendingMail(false);
    setMailSent(true);
  };

  const [recaptchaError, setRecaptchaError] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setRecaptchaError(false);
    setConsentError(false);
    setEmailError(false);
    if (!formData.recaptchaValue) {
      setRecaptchaError(true);
    }
    if (!formData.consent) {
      setConsentError(true);
    }
    if (formData.email !== formData.confirmEmail) {
      setEmailError(true);
    }
    if(formData.recaptchaValue && formData.consent && formData.email === formData.confirmEmail) {
      saveFormData(formData);
    }
  };

  return (
    <form className="contactform" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="firstName">Je voornaam *</label>
        <input type="text" id="firstName" name="firstName" required onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="lastName">Je familienaam *</label>
        <input type="text" id="lastName" name="lastName" required onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="email">Je e-mailadres *</label>
        <input type="email" id="email" name="email" required onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="confirmEmail">Bevestig je e-mailadres *</label>
        <input type="email" id="confirmEmail" name="confirmEmail" required onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="phone">Tel / GSM *</label>
        <input type="tel" id="phone" name="phone" required onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="subject">Onderwerp *</label>
        <input type="text" id="subject" name="subject" required onChange={handleChange} />
      </div>
      <div></div>
      <div>
        <label htmlFor="message">Bericht *</label>
        <textarea rows={12} id="message" name="message" required onChange={handleChange}></textarea>
      </div>
      <div></div>
      <div>
        <SettingsCheckbox
          checked={formData.sendCopy}
          label="Stuur mij een kopie van dit bericht"
          onChange={(val) => handleChangeCheckbox("sendCopy", val)}
        />
        <SettingsCheckbox
          checked={formData.consent}
          label="Ik geef toestemming dat mijn gegevens worden verzonden"
          onChange={(val) => handleChangeCheckbox("consent", val)}
        />
        <br/>
        <ReCAPTCHA
          sitekey={Config.getGoogleRecaptchaSiteKey()}
          onChange={handleRecaptcha}
        />
        <p className="contactform-disclaimer">* Verplichte velden</p>
        
        <button className="small-button dark-button contact-send-button" disabled={sendingMail} type="submit">Verzend e-mail</button>
        <p className="contactform-message">
          {sendingMail ? 'Uw contactformulier wordt verzonden...' : ''}
          {mailSent ? 'Contactformulier verzonden!' : ''}
        </p>
        <p className="contactform-error">
          {emailError ? (<>E-mailadressen komen niet overeen<br/></>) : ''}
          {recaptchaError ? (<>Gelieve te verifiëren dat u geen robot bent<br/></>) : ''}
          {consentError ? 'Gelieve toestemming te geven' : ''}
        </p>
      </div>
    </form>
  );
}
