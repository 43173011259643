import React, { useState, useEffect } from 'react';
import './HomePage.css';
import Header from '../../components/header/Header'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Import the Footer component
import Lottie from 'lottie-react';
import homeAnimation from '../../animations/home.json'; // Adjust path accordingly
import contactImage from '../../assets/contact.png';
import lijstImage from '../../assets/lijst.png';
import verfijnImage from '../../assets/verfijn.png';
import vragenImage from '../../assets/vragen.png';
import providerImage from '../../assets/hulpverlener.png';
import comonImage from '../../assets/comon.webp';
import imecImage from '../../assets/imec.webp';
import facebookIcon from '../../assets/facebook.png'; // Adjust path accordingly
import instagramIcon from '../../assets/instagram.png'; // Adjust path accordingly
import linkedinIcon from '../../assets/linkedin.png'; // Adjust path accordingly


const HomePage: React.FC = () => {

  const [scrollY, setScrollY] = useState(0);
  const [parallaxHeight, setParallaxHeight] = useState('auto');


  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  const handleTestButtonClick = () => {
    window.location.href = "/questionnaire";
  };

  const handleProviderButtonClick = () => {
    window.location.href = "/hulpverlener";
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  useEffect(() => {
    const parallaxElement = document.querySelector('.paralax-home');
    if (parallaxElement) {
      const rect = parallaxElement.getBoundingClientRect();
      const heightAdjustment = window.innerHeight - rect.top - window.scrollY;
      setParallaxHeight(`${heightAdjustment}px`);
    }
  }, [scrollY]);

  return (
    <div className="container">
      
      <Header />
      <main className="main">
        <div className="content-wrapper">

          <div className="image-section">
              <Lottie animationData={homeAnimation} loop={true} />
          </div>
          <div className="text-section">
            <h1>Mia vindt de gepaste hulpverlening voor jou en jouw situatie</h1>

            <button className="test-button" onClick={handleTestButtonClick}>Start de hulpzoeker</button>
            <br/>
            <div className="vragen-homepage">
              <h2>Zit je met vragen?</h2>
              <p>Zoek een antwoord op je vragen over de <br/>geestelijke gezondheidszorg in ons <a href="/informatie">informatieluik</a>.</p>
            </div>
            
          </div>
        </div>
      </main>
      <ul className="info-list" onClick={handleTestButtonClick}>
              <li>
                <div className="icon">&#10142;</div>
                <div className="text">
                  <p>Beantwoord een aantal eenvoudige, anonieme vragen.</p>
                  <span>Deze zullen een beeld vormen van de problematiek, hulpvraag en voorkeuren van de hulpverlening.</span>
                </div>
              </li>
              <li>
                <div className="icon">&#10142;</div>
                <div className="text">
                  <p>Selecteer de soort hulpverlening die u wenst voor uw hulpvraag.</p>
                  <span>Dit kunnen hulpverleners zijn, maar ook lotgenotencontact, workshops of literatuur zit in onze databank.</span>
                </div>
              </li>
              <li>
                <div className="icon">&#10142;</div>
                <div className="text">
                  <p>Filter de resultatenpagina.</p>
                  <span>Specificeer de locatie, taal, budget, beschikbaarheid en meer.</span>
                </div>
              </li>
            </ul>
      <br/><br/><br/><br/><br/><br/><br/>
      <div className="paralax-home" style={{ transform: `translateY(-${scrollY * 0.5}px)`, height: parallaxHeight }}>
      <div className="hulpverlener">
        <div className="hulpverlener-text">
          <h2>Ben je hulpverlener ?</h2>
          <p><b>Wanneer we de geestelijke gezondheidszorg gemakkelijker willen maken, <span className="important-text">gaan we eerst en vooral moeten zorgdragen voor de hulpverleners.</span> </b><br/><br/>
            Daarom hebben we bij Mia een elektronisch patiëntendossier ontworpen dat speciaal is aangepast aan de geestelijke gezondheidszorg. Dit betekent dat wij <b>niet alleen voldoen aan de nieuwe kwaliteitswet</b> en constant op de hoogte blijven van de nieuwe regelgeving, maar ook dat wij specifiek inspelen op de noden van therapeuten. <br/><br/>Zo beschikken we naast <span className="important-text">agendabeheer, online beveiligde sessies en een cliëntenportaal ook over praktijkruimte-verdeling, de mogelijkheid om digitale schema's en genogrammen aan te maken en het digitaal overzetten van notities in een persoonlijk patiëntendossier</span>.
            Dankzij Mia Praktijk laten we de administratieve taken efficiënter en correcter verlopen zodat hulpverleners zich kunnen concentreren op wat er echt toe doet, namelijk de hulpvrager. Kortom, naast het profiel op miazoekthulp.be krijgt uw praktijk ook de nodige digitale ondersteuning die in deze tijd onontbeerlijk is.
            <br/><br/><b>Zo helpt Mia jou voldoen aan de nieuwe kwaliteitswet met kwaliteitsvolle digitale dossiers. Wist je dat je met Mia Praktijk ook met de tablet kan werken tijdens een therapie-sessie?</b></p>
        </div>
        <div className="hulpverlener-bigtext">
          <br/><br/>
          <small>Dankzij Mia Praktijk laten we de administratieve taken efficiënter en correcter verlopen zodat hulpverleners zich kunnen concentreren op wat er echt toe doet, namelijk de hulpvrager. </small><br/>
          <br/>Kortom, naast het profiel op miazoekthulp.be krijgt uw praktijk ook de nodige digitale ondersteuning die in deze tijd onontbeerlijk is.
          <img src={providerImage} className="provider-image" alt="Hulpverlener" />
        </div>
      </div>
      <div className="hulpverlener-solutions">
        <h2 className="hulpverlener-solutions-title">Ontdek de oplossingen van Mia</h2>
        <div>
          <div className="solutions">
            <div className="solution">
              <li>Krijg enkel de cliënten die bij jou passen</li>
            </div>
            <div className="solution">
              <li>Enkel cliënten wanneer jouw wachtlijst dit toelaat</li>
            </div>
            <div className="solution">
              <li>Makkelijke praktijkruimtes beheren</li>
            </div>
            <div className="solution">
              <li>Minder tijd verliezen aan administratie</li>
            </div>
            <div className="solution">
              <li>Ga makkelijk digitaal en wordt GDPR en kwaliteitswet conform</li>
            </div>
            <div className="solution">
              <li>Minder tijd verliezen aan doorverwijzingen</li>
            </div>
          </div>
          <br/>
          <div className="button-container">
            <button onClick={handleProviderButtonClick} className="subscribe-button">Inschrijven als hulpverlener</button>
          </div>
        </div>
      </div>
      <div className="features-intro">
        <h2>Hoe werkt Mia</h2>
        <p>Met Mia kan je op een eenvoudige manier de gepaste hulpverlening vinden voor jouw situatie. Hieronder vind je een overzicht van de stappen die je kan volgen om de hulp te vinden die je nodig hebt.</p>
      </div>
      <section className="features">
        <div className="feature">
          <img src={vragenImage} alt="Vragen" />
          <h4>Beantwoord een aantal vragen</h4>
          <p>Aan de hand van de antwoorden plaatsen wij funnels op onze databank en kunnen we een gepersonaliseerd aanbod voorstellen, op maat van jouw noden, voorkeuren, hulpvraag en meer.</p>
        </div>
        <div className="feature">
          <img src={verfijnImage} alt="Verfijn" />
          <h4>Verfijn uw zoekopdracht</h4>
          <p>De resultaten kunnen verder gefilterd worden zodat de selectie nog specifieker past bij jou. Zo houden we ook rekening met zaken zoals locatie, budget, leeftijd, gender, religie, en meer</p>
        </div>
        <div className="feature">
          <img src={lijstImage} alt="Lijst" />
          <h4>Krijg een lijst van beschikbare en gepaste hulp</h4>
          <p>Op de resultatenpagina vind je uitendelijk de hulpverleners die het best overeenkomen met jouw zoekactie. Dit wordt dan aangevuld met organisaties, literatuur en andere initiatieven die mogelijks bij jouw profiel passen en interessant kunnen zijn om te raadplegen.</p>
        </div>
        <div className="feature">
          <img src={contactImage} alt="Contact" />
          <h4>Neem contact op wanneer u er klaar voor bent</h4>
          <p>Gezien het laagdrempelige en anonieme karakter van MIA, zullen wij nooit gegevens verdelen zonder toestemming. Je kiest uiteindelijk zelf welke hulp je contacteert en of je wilt dat deze instantie op de hoogte wordt gesteld van jouw antwoorden op de vragen van de zorgzoeker.</p>
        </div>
      </section>
      <div className="sponsors">
        <h2>Met ondersteuning van onze partners</h2>
        <p>
        Deze slimme oplossing is bedacht in de schoot van Comon, een initiatief van de stad Gent in samenwerking met Imec. Tijdens een van de Miniproeftuinen over verstaanbare zorg heeft een divers team dit idee verder ontwikkeld tot een werkend prototype. Met dank aan deze partners:
        <br/><br/>
        <div className="sponsors-images">
          <img src={imecImage} alt="Imec" />
          <img src={comonImage} alt="Comon" />
        </div>
        </p>
    
      </div>
      <Footer /> {/* Add the Footer component here */}
      </div>
    </div>
  );
};

export default HomePage;
