import "./SingleChoice.css";
import { Question } from "../../../types/Types";
import { useResultsContext } from "../../../hooks/useResultsContext";

export function SingleChoice({ question, onNext }: { question: Question, onNext: () => void }) {
  const { setAnswersForQuestion, isAnswerActive } = useResultsContext();

  return (
    <div className={"single-choice"}>
      <div className={"single-choice-answers"}>
        {question.answers.sort((a1,a2) => a1.order - a2.order).map((answer) => (
          <button
            key={answer.id}
            className={`large-button single-choice-answer${
              isAnswerActive(answer) ? " active" : ""
            }`}
            onClick={() => {
              setAnswersForQuestion([answer]);  // Set the selected answer
              onNext(); // Move to the next question immediately
            }}
          >
            {answer.description}
          </button>
        ))}
      </div>
    </div>
  );
}
