import "./ProgressBar.css";
import { Category, Question } from "../../types/Types";

export function ProgressBar({
  sortedQuestions,
  questionIndex,
  onClick,
  categories,
  categoryIndex,
  showCategories,
}: {
  sortedQuestions: Question[];
  questionIndex: number;
  onClick: (index: number) => void;
  categories: Category[];
  categoryIndex: number;
  showCategories: boolean;
}) {
  return (
    <div className={"progress-bar mt-2"}>
      {showCategories ? (
        <div className={"progress-bar-categories mt-4 mb-4"}>
          {categories
            .filter((category, index) => categoryIndex === index)
            .map((category) => (
              <div key={category.id}>{category.name}</div>
            ))}
        </div>
      ) : null}
      <div className={"progress-bar-container"}>
        {sortedQuestions.map((question, index) => (
          <div
            key={question.id}
            onClick={() => onClick(index)}
            className={`progress-bar-progress${
              index <= questionIndex ? " progress-bar-passed" : ""
            }`}
          />
        ))}
      </div>
    </div>
  );
}
