import ApiInstance from "./ApiInstance";
import { MethodScoresType, ScoresWithAnswerType } from "../types/Types";
import { HealthcareProvider } from "./HealthcareProviderApi";

export type HealthCareProviderType = {
  id: string;
  ProviderApi: string;
  providerData: HealthcareProvider;
  normalizedScore: number;
};

class ProviderApi {
  public async getSuggestedProviders(
    scores: MethodScoresType
  ): Promise<HealthCareProviderType[]> {
    const response = await ApiInstance.getApi().post(
      "/provider/suggest",
      scores
    );
    return response.data.data;
  }


  public async getWhyProvider(
    scores: ScoresWithAnswerType, providerId: string
  ): Promise<string> {
    const response = await ApiInstance.getApi().post(
      "/provider/why",
      {userScores: scores, providerId}
    );
    return response.data;
  }


  public async countProviderMatches(scores: MethodScoresType): Promise<{strong: number, veryGood: number, good: number, reasonable: number}> {
    const response = await ApiInstance.getApi().post("/provider/countMatches", scores);
    return response.data;
  }

}

export default new ProviderApi();
