import React, { useState } from "react";
import { Button, TextField, MenuItem } from "@mui/material";
import { SettingsSelect } from "../settings-select/SettingsSelect";
import { Method } from "../../types/Types";
import "./CertificationForm.css";

export type Certification = {
  certName: string;
  trainingCenterId: string;
  yearCompleted: number;
};

interface CertificationFormProps {
  trainingCenters: Method[];
  onAddCertification: (certification: Certification) => void;
}

const CertificationForm: React.FC<CertificationFormProps> = ({ trainingCenters, onAddCertification }) => {
  const [certName, setCertName] = useState<string>("");
  const [selectedTrainingCenter, setSelectedTrainingCenter] = useState<string>("");
  const [yearCompleted, setYearCompleted] = useState<string>("");

  const handleAddCertification = (e) => {
    e.preventDefault();
    if (certName && selectedTrainingCenter && yearCompleted) {
      onAddCertification({
        certName,
        trainingCenterId: selectedTrainingCenter,
        yearCompleted: parseInt(yearCompleted)
      });
      setCertName("");
      setSelectedTrainingCenter("");
      setYearCompleted("");
    }
  };

  return (
    <div className="add-certification">
      <TextField
        label="Certificatie, opleiding of diploma"
        value={certName}
        onChange={(e) => setCertName(e.target.value)}
        variant="outlined"
        style={{ width: '100%' }} 
      />
      <div className="add-certification-second-row">
      <SettingsSelect
        multiple={false}
        options={trainingCenters}
        onSelect={(tc) => setSelectedTrainingCenter(tc.id)}
        label={(tc) => tc.name}
      />
      </div>
      <div className="add-certification-last-row">
      <TextField
        label="Jaar voltooid"
        type="number"
        value={yearCompleted}
        onChange={(e) => setYearCompleted(e.target.value)}
        variant="outlined"
        style={{ width: '150px' }} 
      />
      <button className="small-button lighter-button" onClick={handleAddCertification}>Ok</button>
      </div>
    </div>
  );
};

export default CertificationForm;
