import "./MultipleChoice.css";
import { Question } from "../../../types/Types";
import { useResultsContext } from "../../../hooks/useResultsContext";
import { SelectButton } from "../../select-button/SelectButton";

export function MultipleChoice({ question }: { question: Question }) {
  const { isAnswerActive, addAnswer, removeAnswer } = useResultsContext();

  return (
    <div className={"multiple-choice"}>
      <div className={"multiple-choice-answers"}>
        {question.answers.map((answer) => {
          const selected = isAnswerActive(answer);

          return (
            <SelectButton
              key={answer.id}
              selected={selected}
              onClick={() => {
                if (selected) {
                  removeAnswer(answer);
                } else {
                  addAnswer(answer);
                }
              }}
            >
              {answer.description}
            </SelectButton>
          );
        })}
      </div>
    </div>
  );
}
