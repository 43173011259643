import React from "react";
import "./SettingsCheckbox.css";
import checkIcon from "../../assets/check.svg";

export function SettingsCheckbox({
  checked,
  onChange,
  label,
  className,
}: {
  checked: boolean;
  onChange: (answer: boolean) => void;
  label: string;
  className?: string;
}) {
  return (
    <div className={`settings-checkbox-container ${className || ""}`}>
      <button
        className={`select-button-answer settings-large-button ${
          checked ? " active" : ""
        } settings-checkbox`}
        onClick={(e) => {
          e.preventDefault();
          onChange(!checked);
        }}
      >
        <div className="settings-select-button-checkbox">
          {checked ? <img src={checkIcon} alt="check icon" /> : null}
        </div>
      </button>
      <div
        className="settings-checkbox-label"
        onClick={(e) => {
          e.preventDefault();
          onChange(!checked);
        }}
      >
        {label}
      </div>
    </div>
  );
}
