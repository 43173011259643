import ApiInstance from "./ApiInstance";

export type MediaUrl = {
  url: string;
};

class MediaApi {
  public async postMedia(form: FormData): Promise<MediaUrl> {
    const response = await ApiInstance.getApi().post("/media/", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
}

export default new MediaApi();
