import React, { useState } from 'react';
import Dialog from '../dialog/Dialog';
import './WhyProviderDialog.css';

interface WhyProviderDialogProps {
  isOpen: boolean;
  onRequestClose: () => void;
  providerName: string | undefined; // Define the type of your provider object
  whyThisTherapist: string;
  mostSimilarMethods: any[]; // Define the type of mostSimilarMethods
  getMethodSlidersForCategory: (category: string, threshold: number) => JSX.Element[] | null;
  isLoading: boolean; // Add isLoading prop
}

const WhyProviderDialog: React.FC<WhyProviderDialogProps> = ({
  isOpen,
  onRequestClose,
  providerName,
  whyThisTherapist,
  mostSimilarMethods,
  getMethodSlidersForCategory,
  isLoading, // Add isLoading prop
}) => {
  function renderParagraphs(text: string) {
    const paragraphs = text.split('<br>').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
    return paragraphs;
  }

  return (
    <Dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size={"large"}
      title={`Waarom ${providerName}`}
    >
      <div className="why">
        <h2>Waarom {providerName}</h2>
        {isLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : (
          <>
            {renderParagraphs(whyThisTherapist)}
            {mostSimilarMethods.length ? (
              <>
                <br />
                <div className="similar-section">
                  <h2>Sterkste overeenkomsten</h2>
                  <br />
                  <div className="similar-info">
                    {mostSimilarMethods.map((method: any, index: number) => (
                      <div className="similar-label" key={index}>{method.name}</div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
            <div>
              <br />
              <h2>Aanpak van deze therapeut</h2>
              <div className="match-info-white">
                {getMethodSlidersForCategory("aanpak", 4)}
              </div>
            </div>
            <br/><br/><br/><br/>
          </>
        )}
      </div>

    </Dialog>
  );
};

export default WhyProviderDialog;
