import "./MediumCard.css";
import { useResultsContext } from "../../hooks/useResultsContext";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MediumApi, { MediumType } from "../../api/MediumApi";


function boolToStr(bool: boolean | null) {
  return bool === null ? "Onbekend" : bool ? "Ja" : "Nee";
}


function isEven(num: number): boolean {
  return num % 2 === 0;
}

export function MediumCard({
  medium, position, backgroundClass
}: {
  medium: MediumType;
  position: number;
  backgroundClass: string;
}) {

  const goToDetail = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
    window.open(medium.mediaData.link, '_blank');
  };

  return (
    <>
    <div className={`medium-card medium-hover-behaviour ${backgroundClass}`} onClick={goToDetail}>
        <div className={"medium-card-text"}>
        <div className="mobile">
        {medium.mediaData.pictureUrl ? (
          <div className={"medium-card-image-container"}>

          
            <img
              src={medium.mediaData.pictureUrl}
              alt={"Profile picture of healthcare provider"}
              className={"mobile medium-card-image"}
            />
          </div>):(<></>)}
        </div>
        
        <div className={"medium-card-name"}>
          {medium.mediaData.title}
        </div>
       
        <div className={"mt-4"}>
          {medium.mediaData?.description}
        </div>
        <p>
          Auteur: <b>{medium.mediaData.author}</b>
          <br/>
          {medium.mediaData.subject ? (
        <span>
          <b>Onderwerp</b>:{" "}
          {medium.mediaData.subject || "Ongekend"}

        <br/>
        </span>):(<></>)}
          {medium.mediaData.problemArea ? (
        <span>
          <b>Probleemgebied</b>:{" "}
          {medium.mediaData.problemArea || "Ongekend"}

        <br/>
        </span>):(<></>)}
        {medium.mediaData.targetAudience ? (
        <span>
          <b>Doelpubliek</b>:{" "}
          {medium.mediaData.targetAudience || "Ongekend"}

        <br/>
        </span>):(<></>)}
        {medium.mediaData.distributor ? (
        <span>
          <b>Distributeur</b>:{" "}
          {medium.mediaData.distributor || "Ongekend"}

        <br/>
        </span>):(<></>)}
        </p>
      </div>
      <div className={"medium-card-right desktop"}>
      {(medium.mediaData.pictureUrl)? (
        <div className={isEven(position) ? 'medium-card-image-container' : 'medium-card-image-container background-darker'}>
         <img
            alt={"Picture of medium"}
            src={medium.mediaData.pictureUrl}
            className={!isEven(position) ? 'medium-card-image' : 'medium-card-image background-darker'}
          />
        </div>) :(<div className="image-placeholder"></div>)}  
      </div>
    </div>
      
      

    </>
  );
}
