export const languages = [
  "Nederlands",
  "Engels",
  "Frans",
  "Duits",
  "Turks",
  "Bulgaars",
  "Oekraïens",
  "Vlaamse gebarentaal",
];
