export enum ParkingAvailibilty {
  FREE = "FREE",
  PAYING = "PAYING",
  NONE = "NONE",
}

export function toParkingAvailability(s: string) {
  switch (s) {
    case "FREE":
      return ParkingAvailibilty.FREE;
    case "PAYING":
      return ParkingAvailibilty.PAYING;
    case "NONE":
      return ParkingAvailibilty.NONE;
  }
}
