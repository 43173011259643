import { PropsWithChildren, ReactElement } from "react";
import ReactModal from "react-modal";
import "./Dialog.css";
import { AiOutlineClose } from "react-icons/ai";

export default function Dialog({
  isOpen,
  children,
  onRequestClose,
  title,
  size,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  url,
}: PropsWithChildren<{
  isOpen: boolean;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  title: string | ReactElement;
  size: "small" | "wide" | "xsmall" | "large" | "slide" | "msmall";
  url?: string; // Make URL property optional
}>) {
  const classNames = ["dialog-body"];
  if (size === "small") {
    classNames.push("is-small");
  }
  if (size === "wide") {
    classNames.push("is-wide");
  }
  if (size === "slide") {
    classNames.push("is-slide");
  }
  if (size === "xsmall") {
    classNames.push("is-xsmall");
  }
  if (size === "large") {
    classNames.push("is-large");
  }
  if (size === "msmall") {
    classNames.push("is-msmall");
  }

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={window.document.getElementById("root") as HTMLElement}
      onRequestClose={onRequestClose}
      className={classNames.join(" ")}
      overlayClassName="dialog-overlay"
      bodyOpenClassName="dialog-open"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <div className={"dialog-header items-center"}>
        <div className="flex items-center">
          <div className="dialog-title">{title}</div>
        </div>
        <div
          className="close-button ml-auto"
          title="Close dialog"
          onClick={() => {
            onRequestClose();
          }}
        >
          <AiOutlineClose />
        </div>
      </div>
      {url && isOpen && <iframe src={url} 
            frameBorder="0"
            width="100%"
            height="90%" title="Dialog Content" />}
      {!url && isOpen &&
      <div className="dialog-content">
        {/* Conditionally render iframe if URL is provided */}
        
        {/* Render children content if provided */}
        {!url && isOpen && children}
      </div>}
    </ReactModal>
  );
}
