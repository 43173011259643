import "./Slider.css";
interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  showInfoText?: boolean; // Make showInfoText optional by adding "?" after its name
  showLeftRightText?: boolean;
  leftText?: string;
  rightText?: string;
}

export default function Slider({
  value,
  onChange,
  showInfoText = true,
  showLeftRightText = true,
  leftText = "Eerder niet",
  rightText = "Eerder wel"
}: SliderProps) {
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent any default action the browser might take
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <div className={"slider-container"}>
      {showInfoText && (
        <div className={"slider-info-text"}>
          Gebruik de slider om uw gevoel aan te geven..
        </div>
      )}
      <div className={"slider-numbers"}>
        {[...Array(11).keys()].map((number) => (
          <div
            key={number}
            className={`slider-number${number === value ? " active" : ""}`}
            onClick={() => onChange(number)}
          >
            {number}
          </div>
        ))}
      </div>
      {showLeftRightText && (
        <div className={"lefttext-righttext"}>
          <div className={"lefttext"}>{leftText}</div>
          <div className={"righttext"}>{rightText}</div>
        </div>
      )}
      <input
        className={"slider"}
        type={"range"}
        min={0}
        max={10}
        step={1}
        value={value}
        onChange={handleSliderChange}
      />
    </div>
  );
}
