import React from 'react';
import './Footer.css'; // Create a separate CSS file for the Footer

import facebookIcon from '../../assets/facebook.png'; // Adjust path accordingly
import instagramIcon from '../../assets/instagram.png'; // Adjust path accordingly
import linkedinIcon from '../../assets/linkedin.png'; // Adjust path accordingly

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <h4>Mia BV</h4>
        <p>BTW BE 1003071465
        </p>
        <h4>Privacy</h4>
        <ul>
          <li><a href="https://www.miazoekthulp.be/privacy">Privacybeleid</a></li>
          <li>Deze site gebruikt geen cookies</li>
        </ul>
      </div>
      <div className="footer-column">
        <h4>Contact</h4>
        <ul>
          <li><a href="mailto:info@miazoekthulp.be">info@miazoekthulp.be</a></li>
          <li><a href="mailto:support@miazoekthulp.be">support@miazoekthulp.be</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h4>Sitemap</h4>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="https://profiel.miapraktijk.be/questionnaire">Start de hulpzoeker</a></li>
          <li><a href="/informatie">Informatieluik</a></li>
          <li><a href="/hulpverlener">Inschrijven als hulpverlener</a></li>
          <li><a href="https://app.miapraktijk.be">login als hulpverlener</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
