import "./ImageInput.css";

export function ImageInput({
  imageUrl,
  onChange,
}: {
  imageUrl: string | null;
  onChange: (img: File) => void;
}) {
  return (
    <div className="image-input-wrapper">
      <label htmlFor="image-upload" className="image-input-label">
        Klik hier om uw profielfoto te uploaden
      </label>
      <input
        className="image-input"
        type="file"
        accept="image/*"
        width={200}
        height={200}
        onChange={({ target: { files } }) => {
          if (files !== null) {
            if (files.length > 0) {
              onChange(files[0]);
            }
          }
        }}
      />
      <div className="image-input-wrapper-plus">
        {imageUrl ? (
          <img src={imageUrl} width={160} height={160} alt={"uploaded image"} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
