import React, { useState, useEffect } from 'react';
import './ProgressGauge.css'; // Ensure you include the CSS from the previous example

const ProgressGauge = ({ percentComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Animation for progress bar: smoothly transition to the new percentage
    const timer = setTimeout(() => {
      setProgress(percentComplete);
    }, 200); // Delay can be adjusted or removed as per your needs

    return () => clearTimeout(timer);
  }, [percentComplete]);

  return (
    <div className="progress-container">
      <div className="progress-gauge" style={{ width: `${progress}%` }}></div>
      <div className="percentage-label">{progress}%</div>
    </div>
  );
};

export default ProgressGauge;
