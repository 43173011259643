import React from 'react';
import Gauge from 'react-gauge-component';
import './ProgressDisplay.css';

const ProgressDisplay = ({ percentComplete, strongMatches, veryGoodMatches, goodMatches, reasonableMatches }) => {
  const totalMatches = strongMatches + veryGoodMatches + goodMatches + reasonableMatches;
  // calculate the percentage of the total matches
  const reasonableMatchesPercent = (reasonableMatches / totalMatches) * 100;
  const goodMatchesPercent = ((goodMatches + reasonableMatches) / totalMatches) * 100;
  const veryGoodMatchesPercent = ((veryGoodMatches + goodMatches + reasonableMatches) / totalMatches) * 100;
  const strongMatchesPercent = ((strongMatches + veryGoodMatches + goodMatches + reasonableMatches) / totalMatches) * 100;

  const bubbles = [
    { count: strongMatches, className: 'strong-match', name: 'beste match' },
    { count: veryGoodMatches, className: 'very-good-match', name: 'sterke match' },
    { count: goodMatches, className: 'good-match', name: 'goede match' },
    { count: reasonableMatches, className: 'reasonable-match', name: 'redelijke match' }
  ];
  
  // Function to calculate size in percentage
  const calculateSize = (count) => {
    const size = (Math.sqrt(count / totalMatches) * 50); // Base relative size as a number
    const limitedSize = Math.max(size, 20);
    const finalSize = 200/100*limitedSize; 
    return finalSize; // Convert to string with 2 decimal places after final calculation
  };

  // Sort bubbles by size for z-index assignment
  const sortedBubbles = bubbles.sort((a, b) => calculateSize(b.count) - calculateSize(a.count));

   // Adjust position based on size to prevent overflow
   const randomPosition = (size) => {
    const maxOffset = 100 - (size / 2); // Adjust the maximum percent offset based on size
    return `${Math.random() * maxOffset}%`;
  };


  return (
    <div className="progress-display">
      <div className="bubble-container">
        {sortedBubbles.map((bubble, index) => {
          const size = calculateSize(bubble.count); // Calculate and use size as a number
          return (
            <div className={`${bubble.className}`} key={bubble.className}>
              <div
                className="line"
                style={{
                  marginTop: `-190px`,
                  height: `200px`, // Example of dynamic line length
                  opacity: 0.2
                }}
              ></div>
              <div
                className="bubble"
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                  maskImage: `url('/mask.svg')`,
                  maskSize: 'contain',
                  maskRepeat: 'no-repeat',
                  zIndex: sortedBubbles.length - index, // Smaller bubbles (lower index) get higher z-index
                }}
              >
                {bubble.count}
              </div>
              <div>{bubble.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
  
};

export default ProgressDisplay;
