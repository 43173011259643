import "./ProviderDetails.css";
import { useResultsContext } from "../../hooks/useResultsContext";
import ProviderApi, { HealthCareProviderType } from "../../api/ProviderApi";
import HealthcareProviderApi, { HealthcareProvider } from "../../api/HealthcareProviderApi";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { routes } from "../../constants/routes";
import { ActiveMethods } from "../../components/methods/ActiveMethods";
import MethodApi, {
  MethodsPerCategory,
  MethodWeightById,
} from "../../api/MethodApi";
import { SettingsDisplay, getDisplay } from "../../components/settings-display/SettingsDisplay";
import Slider from "../../components/slider/Slider";
import { Loader } from '@googlemaps/js-api-loader';
import { Config } from "../../Config";
import {GoogleMapsAddressStyle} from "../../components/google-maps-address/GoogleMapsAddress";
import WhyDialog from "../../components/why-provider-dialog/WhyProviderDialog";
import { Certification } from "../../components/certification-form/CertificationForm";
import { Method } from "../../types/Types";  
import WorkingHours from "../../components/working-hours/WorkingHours";
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer'; // Import the Footer component


export function ProviderDetails() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const providerId = query.get('providerId'); 
  const [email, setEmail] = useState("");
  const [provider, setProvider] = useState<HealthcareProvider | null>(null);
  const navigate = useNavigate();
  const { getScores, getScoresWithAnswerId } = useResultsContext();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [showPhoneNumber, setShowPhoneNumber] = useState<boolean>(false);
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [trainingCenterMethods, setTrainingCenterMethods] = useState<Method[]>([]);
 
  const [methodsPerCategory, setMethodsPerCategory] =
    useState<MethodsPerCategory>({});
  const [methodWeightsPerId, setMethodWeightsPerId] =
    useState<MethodWeightById>({});
  const [whyIsOpen, setWhyIsOpen] =
    useState<boolean>(false);
  const [whyThisTherapist, setWhyThisTherapist] =
      useState<string>("");
  const [mostSimilarMethods, setMostSimilarMethods] =
      useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const onContactClick = () => {
    window.scrollTo(0, 0);
    navigate(`/contact/${providerId}/${provider?.name}`);
  };

  const onWhyClick = async () => {
    setWhyIsOpen(true); // Open the dialog first
    setIsLoading(true); // Set loading to true initially
  
    if (provider && Object.keys(getScores()).length > 0) {
      const response: any = await ProviderApi.getWhyProvider(getScoresWithAnswerId(), provider.id);
      setWhyThisTherapist(response.answer);
      setMostSimilarMethods(response.mostSimilarMethods);
      setIsLoading(false); // Set loading to false after data is fetched
    }
  };

  function getMethodSlidersForCategory(category: string, threshold: number) {
    const methods = methodsPerCategory[category];
    if (methods) {
      return methods.filter(method => methodWeightsPerId[method.id] && methodWeightsPerId[method.id] > threshold)
      .map((method) => (
        <div key={method.id}>
          <div className="my-account-label">{method.name}</div>
          <Slider showInfoText={false}
            value={methodWeightsPerId[method.id] || 0}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={(weight) => {}}
          />
        </div>
      ));
    } else {
      return null;
    }
  }

  const goBack = () => {
    if(provider)
    {
      navigate(`${routes.results}`); 
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
    if(providerId) {
      const provider = await HealthcareProviderApi.getHealthcareProvider(providerId);
      setProvider(provider);
      setCertifications(JSON.parse(provider.certifications) || []);

      const methodsPerCategory = await MethodApi.getMethodsPerCategory();
      setMethodsPerCategory(methodsPerCategory);
      setTrainingCenterMethods(methodsPerCategory["21aef58e-ee79-4a3e-bead-615ed2b1d4ee"] || []);

      const methodAnswers = await MethodApi.getMethodWeightsForProvider(
        providerId
      );
      setMethodWeightsPerId(methodAnswers);

      const loader = new Loader({
        apiKey: Config.getGoogleMapsApiKey(),
        libraries: ["places"]
      });

      loader.load().then(() => {

        if (provider.lat && provider.lng && mapContainerRef.current) {
          // Initialize the map
          
          const map = new window.google.maps.Map(mapContainerRef.current, {
            center: new google.maps.LatLng(provider.lat,provider.lng),
            zoom: 15,
            styles: GoogleMapsAddressStyle
          });

          // Add a marker for the provider's location
          new window.google.maps.Marker({
            position: new google.maps.LatLng(provider.lat,provider.lng),
            map: map,
            title: provider.name + " " + provider.surName,
          });
        }
      });


    }})();
  }, []);


  return (
    <div className={"wrapper"}>
      <div className={"menu-container"}>
        <Header />

        {provider && (

        <div className="profile-container">
          <div className="header-section">
            <div className="left-section">
              <div className="back-link">
                <a  onClick={goBack}>← Terug naar zoekresultaten</a>
              </div>
              <div className="professional-info">
                <h1>{provider.name} {provider.surName}</h1>
                <p>{provider.therapeuticMotto}</p>
                {provider.practiceName && (
                  <h2>{provider.practiceName}</h2>
                )}
                <p className="address">{provider.address}</p>
              </div>
              <a className={"small-button dark-button contact-button"} onClick={onContactClick}>
              Contacteer {provider.name} 
              </a>
              {Object.keys(getScores()).length > 0?(
              <a className={"small-button medium-dark-button why-button"} onClick={onWhyClick}>
                Waarom deze therapeut? 
              </a>): <div><br/><br/></div>}
            </div>
            <div className={provider.profilePictureUrl?"profile-image-container":"profile-image-container desktop"}>
            {provider.profilePictureUrl && (
              <img src={provider.profilePictureUrl} alt={`${provider.name}`} className="profile-image" />
            )}
            </div>
          </div>
          
          <div className="main-content">
            
            <div className="practice-information">
              <div className="consult-info">
                <h2>Therapeutische praktijk en aanpak</h2>
                {provider.practiceDescription}
                {
                  methodsPerCategory["specialisatie"] && methodsPerCategory["specialisatie"].filter((method) => methodWeightsPerId[method.id] === 1).length?(
                    <>
                     <h3>Ondersteuning bij</h3>
                      <p>
                      <ActiveMethods amount={20} color="white" methodsPerCategory={methodsPerCategory} category={"specialisatie"} methodWeightsPerId={methodWeightsPerId}/>
                      </p>
                    </>
                  ):(<></>)
                }
                {
                  methodsPerCategory["aanpak"] && methodsPerCategory["aanpak"].filter((method) => methodWeightsPerId[method.id] > 4).length?(
                    <>
                      <h3>Aanpak</h3>
                      <div className="match-info">
                        {getMethodSlidersForCategory("aanpak", 4)}
                      </div>
                    </>
                  ):(<></>)
                }
              </div>
            </div>

            <div className="introduction-section">
            </div>
            <div className="details-section">
              {/* Content for the introduction section */}

              <div className="professional-info">
                <h2>Professionele informatie</h2>
                
                <p><b>Opleiding</b></p>
                {trainingCenterMethods.length && certifications.map((cert, index) => (
                    <p key={index}>
                      {cert.certName}, bij {trainingCenterMethods.find((method) => method.id === cert.trainingCenterId)?.name} - {cert.yearCompleted}
                    
                    </p>
                  
                  ))}
                {provider.visumNumber?(
                  <>
                  <p><b>Visumnummer klinisch psycholoog</b></p>
                  <p>{provider.visumNumber}</p>
                  </>
                ):null}
                {provider.registrationNumber?(
                  <>
                  <p><b>Inschrijvingsnummer psychologen</b></p>
                  <p>{provider.registrationNumber}</p>
                  </>
                ):null}
                {provider.professionalAssocation?(
                  <>
                  <p><b>Beroepsvereniging</b></p>
                  <p>{provider.professionalAssocation}</p>
                  </>
                ):null}
                {provider.consultationType?(
                  <>
                  <p><b>Consultatievormen</b></p>
                  <p>{getDisplay(provider.consultationType)}</p>
                  </>
                ):null}
                {provider.ageGroups?(
                  <>
                  <p><b>Leeftijdsgroepen</b></p>
                  <p><SettingsDisplay color="#f9f6f8" options={provider.ageGroups}></SettingsDisplay></p>
                  </>
                ):null}
                <p><b>Therapiestroming</b></p>
                <p>
                <ActiveMethods amount={5} color="#f9f6f8" methodsPerCategory={methodsPerCategory} category={"therapiestroming"} methodWeightsPerId={methodWeightsPerId}/>
                </p>
                <p><b>Soort therapie</b></p>
                <p>
                <ActiveMethods amount={5} color="#f9f6f8" methodsPerCategory={methodsPerCategory} category={"therapie"} methodWeightsPerId={methodWeightsPerId}/>
                </p>
                <p><b>Behandelingstechnieken</b></p>
                <p>
                <ActiveMethods amount={5} color="#f9f6f8" methodsPerCategory={methodsPerCategory} category={"behandelingstechniek"} methodWeightsPerId={methodWeightsPerId}/>
                </p>
                
              </div>
              <div className="personal-info">
                <h2>Persoonlijke informatie</h2>
                <p><b>Leeftijd</b></p>
                <p>
                {provider.age}
                </p>
                {provider.sex?(
                  <>
                    <p><b>Gender</b></p>
                    <p>{getDisplay(provider.sex)}</p>
                  </>
                ):null}
                {provider.beliefs && provider.beliefs.length?(
                  <>
                    <p><b>Identificeert zichzelf binnen geloof of religie met</b></p>
                    <p><SettingsDisplay color="#f9f6f8" options={provider.beliefs}></SettingsDisplay></p>
                  </>
                ):null}
                {provider.pronouns?(
                  <>
                    <p><b>Voornaamwoorden</b></p>
                    <p>{provider.pronouns}</p>
                  </>
                ):null}
                {provider.comfortableWithLGBTQIAP?(
                  <>
                    <p><b>Comfortabel met</b></p>
                    <p>LGBTQIA+</p>
                  </>
                ):null}
                
              </div>
            
              <div className="practical-information">
                {/* Practical information */}
                <h2>Praktische informatie</h2>
                {provider.languages.length?(
                  <>
                  <h3>Talen</h3>
                  <SettingsDisplay color="white" options={provider.languages}></SettingsDisplay>
                  </>
                ):null}
                {provider.refundOptions.length?(
                  <>
                  <h3>Terugbetalingsmogelijkheden</h3>
                  <SettingsDisplay color="white" options={provider.refundOptions}></SettingsDisplay>
                  </>
                ):null}
                <>
                  <h3>Consultprijs</h3>
                  {provider.pricePerSession?(<>&euro;{provider.pricePerSession}</>):(<>Ongekend</>)}
                </>
                <>
                  <h3>Consultduur</h3>
                  {provider.pricePerSession?(<>{provider.avgSessionMinutes} min</>):(<>Ongekend</>)}
                </>
                <>
                  <h3>Een behandeling duurt gemiddeld</h3>
                  {provider.avgSessionCount?(<>{provider.avgSessionCount} sessies</>):(<>Ongekend</>)}
                </>
                <>
                  <h3>Gemiddelde wachttijd</h3>
                  {provider.avgWaitingDurationWeeks?(<>{provider.avgWaitingDurationWeeks} weken</>):(<>Ongekend</>)}
                </>
                <>
                  <h3>Beschikbaar voor nieuwe clienten</h3>
                  {provider.availableForNewPatients?(<>Ja</>):(<>Nee</>)}
                </>
                <>
                  <h3>Geconventioneerd (voor terugbetalingsmogelijkheden)</h3>
                  {provider.conventioned?(<>Ja</>):(<>Nee</>)}
                </>
                <>
                  <h3>Werkuren</h3>
                  <div className="details-working-hours">
                  <WorkingHours
                    value={provider.practiceHours}
                    
                  />
                  </div>
                </>
              </div>
              <div className="support-section">
                
                <h2>Bereikbaarheid</h2>
                {(provider.accessibilityByPublicTransit && provider.accessibilityByPublicTransit.filter(item => item !== "").length)?(
                  <>
                  <h3>Openbaar vervoer</h3>
                  <SettingsDisplay color="white" options={provider.accessibilityByPublicTransit.filter(item => item !== "")}></SettingsDisplay>
                  </>
                ):(<>
                  <h3>Openbaar vervoer</h3>
                  Moeilijk bereikbaar
                  </>)}
                  {(provider.accessibilityByCar && provider.accessibilityByCar.filter(item => item !== "").length)?(
                  <>
                  <h3>Met de wagen</h3>
                  <SettingsDisplay color="white" options={provider.accessibilityByCar.filter(item => item !== "")}></SettingsDisplay>
                  </>
                ):(<>
                  <h3>Met de wagen</h3>
                  Moeilijk bereikbaar
                  </>)}
                  {(provider.parkingAvailibilty)?(
                  <>
                  <h3>Parkeermogelijkheden</h3>
                  <p>{getDisplay(provider.parkingAvailibilty)}</p>
                  </>
                ):(<>
                  <h3>Parkeermogelijkheden</h3>
                  Ongekend
                  </>)}
              </div>

              <div>
                <div ref={mapContainerRef} style={{ width: '100%', marginTop: '40px', height: '200px' }} />
                <br/>
                <h2>Locatie en contact</h2>
                <a className={"small-button dark-button contact-button"} onClick={onContactClick}>
                Contacteer {provider.name} 
                </a>
                <p>{provider.address}</p>
                {provider.landlinePhoneNumber || provider.mobilePhoneNumber?(<p>
                  <a className="show-link" onClick={() => setShowPhoneNumber(true)}>Toon telefoonnummer</a>
                  {showPhoneNumber?(<div>
                      {provider.landlinePhoneNumber || provider.mobilePhoneNumber}
                    </div>):(<></>)}
                  </p>):(<></>)}
                {provider.email?(<p>
                  <a className="show-link" onClick={() => setShowEmail(true)}>Toon email</a>
                  {showEmail?(<div>
                  {provider.email}</div>):(<></>)}
                </p>):(<></>)}
              </div>
            </div>
            
          </div>
          
        </div>
        )}
      
      </div>
      
      <WhyDialog
        isOpen={whyIsOpen}
        onRequestClose={() => setWhyIsOpen(false)}
        providerName={provider?.name} // Pass the provider as a prop
        whyThisTherapist={whyThisTherapist}
        mostSimilarMethods={mostSimilarMethods}
        getMethodSlidersForCategory={getMethodSlidersForCategory}
        isLoading={isLoading}
      />
      <br/><br/>
      <div className={"grey-area wider"}>
          <div className={"centered-text intro-text"}>
                <p>
                  Deze vragenlijst kwam tot stand met ondersteuning en validatie van hulpverleners en hulpvragers. Mia werd gerealiseerd dankzij onze partners.
                </p>
              
                <div className={"partner-logos"}>
                  <img src="/imec.png" className={"partner-logo"} alt="Imec"/>
                  <img src="/comon.png" className={"partner-logo"} alt="Comon"/>
                  <img src="/ugent.png" className={"partner-logo"} alt="UGent"/>

                </div>
              </div>
        </div>
        <div className="wider">
          <Footer/>
        </div>
    </div>
    
  );
}
