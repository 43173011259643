import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./constants/routes";
import { AuthenticationGuard } from "./hoc/AuthenticationGuard";
import { Questionnaire } from "./pages/questionnaire/Questionnaire";
import HomePage from "./pages/home/HomePage";
import Hulpverlener from "./pages/hulpverlener/Hulpverlener";
import Informatie from "./pages/informatie/Informatie";
import Terugbetaling from "./pages/informatie/Terugbetaling";
import SoortenZorg from "./pages/informatie/SoortenZorg";
import Privacy from "./pages/privacy/Privacy";
import { ResultsProvider } from "./providers/ResultsProvider";
import { Results } from "./pages/results/Results";
import { ProviderDetails } from "./pages/providerDetails/ProviderDetails";
import { MyAccount } from "./pages/my-account/MyAccount";
import { ContactFormPage } from "./pages/contactForm/ContactFormPage";

export default function App() {
  return (
    <BrowserRouter>
      <ResultsProvider>
        <Routes>
          <Route path={routes.home} element={<HomePage />} />
          <Route path={routes.hulpverlener} element={<Hulpverlener />} />
          <Route path={routes.informatie} element={<Informatie />} />
          <Route path={routes.terugbetaling} element={<Terugbetaling />} />
          <Route path={routes.soortenzorg} element={<SoortenZorg />} />
          <Route path={routes.privacy} element={<Privacy />} />
          <Route path={routes.questionnaire} element={<Questionnaire />} />
          <Route path={routes.results} element={<Results />} />
          <Route path={routes.providerDetails} element={< ProviderDetails/>} />
          <Route path={routes.contactForm} element={<ContactFormPage />} />
          <Route
            path={routes.myAccount}
            element={<AuthenticationGuard component={MyAccount} />}
          />
        </Routes>
      </ResultsProvider>
    </BrowserRouter>
  );
}
