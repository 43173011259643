import React, { PropsWithChildren, useState, useCallback, useEffect } from "react";
import { ChosenAnswer, Category, Question, ScoresType, MethodScoresType, OrganisationScoresType, MediaScoresType } from "../types/Types";
import { ResultsContext } from "../context/ResultsContext";
import ResultsStore from "./ResultsStore";

export const ResultsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [, forceUpdate] = useState({}); // Dummy state for forcing updates

  // Function to force a re-render of the provider component
  const update = useCallback(() => {
    forceUpdate({});
  }, []);

  // Subscribe to the store updates on mount and setup cleanup on unmount
  useEffect(() => {
    ResultsStore.subscribe(update);
    return () => {
      ResultsStore.unsubscribe(update);
    };
  }, [update]);

  const invalidatePagination = () => {
    sessionStorage.removeItem('providersCurrentPage');
    sessionStorage.removeItem('organisationsCurrentPage');
    sessionStorage.removeItem('mediaCurrentPage');
  };
  
  const setAnswers = (answers: ChosenAnswer[]) => {
    ResultsStore.setAnswers(answers);
    invalidatePagination();  // Invalidate pagination when answers change
  };
  
  // Function to retrieve answers from session storage and update the store
  const getAnswersFromSessionStorage = () => {
    const answersInSessionStorage = sessionStorage.getItem("answers");
    if (answersInSessionStorage) {
      try {
        const parsedAnswers: ChosenAnswer[] = JSON.parse(answersInSessionStorage);
        if (parsedAnswers && parsedAnswers.length > 0) {
          ResultsStore.setAnswers(parsedAnswers);
        }
      } catch (error) {
        console.error("Failed to parse answers from session storage:", error);
      }
    }
  };
  
  // Function to save the answers in the store to session storage
  const setAnswersInSessionStorage = () => {
    const answers = ResultsStore.answers; // Retrieve answers directly from the store
    if (answers && answers.length > 0) {
      try {
        const serializedAnswers = JSON.stringify(answers);
        sessionStorage.setItem("answers", serializedAnswers);
      } catch (error) {
        console.error("Failed to save answers to session storage:", error);
      }
    }
  };

  const setAnswersForQuestion = (addedAnswers: ChosenAnswer[]) => {
    ResultsStore.setAnswersForQuestion(addedAnswers);
    invalidatePagination();  // Invalidate pagination when answers change
  };

  const finishCategory = (category: Category) => {
    ResultsStore.finishCategory(category);
  };
  
  const isCategoryFinished = (category: Category): boolean => {
    return ResultsStore.isCategoryFinished(category);
  };
  
  const isAnswerActive = (answer: ChosenAnswer): boolean => {
    return ResultsStore.isAnswerActive(answer);
  };
  
  const addAnswer = (answer: ChosenAnswer) => {
    ResultsStore.addAnswer(answer);
    invalidatePagination();  // Invalidate pagination when answers change
  };
  
  const removeAnswer = (answer: ChosenAnswer) => {
    ResultsStore.removeAnswer(answer);
    invalidatePagination();  // Invalidate pagination when answers change
  };

  const setDevAnswers = (answers: ChosenAnswer[]) => {
    ResultsStore.setAnswers(answers);
  };
  
  const anyAnswerChosen = (question: Question): boolean => {
    return ResultsStore.answers.some((answer) => answer.questionId === question.id);
  };
  
  const getFirstAnswerScore = (question: Question): number | null => {
    const result = ResultsStore.answers.find((answer) => answer.questionId === question.id)?.weight;
    return result ?? null;
  };
  
  const setFreeTextAnswer = (freeTextAnswer: string | undefined, question: Question) => {
    ResultsStore.setFreeTextAnswer(freeTextAnswer, question);
  };
  
  const finishAll = () => {
    setAnswersInSessionStorage();
    ResultsStore.finishAll();
  };

  type ScoresWithAnswerType = {
    [methodId: string]: { score: number; answerId: string };
  };

  const getScoresWithAnswerId = () => {
    return ResultsStore.getScoresWithAnswerId();
  };
  
  const getScores = (): ScoresType => {
    return ResultsStore.getScores();
  };


  const setShowExtraInfo = (showExtraInfo: boolean) => {
    ResultsStore.setShowExtraInfo(showExtraInfo);
  };
  
  const getAnswers = () => {
    return ResultsStore.answers;
  };

  return (
    <ResultsContext.Provider
      value={{
      get finishedCategories() {
        return ResultsStore.finishedCategories;
      },
      get allFinished() {
        return ResultsStore.allFinished;
      },
      get showExtraInfo() {
        return ResultsStore.showExtraInfo;
      },
      get extraInfo() {
        return ResultsStore.extraInfo;
      },
      get physicalDiagnose() {
        return ResultsStore.physicalDiagnose;
      },
      get mentalDiagnose() {
        return ResultsStore.mentalDiagnose;
      },
        setAnswersInSessionStorage,
        getAnswersFromSessionStorage,
        setDevAnswers,
        setAnswersForQuestion,
        finishCategory,
        isCategoryFinished,
        isAnswerActive,
        addAnswer,
        removeAnswer,
        anyAnswerChosen,
        getFirstAnswerScore,
        setShowExtraInfo,
        setFreeTextAnswer,
        finishAll,
        getScores,
        getScoresWithAnswerId,
        getAnswers
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
};
